import { confirmationTypeAsArray, EConfirmationType, TConfirmationTypeArray, TConfirmationTypeMatrix } from "@colmeia/core/src/core-constants/types";
import { getConditionValueTokens } from "@colmeia/core/src/shared-business-rules/bot/conditional-functions";
import { EBPMType, IBPConditionalEvaluator, TIBPConditionalEvaluatorArray } from "@colmeia/core/src/shared-business-rules/BPM/bpm-model";
import { EMetaEngagementConditionType } from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { isInEnum, isThisOneOfThat, isValidArray, isValidNumber, isValidObject, isValidString } from "../../../tools/utility";
import { ICRMAgentEventServer } from "../../crm/crm-services/crm-bpm-model";
import { ENonSerializableObjectType } from "../../non-serializable-id/non-serializable-id-interfaces";
import { isBPMOfType, isValidBPMEvaluators } from "../bpm-functions";
import { ESubscribedTo, IBPMBaseNodeServer, processableBPMBusinessEventTypes, TBPMBusinessEventTypes, TBPMBusinessEventTypesArray, TIBPMBaseNodeServerArray } from "./common-bpm";


export function hasBPMEndNodeConfig(ns: IBPMBaseNodeServer): boolean {
    return ns.nsType === ENonSerializableObjectType.bpmMarketingEnd;

}

export function hasBPMActionNodeConfig(ns: IBPMBaseNodeServer): boolean {
    return isValidBPMEvaluators(ns.condFormsAction) || isValidObject((<ICRMAgentEventServer>ns).step?.force);
}

export function hasBPMTimerNodeConfig(ns: IBPMBaseNodeServer): boolean {
    return isValidNumber(ns.bpmScheduleMS);
}

export function isPureBPMTimerNode(ns: IBPMBaseNodeServer): boolean {
    return hasBPMTimerNodeConfig(ns) && !hasBPMActionNodeConfig(ns) && !hasBPMListenerNodeConfig(ns);
}

export function isBPMPureListenerNode(ns: IBPMBaseNodeServer): boolean {
    return !(hasBPMTimerNodeConfig(ns) || hasBPMActionNodeConfig(ns));
}

export function hasBPMListenerNodeConfig(ns: IBPMBaseNodeServer): boolean {
    return isValidString(ns.listenTo?.idSubscribedTo) && isInEnum(ESubscribedTo, ns.listenTo?.type) || isValidArray(ns.listenConditions);
}

export interface ISummarizedBPMEvents {
    listeningStatuses: TBPMBusinessEventTypesArray;
}


// Sumariza (gera uma array) de todos os eventos que são importantes para os listeners
export function getSumarizedBPMEvents(processing: TIBPMBaseNodeServerArray): ISummarizedBPMEvents {
    const listeningStatuses: TConfirmationTypeArray = [];

    if (isValidArray(processing))
        processing.forEach((bpm) => {
            if (isBPMOfType(bpm.nsType, EBPMType.marketing)) {
                const statuses: TConfirmationTypeArray = getConfirmationTypesOfEachEvaluator(bpm.condFormsAction).flat();

                listeningStatuses.push(...statuses);
            }
        })

    return {
        listeningStatuses
    }
}


/**
 * Retorna uma matriz onde o índice da linha é o original do evaluator
 * @param evaluators 
 * @returns 
 */
export function getConfirmationTypesOfEachEvaluator(evaluators: TIBPConditionalEvaluatorArray): TConfirmationTypeMatrix {
    return evaluators?.map(eva => getAllConfirmationTypeFromEvaluator(eva)) || [];
}

export function getAllConfirmationTypeFromEvaluator(cond: IBPConditionalEvaluator): TConfirmationTypeArray {
    const allStatusSet: Set<EConfirmationType> = new Set();

    const add = (value: EConfirmationType) => {
        if (isThisOneOfThat(value, ...confirmationTypeAsArray)) {
            allStatusSet.add(value);
        }
    }

    for (const condition of cond.matchConditions) {
        const isListType: boolean = condition.metaConditionType === EMetaEngagementConditionType.list;
        if (isListType) {
            const tokens = getConditionValueTokens(condition.condition);

            tokens.forEach(add);

            continue;
        }

        add(condition.condition as EConfirmationType);
    }

    return [...allStatusSet.values()];

}

export function hasStatusOnTips(tips: ISummarizedBPMEvents, ...statuses: TBPMBusinessEventTypesArray): boolean {
    return isValidArray(tips?.listeningStatuses) && tips.listeningStatuses.some((status) => {
        return isThisOneOfThat(status, ...statuses)
    });
}

export function hasNothasStatusOnTips(tips: ISummarizedBPMEvents, ...statuses: TBPMBusinessEventTypesArray): boolean {
    return !hasStatusOnTips(tips, ...statuses)

}

export interface IStatusAdjust {
    waitTimer: boolean;
    runningStatus: TBPMBusinessEventTypes;
    invalidEvent?: boolean;
}

// "Normaliza" os eventos de acordo com o contexto (em summarized) e com o funcionamento esperado..
// ex.. um respond pode servir como read caso o cliente só tenha colocado listener em read 
// Os ajustes aqui são feitos comparando o status vs o que foi programado (portanto controle adicional a  ultiplos eventos simultaneos)
export function getAdjustedStatus(bpmType: EBPMType, incomingStatus: TBPMBusinessEventTypes, summarized: ISummarizedBPMEvents): IStatusAdjust {
    if (bpmType === EBPMType.crm) {
        return {
            invalidEvent: false,
            runningStatus: incomingStatus,
            waitTimer: false,
        }
    }

    const timered_naoRespondido: boolean = hasStatusOnTips(summarized, EConfirmationType.notAnswered);
    const timered_Respondido = hasStatusOnTips(summarized, EConfirmationType.respond);
    const timered_naoLido = hasStatusOnTips(summarized, EConfirmationType.notRead);
    const interessadoEmRead = hasStatusOnTips(summarized, EConfirmationType.read);

    if (incomingStatus === EConfirmationType.colmeiaSent) {
        return {
            waitTimer: false,
            runningStatus: EConfirmationType.colmeiaSent
        }
    }

    if (incomingStatus === EConfirmationType.read) {

        if (timered_naoRespondido || timered_Respondido) {
            return {
                waitTimer: true,
                runningStatus: EConfirmationType.read
            }
        }

        if (interessadoEmRead) {
            return {
                runningStatus: EConfirmationType.read,
                waitTimer: false,
            }
        }

        return {
            waitTimer: false,
            runningStatus: EConfirmationType.read
        }


    } else if (incomingStatus === EConfirmationType.notRead) {
        if (timered_naoLido) {
            return {
                waitTimer: false,
                runningStatus: EConfirmationType.notRead
            }

        }

        if (timered_naoRespondido) {
            return {
                waitTimer: false,
                runningStatus: EConfirmationType.notAnswered
            }
        }

        return {
            waitTimer: false,
            runningStatus: EConfirmationType.notRead
        }

    } else if (incomingStatus === EConfirmationType.respond) {
        if (timered_Respondido) {
            return {
                runningStatus: EConfirmationType.respond,
                waitTimer: false
            }
        }

        if (interessadoEmRead) {
            return {
                runningStatus: EConfirmationType.read,
                waitTimer: false
            }

        }

        return {
            runningStatus: EConfirmationType.respond,
            waitTimer: false
        }


    } else if (incomingStatus === EConfirmationType.notAnswered) {

        if (timered_naoRespondido) {
            return {
                runningStatus: EConfirmationType.notAnswered,
                waitTimer: false
            }
        };

        if (interessadoEmRead) {
            return {
                runningStatus: EConfirmationType.read,
                waitTimer: false
            }
        }

        if (timered_naoLido) {
            return {
                runningStatus: EConfirmationType.notRead,
                waitTimer: false
            }
        }

        return {
            runningStatus: EConfirmationType.notAnswered,
            waitTimer: false
        }
    }

    /**
     * Evento não foi ajustado pela função,
     * logo ele não é um evento válido de régua de comunicação.
     * Eventos válidos: read, notRead, respond, notAnswered
     */

    console.log('🔥 Evento não ajustado - BPM MKT - getAdjustedStatus: ' + incomingStatus)

    return {
        invalidEvent: true,
        runningStatus: undefined,
        waitTimer: false,
    }
}

export function isProcessableBpmStateBusinessType(bpmBusinessType: TBPMBusinessEventTypes): boolean {
    return processableBPMBusinessEventTypes.includes(bpmBusinessType);
}
