import { Component, Input, OnInit } from '@angular/core';
import { NonSerializableFactory } from '@colmeia/core/src/nser/non-serializable.factory';
import { ICRMProcessingNodeEventServer } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-bpm-model';
import { CrmProcessingHostNode } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/crm/crm-processing-host-node';
import { HostNodeFactory } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/factory/host-node.factory';
import { ESourceOfInfo } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { GenericDashboardEditHandler, IGenericDashboardEditPageClientCallback } from 'app/handlers/generic-dashboard-edit.handler';
import { ENsMoreOptions } from 'app/handlers/ns-more-options.handler';
import { CRMProcessingNodeBpmGraphCreateHandler } from 'app/model/bpm-graph-handler.model';
import { BpmCrmApiService } from 'app/services/bpm/api/bpm-crm-api.service';
import { BpmService } from 'app/services/bpm/bpm.service';
import { NewConditionEditorHandler, NewConditionEditorParameter } from '../../new-condition-editor/new-condition-editor.component';
import { ICRMGraphBasicData } from '../crm-graph-basic-data/crm-graph-basic-data.component';


@Component({
    selector: 'app-crm-graph-process-node',
    templateUrl: './crm-graph-process-node.component.html',
    styleUrls: ['./crm-graph-process-node.component.scss']
})
export class CrmGraphProcessNodeComponent implements OnInit {
    @Input() handler: CRMProcessingNodeBpmGraphCreateHandler;
    isCreating: boolean
    processingHostNode: CrmProcessingHostNode
    genericNserEditHandler: GenericDashboardEditHandler
    basicCRMHandler: ICRMGraphBasicData
    processingNode: ICRMProcessingNodeEventServer;
    conditionHandler: NewConditionEditorHandler;

    constructor(
        private bpmService: BpmService,
        private bpmCrmApiService: BpmCrmApiService,
        private bpmSvc: BpmService,
    ) { }

    ngOnInit(): void {
        this.isCreating = !isValidRef(this.handler.getProcessNode())

        this.processingNode = this.isCreating
            ? <ICRMProcessingNodeEventServer>NonSerializableFactory.create(ENonSerializableObjectType.crmServiceConfigProcessingNode,
                { name: '', idParent: this.handler.getRootCrmHostedId() })
            : this.handler.getProcessNode();
        this.processingHostNode = <CrmProcessingHostNode>HostNodeFactory.create({ bpmType: this.bpmService.getType(), ns: this.processingNode })

        this.initNonserializableEditorHandler()
        this.initBasicCRMHandler()
        this.initConditionsHandler()
    }

    initBasicCRMHandler() {
        this.basicCRMHandler = {
            element: <ICRMProcessingNodeEventServer>this.processingHostNode.getNonSerializable(),
            saveNSCallback: (botItem: INonSerializable) => {
                // this.processingHostNode.addBotMenuItem(<IBotMenuItemServer>botItem)
            },
            configureAction: true,
            bpmListenerParamsOverride: {
                hideSubscriber: true,
            },
        }
    }

    initNonserializableEditorHandler() {
        this.genericNserEditHandler = new GenericDashboardEditHandler({
            nsType: ENonSerializableObjectType.crmServiceConfigProcessingNode,
            autoValidate: true,
            clientCallback: <IGenericDashboardEditPageClientCallback>{
                onGenericSaveButtonPressed: this.saveProcessNode,
                onGenericBackButtonPressed: () => {
                    this.handler.getDialogRef().close();
                }
            },
            nser: this.processingHostNode.getNonSerializable(),
            allowEditTitleInHeader: true,
            moreOptions: {
                [ENsMoreOptions.CopyID]: true,
                [ENsMoreOptions.CheckDependencies]: true,
            }
        })
    }

    private initConditionsHandler() {
        const parameter: NewConditionEditorParameter = {
            field: undefined,
            fields: [],
            clientCallback: undefined,
            allowedActionTypes: {
                // [ETypeBPMActionModel.Assign]: true,
                // [ETypeBPMActionModel.GerFollowUpFromCustomer]: true,
                // [ETypeBPMActionModel.Notification]: true,
                // [ETypeBPMActionModel.executeFunction]: true,
            },
            currentConditions: this.processingNode.processingAction,
            allowedSourceInfo: [ESourceOfInfo.property],
            disableAutoAdd: true,
        }

        this.conditionHandler = new NewConditionEditorHandler(parameter);
    }

    private saveProcessNode = async () => {
        const processor = this.bpmSvc.getCurrentStrategy().getGraphRulesProcessor();
        const idParentGraphNode = processor.findNodeElementByHostedId(this.handler.getRootCrmHostedId() || this.processingHostNode.getNonSerializable().idParent)?.getGraphElementID();

        const [result] = await this.bpmCrmApiService.save(
            <ICRMProcessingNodeEventServer>this.processingHostNode.getNonSerializable(), idParentGraphNode)

        if (!result) return;

        this.handler.closeDialogAndSendResultToParentComponent({
            userHasClickedSave: true,
            nonSerializable: result,
            botMenuItem: this.processingHostNode.getBotMenuItem()
        });
    }
}
