import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { PartialExplicit } from '@colmeia/core/src/tools/utility/types';
import { Define } from '@colmeia/core/src/tools/utility/types/entities/define';
import { TArrayID } from '../../../core-constants/types';
import { ENextGenBotElementType, IBotCanonical, IBotElement, IBotElementData } from '../../bot/bot-model';
import { IMetaEngagement } from '../../metadata/meta-engagement';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from '../../non-serializable-id/non-serializable-id-interfaces';
import { IIntentAsset } from '../kb-inferfaces';
import { EOmniSenseMethods, ILLMProvider, TIOmniSenseStrategyConfigArray } from '../luis-core-interfaces';


export type EGeneratorTransactionType = ENextGenBotElementType.nlpTransaction | ENextGenBotElementType.formTransaction;


export enum EActionOnComplete {
    return = 'return',
    keepUntilCommand = 'kUntC'
}

export interface IBotTransactionNLP {
    idKB: IdDep<ENonSerializableObjectType.knowledgeBase>;
    assets: IIntentAsset;
    providers?: {
        llm?: ILLMProvider;
    }
}


export interface IGenerativeAIConfigOnContentGenerator {
    /**
     * Apenas generativo da predição após conversa iniciada
     */
    onceGenerativeKeepIt?: boolean;

    /**
     * Acurácia para considerar como o mesmo assunto
     *  vai de 0..1
     */
    sameSubjectCertain?: number; // slide

    sameSubjectStrategy?: TIOmniSenseStrategyConfigArray;



}


export interface IBotTransaction extends IBotElementData, IBotCanonical, INonSerializableHeader {
    nsType: ENonSerializableObjectType.contentGenerator,
    botLevel: EGeneratorTransactionType;
    actionOnComplete: EActionOnComplete;
    // whenStartGenerative?: IGenerativeAIConfigOnContentGenerator;
    submitOnComplete?: boolean;
    idSchemma: string;
    idDatabaseContentGenerator?: IdDep<ENonSerializableObjectType.fileMetadata>;
    variableTags?: TArrayID;
    engagement?: IMetaEngagement;
    idPrefillAdEventTag?: IdDep<ENonSerializableObjectType.colmeiaTags>;
    idPostfillAdEventTag?: IdDep<ENonSerializableObjectType.colmeiaTags>;
}

export function isNSTransactionNLP(ns: INonSerializable): boolean {
    return ns.nsType === ENonSerializableObjectType.contentGenerator && isNLPTransactionGuard(ns as any)
}

export function isNLPTransactionGuard(transaction: IBotTransaction): transaction is INLPTransactionServer {
    return transaction.botLevel === ENextGenBotElementType.nlpTransaction;
}



export function isFormTransactionGuard(transaction: IBotTransaction): transaction is IFormTransactionServer {
    return transaction.botLevel === ENextGenBotElementType.formTransaction;
}


export function isNSTransactionServer(ns: INonSerializable): ns is ITransactionServer {
    return ns.nsType === ENonSerializableObjectType.contentGenerator;
}


export function initBotTransaction(botLevel?: EGeneratorTransactionType): IBotTransaction {
    return {
        nName: '',
        actionOnComplete: EActionOnComplete.return,
        nsType: ENonSerializableObjectType.contentGenerator,
        idSchemma: undefined,
        botLevel: botLevel ?? ENextGenBotElementType.formTransaction,
        tags: [],
        events: [],
        canonicals: [],
    }
}



export interface INLPTransaction extends IBotTransaction {
    nlp: IBotTransactionNLP;
    rerunGreetings?: boolean;
    rerunConfirmation?: boolean;
    idUserFunction?: IdDep<ENonSerializableObjectType.userFunction>;
}

export interface IFormTransation extends IBotTransaction {
}

export interface INLPTransactionComponent {
    entityName: string;
    idFieldForm: string;
}

export type TBotTransactionComponentsArray = Array<INLPTransactionComponent>;

export interface ITransactionServer extends INonSerializable, IBotTransaction {
    nsType: ENonSerializableObjectType.contentGenerator;
};
export interface IFormTransactionServer extends IFormTransation, ITransactionServer {
    botLevel: ENextGenBotElementType.formTransaction;
    nsType: ENonSerializableObjectType.contentGenerator;
};
export interface INLPTransactionServer extends INLPTransaction, ITransactionServer {
    botLevel: ENextGenBotElementType.nlpTransaction;
    nsType: ENonSerializableObjectType.contentGenerator;
};

export type MapContentGeneratorBotLevelToInterface = Define<{ [key in EGeneratorTransactionType]: PartialExplicit<ITransactionServer, { botLevel: key }> }, {
    [ENextGenBotElementType.formTransaction]: IFormTransactionServer;
    [ENextGenBotElementType.nlpTransaction]: INLPTransactionServer;
}>;

export type TBotTransactionServerArray = ITransactionServer[];

export function isINLPTransactionServer(obj: any): obj is INLPTransactionServer {
    return obj && obj.botLevel === ENextGenBotElementType.nlpTransaction &&
        obj.nlp !== undefined &&
        obj.actionOnComplete !== undefined &&
        obj.idSchemma !== undefined &&
        obj.canonicalsts !== undefined;
}
