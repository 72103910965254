import { Injectable } from "@angular/core";
import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { ECloseState, ECRMCanonicalItemsTypes, EServiceManagementPhases, ESeverityLevel, ESupportLevel, ETicketState, EUrgencyLevel, ICRMCloseStateCanonicalItem, ICRMPhaseCanonicalItem, ICRMSeverityLevelCanonicalItem, ICRMSupportLevelCanonicalItem, ICRMTicketStateCanonicalItem, ICRMUrgencyLevelCanonicalItem } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config-canonical-model";
import { ICRMProject } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config.model";
import { getDefaultTicketAttrItemTheme, ITicketAttrThemeConfig } from "@colmeia/core/src/shared-business-rules/crm/crm-ticket-theme.model";
import { CRMTicketsService } from "./crm-tickets.service";
import { getCRMTicketItemBaseConfigByType } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config-canonical-db";


//

export interface ITicketDataForDesign {
    ticketState: ICRMTicketStateCanonicalItem;
    closeState?: ICRMCloseStateCanonicalItem;
    phase?: ICRMPhaseCanonicalItem;
    severityLevel?: ICRMSeverityLevelCanonicalItem;
    supportLevel?: ICRMSupportLevelCanonicalItem;
    urgencyLevel?: ICRMUrgencyLevelCanonicalItem;

    name: string; // customerName? agentName? targetName? solicitorName? attendantName? ownerName? someHumanName
    islandName?: string;
    // island?: IServiceIsland;

    title: string;
    protocol?: string;
    package: Pick<ICRMProject, "projectKey" | "name"> & Partial<Omit<ICRMProject, "projectKey" | "name">>;

    channel?: EDelivery360Action;
    clockTick?: number;
    lastClockTick?: number;
}

function getDefaultTicketData(): ITicketDataForDesign {
    return {
        ticketState: { name: "", ticketState: ETicketState.new, isOpen: false },
        // closeState:    { name: "", closeState: ECloseState.failed,          isSuccess: false, isExpired: false },
        // phase:         { name: "", phase: EServiceManagementPhases.detect,  level: 0 },
        // severityLevel: { name: "", severityLevel: ESeverityLevel.severity1, level: 0 },
        // supportLevel:  { name: "", supportLevel: ESupportLevel.tier0,       level: 0 },
        // urgencyLevel:  { name: "", urgencyLevel: EUrgencyLevel.normal,      level: 0 },
        name: "",
        // islandName: "",
        title: "",
        // protocol: "",
        package: { projectKey: "", name: "" },
        // channel: EDelivery360Action.Delivery360WhatsApp,
        // clockTick: Date.now(),
        // lastClockTick: new Date().getTime(),
    };
}

export enum ETicketDisplayStatuses {
    Open = "open",
    Success = "success",
    Failure = "failure",
    Expired = "expired",
    Unknown = "unknown",
}

export interface ITicketStatusStyleDefinition {
    fg: string;
    bg: string;
    icon: string;
    iconType?: "material-icons" | "svg" | "image" | "unicode";
}

export const TicketStatusStyleDefinitions: Record<ETicketDisplayStatuses, ITicketStatusStyleDefinition> = {
    [ETicketDisplayStatuses.Unknown]: {
        icon: "help",
        fg: "--primary-lighter",
        bg: "--primary-lighter-contrast",
    },
    [ETicketDisplayStatuses.Open]: { icon: "confirmation_number", fg: "--info-900", /*    */ bg: "--info-50" },
    [ETicketDisplayStatuses.Success]: { icon: "task", /*       */ fg: "--success-900", /* */ bg: "--success-900-contrast" },
    [ETicketDisplayStatuses.Failure]: { icon: "thumb_down", /* */ fg: "--warn-300", /*    */ bg: "--warn-300-contrast" },
    [ETicketDisplayStatuses.Expired]: { icon: "timer_off", /*  */ fg: "--alert-500", /*   */ bg: "--alert-500-contrast" },
};

export const TicketStatusAttrStyleDefs: Record<
    any,
    ITicketStatusStyleDefinition & { levels: Record<any, ITicketStatusStyleDefinition> }
> = {
    ["fallback"]: {
        icon: "speed",
        fg: "--primary-400",
        bg: "--primary-50",
        levels: {},
    },
    [ECRMCanonicalItemsTypes.Severity]: {
        icon: "speed",
        fg: "--primary-400",
        bg: "--primary-50",
        levels: {
            [ESeverityLevel.severity1]: { icon: "", fg: "--alert-100-contrast", bg: "--alert-100" },
            [ESeverityLevel.severity2]: { icon: "", fg: "--alert-200-contrast", bg: "--alert-200" },
            [ESeverityLevel.severity3]: { icon: "", fg: "--alert-900-contrast", bg: "--alert-900" },
            [ESeverityLevel.severity4]: { icon: "", fg: "--warn-900-contrast", bg: "--warn-900" },
        },
    },
    [ECRMCanonicalItemsTypes.UrgencyLevel]: {
        icon: "signal_cellular_connected_no_internet_4_bar",
        fg: "--primary-400",
        bg: "--primary-50",
        levels: {
            [EUrgencyLevel.normal]: { iconType: "svg", icon: "./assets/icons/urgency-level/urgency-6.svg", fg: "", bg: "" },
            [EUrgencyLevel.urgent]: { iconType: "svg", icon: "./assets/icons/urgency-level/urgency-8.svg", fg: "", bg: "" },
        },
    },
    [ECRMCanonicalItemsTypes.SupportLevel]: {
        icon: "support",
        fg: "--primary-400",
        bg: "--primary-50",
        levels: {
            [ESupportLevel.tier0]: { icon: "", fg: "--info-500-contrast", bg: "--info-500" },
            [ESupportLevel.tier1]: { icon: "", fg: "--info-600-contrast", bg: "--info-600" },
            [ESupportLevel.tier2]: { icon: "", fg: "--info-700-contrast", bg: "--info-700" },
            [ESupportLevel.tier3]: { icon: "", fg: "--info-800-contrast", bg: "--info-800" },
            [ESupportLevel.tier4]: { icon: "", fg: "--info-900-contrast", bg: "--info-900" },
        },
    },
    [ECRMCanonicalItemsTypes.Phase]: {
        icon: "linear_scale",
        fg: "--primary-400",
        bg: "--primary-50",
        levels: {
            [EServiceManagementPhases.detect]: { icon: "", fg: "--accent-100-contrast", bg: "--accent-100" },
            [EServiceManagementPhases.record]: { icon: "", fg: "--accent-200-contrast", bg: "--accent-200" },
            [EServiceManagementPhases.classify]: { icon: "", fg: "--accent-300-contrast", bg: "--accent-300" },
            [EServiceManagementPhases.prioritize]: { icon: "", fg: "--accent-400-contrast", bg: "--accent-400" },
            [EServiceManagementPhases.investigate]: { icon: "", fg: "--accent-500-contrast", bg: "--accent-500" },
            [EServiceManagementPhases.diagnose]: { icon: "", fg: "--accent-600-contrast", bg: "--accent-600" },
            [EServiceManagementPhases.resolution]: { icon: "", fg: "--accent-700-contrast", bg: "--accent-700" },
            [EServiceManagementPhases.restore]: { icon: "", fg: "--accent-800-contrast", bg: "--accent-800" },
            [EServiceManagementPhases.closure]: { icon: "", fg: "--accent-900-contrast", bg: "--accent-900" },
        },
    },
    [ECRMCanonicalItemsTypes.TicketState]: {
        icon: "info",
        fg: "--primary-400",
        bg: "--primary-50",
        levels: {
            [ETicketState.new]: { icon: "", fg: "--info-50", bg: "--info-900" },
            [ETicketState.inProgress]: { icon: "", fg: "--info-50", bg: "--info-900" },
            [ETicketState.OnHold]: { icon: "", fg: "--info-50", bg: "--info-900" },
            [ETicketState.Closed]: { icon: "", fg: "", bg: "" },
            [ETicketState.Canceled]: { icon: "", fg: "", bg: "" },
            [ETicketState.reopened]: { icon: "", fg: "--info-50", bg: "--info-900" },
            [ETicketState.waitingEscalation]: { icon: "", fg: "--info-50", bg: "--info-900" },
        },
    },
    [ECRMCanonicalItemsTypes.CloseState]: {
        icon: "block",
        fg: "--primary-400",
        bg: "--primary-50",
        levels: {
            [ECloseState.workaround]: { icon: "done", fg: "--success-600-contrast", bg: "--success-600" },
            [ECloseState.solved]: { icon: "check_circle", fg: "--success-600-contrast", bg: "--success-600" },
            [ECloseState.failed]: { icon: "", fg: "", bg: "" },
            [ECloseState.closedByCaller]: { icon: "", fg: "", bg: "" },
            [ECloseState.closedByCustomerTimeout]: { icon: "history_toggle_off", fg: "--alert-600-contrast", bg: "--alert-600" },
        },
    },
};

// state:  info
// phase :  linear_scale
// severity :  speed
// urgency :  signal_cellular_connected_no_internet_4_bar
// support :  support
// closeState :  block

//#region CRMTicketDesignService

@Injectable({
    providedIn: "root",
})
export class CRMTicketDesignService {
    constructor(
        private crmTicketSvc: CRMTicketsService,
    ) { }

    getTicketAttrItemTheme(attrId: string): ITicketAttrThemeConfig {
        if (!attrId) return getDefaultTicketAttrItemTheme();

        const name = this.crmTicketSvc.getItemLocalData(attrId);
        const configBase = getCRMTicketItemBaseConfigByType(name.config);

        return configBase?.theme || getDefaultTicketAttrItemTheme();
    }

    public getStyleDefs(canonicalType: string, canonicalValue?: string): ITicketStatusStyleDefinition {
        let sDefs = TicketStatusAttrStyleDefs["fallback"];

        const canonicalDefs = TicketStatusAttrStyleDefs[canonicalType];
        if (canonicalDefs?.icon) sDefs.icon = canonicalDefs?.icon;
        if (canonicalDefs?.fg) sDefs.fg = canonicalDefs?.fg;
        if (canonicalDefs?.bg) sDefs.bg = canonicalDefs?.bg;
        if (canonicalDefs?.iconType) sDefs.iconType = canonicalDefs?.iconType;

        const cValueDefs = TicketStatusAttrStyleDefs[canonicalType]?.levels?.[canonicalValue as any];
        if (cValueDefs?.icon) sDefs.icon = cValueDefs?.icon;
        if (cValueDefs?.fg) sDefs.fg = cValueDefs?.fg;
        if (cValueDefs?.bg) sDefs.bg = cValueDefs?.bg;
        if (cValueDefs?.iconType) sDefs.iconType = cValueDefs?.iconType;

        return sDefs;
    }
}

//#endregion

export class CRMTicketDesign {
    private ticketData: ITicketDataForDesign = getDefaultTicketData();

    constructor(ticketData: ITicketDataForDesign) {
        this.ticketData = {
            ...getDefaultTicketData(),
            ...ticketData,
        };
    }


    // core logic

    get displayStatus(): ETicketDisplayStatuses {
        if (this.isOpen) {
            return ETicketDisplayStatuses.Open;
        }
        if (this.isExpired) {
            return ETicketDisplayStatuses.Expired;
        }
        if (this.isSuccess) {
            return ETicketDisplayStatuses.Success;
        }
        if (this.isFailure) {
            return ETicketDisplayStatuses.Failure;
        }

        return ETicketDisplayStatuses.Unknown;
    }

    // colors and icon

    get styleDefs(): ITicketStatusStyleDefinition {
        const defs = TicketStatusStyleDefinitions[this.displayStatus];

        if (!defs) {
            return TicketStatusStyleDefinitions[ETicketDisplayStatuses.Unknown];
        }
        return defs;
    }
    get foregroundColor(): string {
        return `var(${this.styleDefs.fg})`;
    }
    get backgroundColor(): string {
        const defs = TicketStatusStyleDefinitions[this.displayStatus];
        return `var(${this.styleDefs.fg})`;
    }
    get icon(): string {
        return this.styleDefs.icon;
    }

    // status label

    get ticketState(): string {
        return this.ticketData.ticketState?.name || "";
    }
    get closedStatus(): string {
        return this.ticketData.closeState?.name || "";
    }
    get bestStatus(): string {
        const strClosedStatus = this.closedStatus;

        return strClosedStatus ? strClosedStatus : this.ticketState;
    }

    // boolean flags

    get isOpen(): boolean {
        return this.ticketData.ticketState.isOpen;
    }
    get isExpired(): boolean {
        return Boolean(!this.ticketData.ticketState.isOpen && this.ticketData.closeState?.isExpired);
    }
    get isSuccess(): boolean {
        return Boolean(
            !this.ticketData.ticketState.isOpen &&
                !this.ticketData.closeState?.isExpired &&
                this.ticketData.closeState?.isSuccess
        );
    }
    get isFailure(): boolean {
        return (
            !this.ticketData.ticketState.isOpen &&
            !this.ticketData.closeState?.isExpired &&
            !this.ticketData.closeState?.isSuccess
        );
    }

    // TODO: design for these properties:
    // urgency
    // severity
    // phase
    // category icons and titles
    // created and updated at

    // customer
    // attendant
    // island

    // expiration ? remaining time ?
}
