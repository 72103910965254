import { templateActionTypeConfig } from '@colmeia/core/src/shared-business-rules/bot/bot-action-config-db';
import { EDelivery360Action } from '../../comm-interfaces/interaction-interfaces';
import { isInvalid, isValidArray, isValidNumber, isValidRef, isValidString } from '../../tools/utility';
import { TContentAssetArray } from '../bot/bot-content-model';
import { TMetadataNameArray } from '../metadata/metadata-db';
import { TPropertyKeyArray } from '../metadata/metadata-util-interfaces';
import { getCanonicalForDeliveryAction } from '../social-cc/social-cc-rules';
import { EAdvancedTemplateOption, ISocialMediaConnectionServer, TIISocialMediaConnectionArray } from '../social-media/social-media.model';
import { IEmailTemplateParameter, IGenericTemplateParameter, INSWhatsAppTemplateServer, ISMSTemplateParameter, IWhatsAppTemplateParameter } from '../social-media/template-model';
import { EEngagementMethodControl, EOnOngoingConversation, ICampaingActionHeader, ICMassCommunicationAction, IMarketingEngagmentControl } from './campaign-type-model';
import { ICampaignOmniChannelSchedule, ICAWhatsAppSchedule, TICampaignActionCommStrategy } from './campaing-comm-strategy';
import { ERCSMessageType, IRCSTemplateParameter } from '../social-media/rcs-template-model';
import { KBAssetType } from '../bot/bot-asset-model';
import { RCS } from '../social-media/rcs-interface';



export function getSendWhatsIfChannelOpen(mass: ICMassCommunicationAction): boolean {
    return mass.commStrategy.some((x) => { return x.channelType === EDelivery360Action.Delivery360WhatsApp && (<ICAWhatsAppSchedule>x).preferedIfChannelOpen })
}

export function getChannelCommFromAction<T extends ICampaignOmniChannelSchedule>(action: ICampaingActionHeader, channelType: EDelivery360Action): T {
    const wa = <ICMassCommunicationAction>action;
    if (isValidArray(wa.commStrategy)) {
        return <T>wa.commStrategy.find((st) => { return st.channelType === channelType })
    };
    return null;
};

const channelsThatNeedTemplate: Record<EDelivery360Action, boolean> = {
    [EDelivery360Action.Delivery360WhatsApp]: true,
    [EDelivery360Action.Delivery360SMS]: false,
    [EDelivery360Action.Delivery360FBMessenger]: false,
    [EDelivery360Action.DeliveryInstagram]: false,
    [EDelivery360Action.Delivery360Email]: true,
    [EDelivery360Action.Delivery360Voice]: false,
    [EDelivery360Action.DeliveryColmeia]: false,
    [EDelivery360Action.DeliveryTelegram]: false,
    [EDelivery360Action.Delivery360RCS]: true,
}

export function getChannelHasTemplateConfig(channel: EDelivery360Action): boolean {
    return channelsThatNeedTemplate[channel]
}

export function initTemplateParameters<ChannelType extends EDelivery360Action>(channelType?: ChannelType) {
    if (isInvalid(channelType)) return basicTemplateParameter();

    switch (channelType) {
        case EDelivery360Action.Delivery360Email: return initEmailTemplateParameter();
        case EDelivery360Action.Delivery360WhatsApp: return initWhatsAppTemplateParameter();
        case EDelivery360Action.Delivery360SMS: return initSMSTemplateParameter();
        case EDelivery360Action.Delivery360RCS: return initRCSTemplateParameter();
    }
}

export function basicTemplateParameter(): IGenericTemplateParameter {
    return {
        name: '',
        allVariables: [],
        channelType: undefined,
        providers: [],
    };
}

export function initEmailTemplateParameter(): IEmailTemplateParameter {
    return {
        ...basicTemplateParameter(),
        channelType: EDelivery360Action.Delivery360Email,
        type: undefined,
        idEmailTemplate: '',
    }
}

export function initWhatsAppTemplateParameter(): IWhatsAppTemplateParameter {
    const template: IWhatsAppTemplateParameter = {
        ...basicTemplateParameter(),
        providers: [],
        channelType: EDelivery360Action.Delivery360WhatsApp,
        language: undefined,
        category: undefined,
        variablesExamples: {},
        allVariables: [],
        template: '',
        compiledTemplateWithProperties: '',
        whatsAppVariablesTemplate: {
            variables: [],
            compiledTemplate: '',
            messageIfNoBind: '',
        }
    }

    return template;
}

export function initSMSTemplateParameter(): ISMSTemplateParameter {
  const template: ISMSTemplateParameter = {
    ...basicTemplateParameter(),
    providers: [],
    channelType: EDelivery360Action.Delivery360SMS,
    smsMessageType: undefined,
    textWithVariables: {
      title: {
        variables: [],
        compiledTemplate: "1",
      },
      localCanonicals: [],
    }
  }

  return template;
}

export function initRCSTemplateParameter(): IRCSTemplateParameter {
  const template: IRCSTemplateParameter = {
    ...basicTemplateParameter(),
    channelType: EDelivery360Action.Delivery360RCS,
    header: {
      content: {
        type: KBAssetType.content,
        content: '',
        idAsset: ''
      },
      format: RCS.Message.Template.Structure.Format.Text,
    },
    templateCategory: RCS.Template.Category.text,
    rcsMessageType: ERCSMessageType.basic,
    content: {
      title: '',
      rcsBodyType: undefined,
      text: '',
      basicMessage: {
        title: {
          variables: [],
          compiledTemplate: ''
        },
        localCanonicals: []
      },
      fileURL: '',
      content: undefined
    },
    compiledTemplateWithProperties: '',
    fallbackMessage: '',
    variablesExamples: {},
    language: RCS.Template.Language.PortugueseBR
  }

  return template;
}





export function getAllNeedCommTypes(commStrategy: TICampaignActionCommStrategy): TMetadataNameArray {
    const ret: { [idMetadata: string]: true } = {};
    commStrategy.forEach((c) => {
        ret[getCanonicalForDeliveryAction(c.channelType)] = true;
    })
    return <TMetadataNameArray>Object.keys(ret);
}


export function getKeyVariablesOfAssetArray(contentArray: TContentAssetArray): TPropertyKeyArray {
    const distictKeys: TPropertyKeyArray = []
    contentArray.forEach((c) => {
        if (isValidArray(c.variablesTemplate?.variables)) {
            c.variablesTemplate.variables.forEach((v) => {
                if (!hasIDpropertyOnVariables(v.idProperty, distictKeys)) {
                    distictKeys.push({ idProperty: v.idProperty })
                }
            })
        }

    })
    return distictKeys;
}

export function hasIDpropertyOnVariables(idProperty: string, variables: TPropertyKeyArray): boolean {
    return variables.some((x) => { return x.idProperty === idProperty });
}

export function getConnectionOfType(type: EDelivery360Action, conns: TIISocialMediaConnectionArray): ISocialMediaConnectionServer {
    return conns.find((m) => { return m.socialMediaType === type })
}

export function getChannelsFromCampaignAction(action: ICampaingActionHeader): TICampaignActionCommStrategy {
    return (<ICMassCommunicationAction>action).commStrategy;
}


export function getIDConnectionFromAction(action: ICampaingActionHeader, type: EDelivery360Action): string {
    return getChannelsFromCampaignAction(action).find(c => c.channelType === type)?.idSocialMediaConnection

}

export function isMktEngagementOn(action: ICampaingActionHeader): boolean {
    return action.useEngagementControl && isMktEngagementConigured(action.engagementControl)
}

export function isMktEngagementConigured(engagement: IMarketingEngagmentControl): boolean {
    return isValidRef(engagement) && isValidString(engagement?.engagedIf)
        && isValidNumber(engagement.ignoreFailuresAfterDays) &&
        (engagement.controlType === EEngagementMethodControl.hourAbsence ?
            isValidNumber(engagement.hourAbsent) :
            isValidNumber(engagement.tries)
        )
}

export function isSendQuickCampaign(action: ICMassCommunicationAction): boolean {
    return action.onOngoingConversation.action === EOnOngoingConversation.sendMessage;
}


export function isValidToSendOnOngoingConversation(nsTemplate: INSWhatsAppTemplateServer): boolean {
    const isOnlyCallToAction = nsTemplate.parameter.advancedOption?.quickReplyOptions.every(item => templateActionTypeConfig[item.action.type].templateOptionType === EAdvancedTemplateOption.callToAction);
    return isOnlyCallToAction ?? true;
}
