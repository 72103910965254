import { ComponentPortal, ComponentType, DomPortalOutlet } from '@angular/cdk/portal';
import { ComponentFactoryResolver, ComponentRef, inject, Injectable, reflectComponentType, runInInjectionContext, SimpleChanges, ViewContainerRef } from '@angular/core';
import { getReadableUniqueID } from '@colmeia/core/src/tools/barrel-tools';
import { AppComponent } from 'app/app.component';
import { AnimationsFramesGroup } from 'app/model/client-utility';
import { ComponentClass, createElement, FunctionComponent, useEffect, useState } from 'react';
import { createRoot, Root } from 'react-dom/client';


declare global {
    namespace JSX {
        interface IntrinsicElements {
            [key: string]: React.DetailedHTMLProps<
                React.HTMLAttributes<HTMLElement>,
                HTMLElement
            > & Record<string, any>;
        }
    }
}

// art
export namespace Reactify {

    const ARC_ID_PREFIX = 'cm-arc-id';
    let service: Service;

    @Injectable({
        providedIn: 'root'
    })
    export class Service {
        private map: Map<string, ViewContainerRef> = new Map();

        constructor() {
            service = this;
        }

        public addComponent(vcr: ViewContainerRef): string {
            const id = this.createComponentId();

            this.map.set(id, vcr);

            vcr.element.nativeElement.id = id;

            return id;
        }

        private createComponentId(): string {
            return `${ARC_ID_PREFIX}-${Date.now()}-${getReadableUniqueID(5)}`;
        }

        getVCR(idARC: string): ViewContainerRef {
            return this.map.get(idARC);
        }

        public removeComponent(arcId: string) {
            this.map.delete(arcId);
        }
    }

    export function This() {

        return function ReactifyComponentClass(target: ComponentType<any>) {
            const reflect = reflectComponentType(target);
            const inputs = new Set([...reflect.inputs.map(i => i.propName)])
            const bridgeComponent = makeBridgeComponent(target, inputs);

            customElements.define(reflect.selector, bridgeComponent);
        }
    }

    function makeBridgeComponent(target: ComponentType<any>, inputs: Set<string>) {

        return class extends HTMLElement {
            static observedAttributes = [...inputs];

            private targetInstance: ComponentRef<any>;
            private cfr: ComponentFactoryResolver | null = null;;
            private withARC!: boolean;

            constructor() {
                super();

                runInInjectionContext(AppComponent.appRef.injector, () => {
                    this.cfr = inject(ComponentFactoryResolver) || null;
                });
            }

            private isAngularComponent(): boolean {
                return (this as any).__ngContext__ !== undefined || this.classList.contains('ng-star-inserted');
            }

            public connectedCallback() {
                if (this.isAngularComponent()) {
                    return;
                }

                const arcID = this.findParentARCId();

                this.withARC = !!arcID;

                if (!arcID) {
                    console.info('ARC not found!', this.parentElement);

                    this.targetInstance = AppComponent.appRef.bootstrap(target, this)
                } else {
                    const vcr = service.getVCR(arcID);
                    const outlet = new DomPortalOutlet(
                        this,
                        this.cfr,
                        AppComponent.appRef,
                        vcr.injector,
                    );

                    const portal = new ComponentPortal(target, vcr);
                    this.targetInstance = outlet.attach(portal);

                    // Remover duplicidade dos elementos
                    /**
                     * Usando o DomPortalOutlet
                     * ao ser anexado a esse nó ele é
                     * renderizado como filho, exemplo:
                     * <my-component>
                     *   <my-component></my-component>
                     * </my-component>
                     * 
                     * a função abaixo é pra evitar isso.
                     */
                    this.replaceWith(this.targetInstance.location.nativeElement);
                }

                for (const input of inputs) {
                    this.targetInstance.setInput(input, this.getAttribute(input));
                }

            }

            private findParentARCId(): string {

                let parent = this.parentElement;

                while (parent) {
                    if (parent.nodeName === 'BODY') break;
                    const isARC = parent.id.startsWith(ARC_ID_PREFIX);

                    if (isARC) {
                        return parent.id;
                    }

                    parent = parent.parentElement;
                }
            }

            public disconnectedCallback() {
                if (this.isAngularComponent()) return;
                if (!this.withARC) {
                    this.targetInstance?.destroy();
                }
            }

            public adoptedCallback() { }

            public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
                if (this.isAngularComponent()) return;
                if (inputs.has(name)) {
                    this.targetInstance?.setInput(name, this.getAttribute(name));
                }
            }
        }
    }

    export function As<P extends {}>(reactElement: FunctionComponent<P> | ComponentClass<P> | string) {
        return class implements Reactify.OnInit, Reactify.OnChanges, Reactify.AfterViewInit, Reactify.AfterContentChecked, Reactify.AfterContentInit, Reactify.OnDestroy {

            reactRoot: Root;
            vcr: ViewContainerRef;
            __props: P;

            private arcId!: string;
            private framesController = new AnimationsFramesGroup();
            private service = inject(Reactify.Service);

            constructor(vcr: ViewContainerRef, props: P) {
                this.__props = props;
                this.vcr = vcr;
                this.reactRoot = createRoot(vcr.element.nativeElement);

                this.arcId = this.service.addComponent(vcr);
            }

            arcOnInit(): void { }
            arcOnChanges(changes: SimpleChanges): void { }
            arcAfterViewInit(): void { }
            arcAfterContentChecked(): void { }
            arcAfterContentInit(): void { }
            arcOnDestroy(): void { }

            private ngOnInit(): void {
                this.arcOnInit();
            }

            private ngOnChanges(changes: SimpleChanges): void {
                this.arcOnChanges(changes);
            }

            private ngAfterViewInit() {
                this.framesController.run(0, () => {
                    this.reactRoot?.unmount();
                    this.reactRoot = createRoot(this.vcr.element.nativeElement);
                    this.reactRoot.render(createElement(reactElement, this.__props));
                });
                this.arcAfterViewInit?.();
            }

            private ngAfterContentChecked(): void {
                this.arcAfterContentChecked?.();
            }

            private ngAfterContentInit(): void {
                this.arcAfterContentInit?.();
            }

            private ngOnDestroy(): void {
                this.service.removeComponent(this.arcId);
                this.destroy();
                this.arcOnDestroy?.();
            }

            protected render = (props: P = this.__props) => {
                this.framesController.run(0, () => {
                    this.reactRoot?.unmount();
                    this.reactRoot = createRoot(this.vcr.element.nativeElement);
                    this.reactRoot.render(createElement(reactElement, props));
                });
            }

            private destroy() {
                this.framesController.run(0, () => {
                    this.reactRoot.unmount();
                });
            }
        }
    }

    export function useService<T extends new (...args: any[]) => any>(service: T): InstanceType<T> {
        const [instance, setInstance] = useState<InstanceType<T> | null>(null);

        useEffect(() => {
            runInInjectionContext(AppComponent.appRef.injector, () => {
                setInstance(inject(service) as InstanceType<T>);
            });
        }, []);

        return instance;
    }

    export declare interface OnInit {
        /**
         * A callback method that is invoked immediately after the
         * default change detector has checked the directive's
         * data-bound properties for the first time,
         * and before any of the view or content children have been checked.
         * It is invoked only once when the directive is instantiated.
         */
        arcOnInit(): void;
    }

    export interface AfterViewInit {
        /**
         * A callback method that is invoked immediately after
         * Angular has completed initialization of a component's view.
         * It is invoked only once when the view is instantiated.
         *
         */
        arcAfterViewInit(): void;
    }

    export declare interface OnChanges {
        /**
         * A callback method that is invoked immediately after the
         * default change detector has checked data-bound properties
         * if at least one has changed, and before the view and content
         * children are checked.
         * @param changes The changed properties.
         */
        arcOnChanges(changes: SimpleChanges): void;
    }

    export declare interface AfterContentChecked {
        /**
         * A callback method that is invoked immediately after the
         * default change detector has completed checking all of the directive's
         * content.
         */
        arcAfterContentChecked(): void;
    }

    export declare interface AfterContentInit {
        /**
         * A callback method that is invoked immediately after
         * Angular has completed initialization of all of the directive's
         * content.
         * It is invoked only once when the directive is instantiated.
         */
        arcAfterContentInit(): void;
    }

    export declare interface OnDestroy {
        /**
         * A callback method that performs custom clean-up, invoked immediately
         * before a directive, pipe, or service instance is destroyed.
         */
        arcOnDestroy(): void;
    }

}
