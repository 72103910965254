import * as Joi from 'joi';
import { $Joi } from "../joi-validator";
import { makeErrorMessage } from './template';
import { IAdvancedTemplateAction, IGenericTemplateDetails, MAX_RCS_BUTTONS_QUICK_REPLY_CALL_TO_ACTION, MAX_WHATSAPP_TEMPLATE_NAME, TIQuickReplyOptionArray } from '../../../social-media/social-media.model';
import { rcsAdvancedTemplateActionErrors, rcsSchemaToValidateRCSTemplate } from '../../../const-text/views/social-media-rcs';
import { FriendlyMessage, isValidArray, isValidRef, isValidString, pickKeys, groupBy, isValidURL, createHash } from '@colmeia/core/src/tools/barrel-tools';
import { IRCSTemplateParameter } from '../../../social-media/rcs-template-model';
import { ITranslationConfig } from '../../../translation/translation-engine';
import { IRCSAdvancesTemplateAction, RCS, TRCSQuickReplyOptionArray } from '../../../social-media/rcs-interface';
import { isValidMedia } from '@colmeia/core/src/rules/mm-functions';
import { getMimeTypePrefix } from '../../../social-media/social-media.functions';
import { EPartialMimeTypes } from '@colmeia/core/src/multi-media/file-interfaces';
import { templateActionRCS, templateActionTypeConfig } from '../../../bot/bot-action-config-db';
import { EBotActionType } from '../../../bot/new-bot-action';
import { isValidBrazilianPhoneNumberWithoutDDI, isValidGeneralPhoneNumber, isValidWhatsappNumber } from '../../../social-cc/config-cell';
import { SimpleDifferences } from '@colmeia/core/src/tools/utility-types';

const $pickRCSToValidateRCSTemplate = pickKeys<typeof rcsSchemaToValidateRCSTemplate>()

export type ToValidateRCSTemplate =
    & Pick<
        SimpleDifferences<IGenericTemplateDetails, IRCSTemplateParameter>,
        | 'fallbackMessage'
        | 'rcsMessageType'
    >
    & Pick<IGenericTemplateDetails, 'name'>
    ;


export const templatePatterns = createHash<{ [key in keyof ToValidateRCSTemplate]?: RegExp }>()({
    name: /^((?![A-Z])(?=\w)[^])+$/, // must be composed only of lowercase letters, numbers and underscores
})


export const schemaToValidateRCSTemplate = ({
    name:
        $Joi
            .string()
            .pattern(templatePatterns.name)
            .max(MAX_WHATSAPP_TEMPLATE_NAME)
            .required()
            .empty('')
            .messages({
                "string.pattern.base": $pickRCSToValidateRCSTemplate.namePattern,
                'any.required': 'Nome do template não pode estar vazio.',
                'any.empty': 'Nome do template não pode estar vazio.',
            }),
    template:
        $Joi.string()
            .required()
            .empty('')
            .messages({
                'any.required': 'Corpo do template não pode estar vazio.',
                'any.empty': 'Corpo do template não pode estar vazio.',
            }),
})

const formatToMimePrefix: { [key in RCS.Message.Template.Structure.Format]?: EPartialMimeTypes } = {
    [RCS.Message.Template.Structure.Format.Image]: EPartialMimeTypes.Image,
    [RCS.Message.Template.Structure.Format.Video]: EPartialMimeTypes.Video,
}

export function validateRCS(friendly: FriendlyMessage, template: IRCSTemplateParameter) {
    const validation = Joi.object(schemaToValidateRCSTemplate).unknown(true);
    const content = template.content?.[0] || template.content;
    template.template = content.text;

    const messages = validation.validate(template);
    const message: string = messages?.error?.message;

    const errorMessages: string[] = [];
    const errorTranslations: ITranslationConfig[] = [];

    if (isValidString(message)) {
        let translationConfig: ITranslationConfig = rcsSchemaToValidateRCSTemplate[message];
        if (isValidRef(translationConfig)) {
            friendly.addReturnTranslationResponse(
                translationConfig
            )
        } else {
            errorMessages.push(message)
        }
    }

    if (content.text) {
        if (content.text.endsWith('}}'))
            errorMessages.push('O template não pode terminar com uma variável.');
    }

    validateComponents();

    if (isValidArray(template?.advancedOption?.quickReplyOptions)) {
        validateAdvancedTemplateOption(template.advancedOption);
    }

    if (isValidArray(errorMessages)) {
        friendly.addReturnTranslationResponse(
            rcsAdvancedTemplateActionErrors.invalidTemplate,
            makeErrorMessage(errorMessages),
        )
    }

    if (isValidArray(errorTranslations)) {
        errorTranslations.forEach(translation => friendly.addReturnTranslationResponse(
            translation
        ))
    }

    function validateComponents() {
        const header = template.header
        if(header) {
            const isText = header?.format === RCS.Message.Template.Structure.Format.Text;
            const content = header.content?.[0] || header.content;
            const title = Array.isArray(template.content) ? template.content[0]?.title : template.content?.title;


            if (!title && template.templateCategory === RCS.Template.Category.card) {
                errorMessages.push('Título do card não pode estar vazio.');
            }

            if (isText) {
                // it can be empty

            } else {
                if (!isValidMedia(content.media)) {
                    errorMessages.push('Cabeçalho sem mídia.');
                    return;
                }

                const prefix = formatToMimePrefix[header.format];
                const mimePrefix = getMimeTypePrefix(content.media.mymeType);

                if ((isValidRef(prefix) && isValidRef(mimePrefix) && prefix !== mimePrefix)) {
                    errorMessages.push('Formato de mídia inválido');
                }
            }
        }
    }

    function validateAdvancedTemplateOption(advancedOption: IRCSAdvancesTemplateAction) {
        if(isValidArray(advancedOption.quickReplyOptions)) {
            if (advancedOption.quickReplyOptions.length > MAX_RCS_BUTTONS_QUICK_REPLY_CALL_TO_ACTION) {
                errorMessages.push('O número máximo de opções é 4.');
                errorTranslations.push(rcsAdvancedTemplateActionErrors.allowMaxFourButtonsOnTemplate)
            }

            const groupedItems = groupBy(advancedOption.quickReplyOptions, (item) => item.action.type);


            if (!validateButtonsGrouping(advancedOption.quickReplyOptions)) {
                errorTranslations.push(rcsAdvancedTemplateActionErrors.invalidButtonGrouping);
            }

            advancedOption.quickReplyOptions.forEach((option) => {
                switch (option.action.type) {
                    case EBotActionType.quickCallPhone: {
                        const isValueValidNumber = isValidWhatsappNumber(option.buttonData);

                        if (!isValueValidNumber) {
                            errorTranslations.push(rcsAdvancedTemplateActionErrors.invalidButtonPhoneNumber)
                        }
                    }
                        break;
                    case EBotActionType.quickVisitWebsite: {
                        const isValueValidURL: boolean = isValidURL(option.buttonData);
                        if (!isValueValidURL) {
                            errorTranslations.push(rcsAdvancedTemplateActionErrors.invalidButtonURL)
                        }
                    }
                    // TODO: add carousel and calendar
                }
            })
        }
    }
}

export function validateButtonsGrouping(buttons: TRCSQuickReplyOptionArray): boolean {
    for (let i = 0, isReadingSecondGroup = false; i < buttons.length; i++) {
        if (i >= buttons.length - 1) {
            break;
        }

        const action = buttons[i].action;
        const nextAction = buttons[i + 1].action;

        const templateOptionType1 = templateActionRCS[action.type].templateOptionType;
        const templateOptionType2 = templateActionRCS[nextAction.type].templateOptionType;

        if (templateOptionType1 !== templateOptionType2) {
            if (isReadingSecondGroup) {
                return false;
            } else {
                isReadingSecondGroup = true;
            }
        }
    }

    return true;
}
