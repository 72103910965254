import { nonNullable } from "../../tools/type-utils";
import { createHashFromEntries } from "../../tools/utility";
import { EAdvancedTemplateOption, EColmeiaWATemplateApprovalStatus, TColmeiaWhatsAppTemplateApprovalStatus } from "./social-media.model";
import { WhatsApp } from "./whatsapp-interface";


type TStatusConfig = { [key in TColmeiaWhatsAppTemplateApprovalStatus]: IColmeiaWhatsAppTemplateApprovalStatusConfig };
type TAdvancedTemplateOptionConfig = { [key in EAdvancedTemplateOption]: IAdvancedTemplateOptionConfig };


interface IColmeiaWhatsAppTemplateApprovalStatusConfig {
    isPendingUpdate: boolean;
}
interface IAdvancedTemplateOptionConfig {
    isSelectionDisabledWhenSent: boolean;
}


const advancedTemplateOptionConfig: TAdvancedTemplateOptionConfig = createHashFromEntries<TAdvancedTemplateOptionConfig>()([
    [EAdvancedTemplateOption.callToAction, {
        isSelectionDisabledWhenSent: true,
    }],
    [EAdvancedTemplateOption.quickReply, {
        isSelectionDisabledWhenSent: false,
    }]
])
export function getTemplateOptionConfig(option: EAdvancedTemplateOption) {
    const config: IAdvancedTemplateOptionConfig = nonNullable(advancedTemplateOptionConfig[option])
    return config;
}

const statusConfig: TStatusConfig = createHashFromEntries<TStatusConfig>()([
    [WhatsApp.Template.Status.Paused, {
        isPendingUpdate: true,
    }],
    [WhatsApp.Template.Status.LimitExceeded, {
        isPendingUpdate: true,
    }],
    [WhatsApp.Template.Status.Approved, {
        isPendingUpdate: false,
    }],
    [WhatsApp.Template.Status.InAppeal, {
        isPendingUpdate: true,
    }],
    [WhatsApp.Template.Status.Pending, {
        isPendingUpdate: true,
    }],
    [WhatsApp.Template.Status.Rejected, {
        isPendingUpdate: false,
    }],
    [WhatsApp.Template.Status.PendingDeletion, {
        isPendingUpdate: true,
    }],
    [WhatsApp.Template.Status.Deleted, {
        isPendingUpdate: false,
    }],
    [WhatsApp.Template.Status.Disabled, {
        isPendingUpdate: false,
    }],
    [WhatsApp.Template.Status.Flagged, {
        isPendingUpdate: true,
    }],
    [WhatsApp.Template.Status.Reinstated, {
        isPendingUpdate: false,
    }],
    [EColmeiaWATemplateApprovalStatus.Notsent, {
        isPendingUpdate: true,
    }],
    [EColmeiaWATemplateApprovalStatus.ConnectionError, {
        isPendingUpdate: true,
    }],
]);
export function getColmeiaTemplateStatusConfig(status: TColmeiaWhatsAppTemplateApprovalStatus): IColmeiaWhatsAppTemplateApprovalStatusConfig {
    const config: IColmeiaWhatsAppTemplateApprovalStatusConfig = nonNullable(statusConfig[status])
    return config;
}

export const defaultApprovalStatus: TColmeiaWhatsAppTemplateApprovalStatus = EColmeiaWATemplateApprovalStatus.Notsent
