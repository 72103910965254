import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { boolean } from "joi";
import { TGlobalUID } from "../../business/constant";
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";

// export enum EColmeiaContractItemType {
//     customProjectGCP = 'customProjectGCP',
// }

// export const eColmeiaContractItemTypeTranslationMap: Record<EColmeiaContractItemType, string> = {
//   [EColmeiaContractItemType.customProjectGCP]: "Projeto GCP de cliente",
// }

export enum EColmeiaProductCategory {
    SaaS = 'pSaaS',
    professionalServices = 'pPro',
    premiumSupport = 'pPr',
    incidentsCosts = 'pIncCosts',
    messenger = 'pMessenger',
    infra = 'pInfra'
}

export interface IRangeBilling {
    /**
     * limite superior de eventos
     */
    limit: number;
    /**
     * preco por valor unitario
     */
    price: number;
}

export type TIRangeBillingArray = Array<IRangeBilling>
// Exemplo caso o item nao tenha range:
// Banana
// [{init: 0, price: 0.5}]

export type TECostEventArray = Array<ECostEvent>

export enum ECostEvent {
    /**
     * evento de visualização de relatório
     */
    reportUsage = 'eRepUsg',
    /**
     * evento derivado de quantos usuarios unicos acessaram o painel de analytics
     */
    biUser = 'eBIUser',

    // Bot
    /**
     * evento de nova conversacao
     */
    smartFlowConversation = 'eSmFlConv', // Done
    /**
     * evento de nova conversacao por target(address) unico(evento derivado)
     */
    smartFlowMAU = 'eSmFlMAU',

    /**
     * mensagens recebidas de um usuario
     */
    smartFlowCustomerMessage = 'eSmFlCustMsg',
    /**
     * mensagens de bot para cliente
     */
    smartFlowBotMessage = 'eSmFlBotMsg',
    /**
     * mensagens de agente pro cliente
     */
    smartFlowAttMessage = 'eSmFlAttMsg',
    /**
     * mensagens recebidas de um usuario + bot para cliente + agente pro cliente
     *
     * somatorio de smartFlowAttMessage+smartFlowBotMessage+smartFlowCustomerMessage
     */
    smartFlowAttMsgBotMsgCustomerMsg = 'smartFlowAttMsgBotMsgCustomerMsg',
    /**
     * somatorio de mensagens inbound e outbound
     */
    msgCountRecepetiveAndActive = 'eMsgCountRecepetiveAndActive',

    /**
     * evento de mensagens de email enviadas da colmeia pro cliente
     */
    // email = 'eSmFlEmail',
    /**
     * evento de mensagens de sms enviadas da colmeia pro cliente
     */
    // sms = 'eSmFlSMS',
    /**
     * custo de envio de mensagem por canal
     */
    // channelCost = 'eChannelCost',

    // Atendimento
    /**
     * evento de logins feitos pelos atendentes na plataforma
     */
    humanService = 'eHumanSvc',
    /**
     * evento de logins feitos por idAvatar unicos dos agentes que logaram na plataforma
     */
    attendentMAU = 'eAttMAU',

    // OmnisSense
    omniSenseConversation = 'eOmnConv', // nao precisa agora
    omniSenseMessage = 'eOmnMsg', // done

    // Marketing
    /**
     * evento de mensagens enviadas pelo envio-de-campanhas
     */
    mktMessage = 'eMktMsg', // Done
    bpmMarketing = 'eMktBPM', // Done

    // Corporate Search
    corporateSearch = 'eCSMsg', // nao precisa agora
    corporateSearchElastic = 'eCSElasticMsg', // nao precisa agora


    // Eventos de Contrato
    /**
     * @description custo de armazenamento do google-cloud-datastore
     */
    storage = 'eSto', // OK
    /**
     * @description custo de armazenamento do google-cloud-datastore
     */
    blobStorage = 'eBlobStorage', // OK
    /**
     * @description custo de processamento de dados do BigQuery
     */
    dwProcessing = 'eDwProcessing', // OK

    map = 'eMap', // proximo foco
    TextToSpeech = 'eTTS', // proximo foco
    speechToText = 'eSpeechToTxt', // proximo foco

    homologEnvironment = 'eHomolog', // evento manual
    support = 'eSuppBasic', // evento manual
    premiumSupport = 'eSuppPrem', // evento manual
    dwAccess = 'eDwAccess', // evento manual
    hours = 'eManualHours', // evento manual

    /**
     * @description custo de uso do projeto do cliente no GCP
     */
    gcpProject = 'eGCPProject',
    others = 'eManualOthers', // nao precisa
}

export interface IRangeConfig {
    metric: ECostEvent;
    range: TIRangeBillingArray;
    hasMonthlyCommit: boolean;
    monthlyCommit?: number;
    amountEventVolumeLimit?: number;
    /**
     * Este campo serve apenas para saber se o preço varia, exemplo: Big query, storage, etc.
     */
    priceAlwaysMutable?: boolean;
}



export interface IProductModuleClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.productModule;
}
export interface IProductModuleServer extends INonSerializable, IProductModuleClient {
    nsType: ENonSerializableObjectType.productModule;
}

export type TIProductModuleServerArray = Array<IProductModuleServer>;


export enum ETypeServiceProvider {
    nlp = 'nlp', communication = 'comm'
}

export enum ENLPProvider {
    Microsoft = 'NLP_MS', Colmeia = 'NLP_Colmeia'
}

export type TProvidedService = EDelivery360Action | ENLPProvider;

export enum EIncidentCostType {
    taxes = 'taxes',
    exchange = 'exchange',
    discount = 'discount',
    businessUnit = 'business_unit'
}

export enum EOperationTypeAdjust {
    divsion = 'division',
    multiply = 'multiply'
}


/**
 * @description produtos colmeia(utilizados nos itens de contrato dos clientes)
 */
export interface IProductClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.product;
    // productType: EColmeiaContractItemType;
    category: EColmeiaProductCategory;
    deprecatedAt: number; // inicia com zero
    defaultPricing: IRangeConfig;

    isProviderDependent: boolean; // É relacionado a provedor
    serviceType: ETypeServiceProvider;
    providedService: TProvidedService;
};


/**
 * @description produtos colmeia(utilizados nos itens de contrato dos clientes)
 */
export interface IProductServer extends INonSerializable, IProductClient {
    nsType: ENonSerializableObjectType.product;
}

export enum EContractStatus {
    active = 'active',
    inactive = 'inactive',
    canceled = 'canceled'
}

export interface IPaymentEntities {
    /**
     * @description id da "entidade" de pagamento, nao vamos persistir no banco de dados
     */
    idPaymentEntity: string;
    /**
     * @description vamos usar o CNPJ aqui
     */
    documentID: string;
    /**
     * @description Nome da empresa
     */
    entityName: string;
}

export type TIPaymentEntitiesArray = Array<IPaymentEntities>;
export interface IColmeiaBillingContractClient extends INonSerializableHeader {
    idSocialContext: TGlobalUID;
    /**
     * volume minimo de eventos acordado com cliente
     */
    monthlyCommit: number;
    hasValidity: boolean;
    validity: IValidity;
    status: EContractStatus;
    isActive: boolean;
    isDeprecated: IDeprecationInfo;
    /**
     * @description id do projeto do cliente no GCP
     */
    gCloudProjectId?: string;

    paymentEntities?: TIPaymentEntitiesArray;
    splits?: IPaymentSplit[]
};

export enum EAportionType {
    ratio = 'ratio',
    events = 'events', // contando algo e rateando pela proporção
}

export interface IPaymentSplit {
    splitId: string;
    /**
     * tipo de rateamento
     */
    apportion: EAportionType;
    name: string;
    /**
     * compartilhar com fatura principal(false) ou gerar uma fatura propria(true)
     */
    generateExclusiveInvoice: boolean;
    /**
     * se sera necessario gerar uma nota fiscal para este split
     */
    generateNotaFiscal: boolean;
    /**
     * reembolso de despesa
     */
    reembursment: boolean;
    /**
     * se sera necessario um purchase-order
     */
    purchaseOrderNeeded: boolean;
    /**
     * ids dos itens de contrato que estarao dentro deste split
     */
    contractItens: ISplitedItem[];
    splitters: ISpliter[]
}

export interface ISplitedItem {
    idContractItem: IdDep<ENonSerializableObjectType.billingContractItem>; // Byeiij67O08BVRy3dH1aM5yOeKTO2P
    /**
     * percentual de pagamento do total
     */
    proportion?: number;
}

export interface ISpliter {
    /**
     * id da entidade de pagamento
     */
    idPaymentEntity: string;
    /**
    * @deprecated utilizar proportion do ISplitedItem
    */
    proportion: number;
}



export type TActiveContractID = IdDep<ENonSerializableObjectType.billingContract>;
export type TActiveContractProductID = string;

/**
 * @description contrato de cliente da colmeia
 */
export interface IColmeiaBillingContractServer extends INonSerializable, IColmeiaBillingContractClient {
    nsType: ENonSerializableObjectType.billingContract;
}

export interface IDeprecationInfo {
    reason: string;
    deprecatedAt: number;
}

export interface IValidity {
    initAt: number;
    endAt: number; // endAt = initAt = 1 pgto

}

export enum EIncidentCostsProcessType {
    preProcess = 'preProcess',
    postProcess = 'postProcess'
}
export interface IIncidentCosts {
    index: number;
    operation: EOperationTypeAdjust;
    type: EIncidentCostType;
    description: string;
    processType: EIncidentCostsProcessType;
}

export type TIIncidentCostsArray = Array<IIncidentCosts>;

/**
 * @description item de contrato da colmeia
 */
export interface IProductSKUClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.billingContractItem;
    // itemType: EColmeiaContractItemType;
    idProduct: string;
    isRefund: boolean;
    deprecatedAt: number; // inicia com zero
    pricingRange: IRangeConfig; // IRangeConfigServer
    hasValidity: boolean;
    validity: IValidity;
    incidentsCosts?: TIIncidentCostsArray;
    manualInput?: boolean;
}

/**
 * Item de contrato do cliente
 */
export interface IBillingContractItem extends INonSerializable, IProductSKUClient {
    nsType: ENonSerializableObjectType.billingContractItem;
}


export interface ICorporateGroup {
    primaryID: TGlobalUID;
    name: string;
}

export type TICorporateGroupArray = Array<ICorporateGroup>;

export function isRangeUniquePricing(rangeConfig: IRangeConfig): boolean {
    return (rangeConfig.range.length === 1) && (rangeConfig.range[0].limit === 0);
}

/**
 * Cria um range de preço único, util pros casos de preços fixos, como o de custos manuais, que so tem um valor e 0 volume
 * @param price
 * @returns
 */
export function createSinglePriceRange(metric: ECostEvent, price: number): IRangeConfig {
    const priceConfig: IRangeBilling = {
        limit: 0,
        price,
    }
    return {
        metric,
        range: [priceConfig],
        hasMonthlyCommit: false,
    }
}
