import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { detectSimpleChange } from 'app/model/client-utility';
import { TColmeiaExtraPallete } from "@colmeia/core/src/shared-business-rules/brand-theme/theme-types"
import { coerceBooleanProperty } from '@angular/cdk/coercion';

export interface IStringLengthRangeConfig {
    label: string,
    min?: number,
    max?: number,
    theme?: ThemePalette | TColmeiaExtraPallete,
}

export type TStringLengthRanges = Array<IStringLengthRangeConfig>;

@Component({
    selector: 'stringmometer',
    templateUrl: './stringmometer.component.html',
    styleUrls: ['./stringmometer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StringmometerComponent implements OnInit {

    @Input()
    stringLength: number;

    @Input()
    rangesConfigs: TStringLengthRanges = [];

    _cumulative: boolean;
    @Input()
    set cumulative(value: boolean) {
        this._cumulative = coerceBooleanProperty(value);
    }

    public rangesMatched: IStringLengthRangeConfig[];

    constructor(private cdr: ChangeDetectorRef) { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if(detectSimpleChange(changes.stringLength) || detectSimpleChange(changes.labelsConfig)) {
            this.defineLabel();
        }

        this.cdr.markForCheck();
    }

    private defineLabel() {
        this.rangesMatched = this.rangesConfigs
        .filter( c =>
            this.stringLength >= (c.min ?? 0) &&
            this.stringLength <= (c.max ?? Number.MAX_SAFE_INTEGER)
        );
    }
}
