import { ComponentType } from "@angular/cdk/portal";
import { EBPMAction, TAllBPMACtion } from "@colmeia/core/src/shared-business-rules/BPM/bpm-action-model";
import { EBPMMarketingActionType } from "@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model";
import { EBPMTicketAgentAction } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-bpm-action.model";


type TMaterialIcon = string;




interface IBpmClientConfigItem<T extends TAllBPMACtion> {
    icon: TMaterialIcon;
    component: ComponentType<unknown>;
}

type TBpmClientConfigDB = {
    [key in TAllBPMACtion]: IBpmClientConfigItem<key>
}

export const bpmClientConfigDB: TBpmClientConfigDB = {
    [EBPMAction.action]: {
        component: undefined,
        icon: "launch",
    },
    [EBPMAction.validator]: {
        component: undefined,
        icon: "check_circle",
    },
    [EBPMAction.conditionalDisplay]: {
        component: undefined,
        icon: "visibility",
    },
    [EBPMAction.mktFilter]: {
        component: undefined,
        icon: "check_circle",
    },
    [EBPMAction.anomization]: {
        component: undefined,
        icon: "person_outline",
    },
    [EBPMAction.getTitularData]: {
        component: undefined,
        icon: "badge",
    },
    [EBPMAction.optOut]: {
        component: undefined,
        icon: "person_remove",
    },
    [EBPMAction.optIn]: {
        component: undefined,
        icon: "person_add",
    },
    [EBPMAction.solicitation]: {
        component: undefined,
        icon: "pending_actions",
    },
    [EBPMTicketAgentAction.Notification]: {
        component: undefined,
        icon: "notifications",
    },
    [EBPMTicketAgentAction.executeFunction]: {
        component: undefined,
        icon: "code",
    },
    [EBPMTicketAgentAction.Assign]: {
        component: undefined,
        icon: "assignment",
    },
    [EBPMTicketAgentAction.CloseTicket]: {
        component: undefined,
        icon: "disabled_by_default",
    },
    [EBPMTicketAgentAction.SendCampaign]: {
        component: undefined,
        icon: "campaign",
    },
    [EBPMMarketingActionType.messageWithCampaing]: {
        component: undefined,
        icon: "campaign",
    },
    [EBPMMarketingActionType.goCampaignWithBot]: {
        component: undefined,
        icon: "smart_toy",
    },
};



export const BPMActionIcons = <{ [key in TAllBPMACtion]: TMaterialIcon }>Object.entries(bpmClientConfigDB).reduce((iconsHash, [type, config]) => {
    iconsHash[type] = config.icon

    return iconsHash;
}, {});
