import { IIdNSToNserMap } from '../../core-constants/types';
import { TIBasicElementServerArray } from '../graph/essential/graph-basic-element-interfaces';
import { TIBasicConditionArray } from '../metadata/meta-engagement';
import { INonSerializable, TINonSerializableArray } from '../non-serializable-id/non-serializable-id-interfaces';
import { IBPMActionModel } from './bpm-action-model';

export type TEBPMTypeArray = Array<EBPMType>

export enum EBPMType {
    conditional = 'conditional',
    lgdPM = 'lgpdBPM',
    bot = 'bpmBOT',
    crm = 'bpmCRM',
    marketing = 'bpmMKT',
    nestedAI = 'bpmNestedAI',
    service = 'service',
}

import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { TBpmMarketingNaturalNsTypes } from './marketing/bpm-marketing.model';
import { TCrmBpmNodes } from '../crm/crm-services/crm-bpm-model';

type TAllBpmNodeTypes = TBpmMarketingNaturalNsTypes | TCrmBpmNodes;

type TNSToBPMType = { [key in TAllBpmNodeTypes]: EBPMType };

export const subscriberToBPMTypeMap: TNSToBPMType = {
    [ENonSerializableObjectType.bpmMarketingRoot]: EBPMType.marketing,
    [ENonSerializableObjectType.bpmMarketingListener]: EBPMType.marketing,
    [ENonSerializableObjectType.bpmMarketingPause]: EBPMType.marketing,
    [ENonSerializableObjectType.bpmMarketingAction]: EBPMType.marketing,
    [ENonSerializableObjectType.bpmMarketingEnd]: EBPMType.marketing,
    [ENonSerializableObjectType.bpmMarketingAcknowledge]: EBPMType.marketing,

    [ENonSerializableObjectType.crmServiceConfigRoot]: EBPMType.crm,
    [ENonSerializableObjectType.crmServiceConfigAgentEvent]: EBPMType.crm,
    [ENonSerializableObjectType.crmServiceConfigNotification]: EBPMType.crm,
    [ENonSerializableObjectType.crmServiceConfigProcessingNode]: EBPMType.crm,
};


export type TBPMHandlers = { [key in EBPMType]?: (element: ISaveHostedFromGraph) => Promise<TINonSerializableArray> };
export type TBPMSpecificHandlers = { [key in EBPMType]?: (element: ISaveHostedFromGraph) => Promise<TINonSerializableArray> };
export interface ISaveHostedFromGraph {
    element: INonSerializable,
    idGraphRoot: IdDep<ENonSerializableObjectType.graphElement>;
    idParentGraphNode?: string;
}

export interface IBPMBasicConditionEvaluator {
    name: string;
    matchConditions: TIBasicConditionArray;
}

export interface IBPConditionalEvaluator extends IBPMBasicConditionEvaluator {
    action?: IBPMActionModel;
    stopAction?: boolean;
};


export type TIBPConditionalEvaluatorArray = Array<IBPConditionalEvaluator>;


export interface INewExternalElement {
    newElement: INonSerializable; //gc
    linkedTo: INonSerializable[] // ma, mb, mc
}

export interface INewHostedExternalElement {
    hostedParent: INonSerializable,
    hostedToCreate: INonSerializable[]
}

export type TINewHostedExternalElementArray = INewHostedExternalElement[]
export interface IUpdatedBPMGraphNodesInRuntime {
    updatedGraphElements: TIBasicElementServerArray,
    updatedHostedMap: IIdNSToNserMap,
}
export type TINewExternalElementArray = Array<INewExternalElement>;
