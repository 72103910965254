import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { EColmeiaColorPallete, EColorVariant, EShapeStyle, ITagConfig } from "@colmeia/core/src/shared-business-rules/brand-theme/theme-types";

const defaultConfig = { tagStyle: EShapeStyle.Flat, color: EColmeiaColorPallete.primary, colorVariant: EColorVariant.default }

@Component({
    selector: "app-tag",
    styles: [`:host { display: inline-block; }`],
    template: `<span [ngClass]='classes'><ng-content></ng-content></span>`,
})
export class AppTagComponent implements OnInit, OnChanges {
    @Input() color: EColmeiaColorPallete = defaultConfig.color;
    @Input() colorVariant: EColorVariant = defaultConfig.colorVariant;
    @Input() style: EShapeStyle = defaultConfig.tagStyle;
    @Input() invert: boolean = false;

    @Input()
    config: ITagConfig = { ...defaultConfig };

    classes: string;

    ngOnInit() {
        this.generateClassNames();
    }

    ngOnChanges() {
        this.generateClassNames();
    }

    private generateClassNames() {
        this.color = this.config.color;
        this.style = this.config.tagStyle;
        this.invert = !!this.config.invertColor;
        this.colorVariant = this.config.colorVariant;

        this.classes = `app-tag-base app-${this.color} app-${this.style} app-${this.colorVariant}`;

        if (this.invert) {
            this.classes = `${this.classes} app-invert`;
        }
    }

}