import { EDelivery360Action } from '../../comm-interfaces/interaction-interfaces';
import { IFileField } from '../../request-interfaces/files-interfaces';
import { ENonSerializableObjectType, INonSerializableMedia } from '../non-serializable-id/non-serializable-id-interfaces';
import { ICompiledTemplateWithVariables } from '../metadata/metadata-util-interfaces';
import { Explicit } from '../../tools/utility/types';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';

export enum ECampaignActionType {
    invites = 'CampInvites',
    activeCallcenter = 'CampActiveCC',
    massComm = 'CampMassComm',
    personalizedBot = 'CampPerBot'
}



export interface ISetOmniChannelSchedule {
    channelType: EDelivery360Action;
    offset: number;
    clockTick?: number;
}

export type TOmniChannelArray = Array<ISetOmniChannelSchedule>

//
export interface ICampaignOmniChannelSchedule extends ISetOmniChannelSchedule {
    idSocialMediaConnection: IdDep<ENonSerializableObjectType.socialMediaConnection>;
}


export type MapEDelivery360ActionToICampaignOmniChannelSchedule = Explicit<{ [key in EDelivery360Action]: ICampaignOmniChannelSchedule }, {
    [EDelivery360Action.Delivery360Email]: ICAEmailChannelSchedulle;
    [EDelivery360Action.Delivery360WhatsApp]: ICAWhatsAppSchedule;
    [EDelivery360Action.Delivery360Voice]: ICAVoiceChannelSchedule;
    [EDelivery360Action.Delivery360SMS]: ICASMSChannelSchedule;
    [EDelivery360Action.Delivery360RCS]: ICARCSChannelSchedule;

    [EDelivery360Action.DeliveryColmeia]: ICampaignOmniChannelSchedule;
    [EDelivery360Action.Delivery360FBMessenger]: ICAMessengerSchedule;
    [EDelivery360Action.DeliveryInstagram]: ICAMessengerSchedule;
    [EDelivery360Action.DeliveryTelegram]: ICATelegramSchedule;
}>

export type TICampaignActionCommStrategy = Array<ICampaignOmniChannelSchedule>;

export interface ICAMessengerSchedule extends ICampaignOmniChannelSchedule {}
export interface ICATelegramSchedule extends ICampaignOmniChannelSchedule {}

export interface ICAWhatsAppSchedule extends ICampaignOmniChannelSchedule {
    idWAFallbackTemplate?: IdDep<ENonSerializableObjectType.channelTemplate>;
    preferedIfChannelOpen?: boolean
    offSetOtherChannelsIfPrefered?: number;
    fixedFields?: IFileField;
}

export interface ICAEmailChannelSchedulle extends ICampaignOmniChannelSchedule {
    // idTemplate: string;
    title: ICompiledTemplateWithVariables;
    idTemplateEmail: string;
    fromName: string;
};

export interface ICASMSChannelSchedule extends ICampaignOmniChannelSchedule {
    idTemplate: string;
}
export interface ICARCSChannelSchedule extends ICampaignOmniChannelSchedule {
    idTemplate: string;
}


export interface ICAVoiceChannelSchedule extends ICampaignOmniChannelSchedule, INonSerializableMedia {
    isMedia: boolean;
}







