import { Component, Input } from "@angular/core";
import { EButtonStyle, TColmeiaPallete } from "@colmeia/core/src/shared-business-rules/brand-theme/theme-types";

// TODO
@Component({
    selector: "app-button",
    template: `<button [disabled]=[disabled]><ng-content></ng-content></button>`,
})
export class AppButtonComponent {
    @Input() disabled: boolean = false;
    @Input() color: TColmeiaPallete;
    @Input() style: EButtonStyle;

}