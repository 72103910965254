import { EBotActionType, TMarketingAction, TRCSMarketingAction } from "./new-bot-action";
import { ENextGenBotElementType } from "./bot-model";
import { createHash, keys, typedClone, values } from "../../tools/utility";
import { ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces";
import { UnionToArray } from "../../tools/utility-types";
import { EAdvancedTemplateOption } from "../social-media/social-media.model";
import { groupBy } from "lodash";
import { WhatsApp } from "../social-media/whatsapp-interface";
import { RCS } from "../social-media/rcs-interface";


export const whatsTel = ':wht'

export const socialConfig = {
    whatsService: {
        prefix: 'https://wa.me/' + whatsTel + '?text=',
        init: 'iw:',
        size: 12
    }
}


export type TBotActionCharacteristicFilter = (db: TBotActionTypeConfig) => boolean;

type TActionEventOfBranch =
    | ENextGenBotElementType
    | ENonSerializableObjectType.socialMediaConnection
    ;

export interface TBotActionTypeConfig {
    actionEventOfBranch: TActionEventOfBranch[];
    isPreCondition?: boolean;
    nsPickerFilter?: {
        showMenuContainer?: true;
        showAttIsland?: true;
        showTransactions?: true;
        showBotRoot?: true;
    };
    showConditional?: boolean;
    allowedPosAction?: {
        [key in ENextGenBotElementType]?: {
            parentTypes: ENextGenBotElementType[],
            allowedActions: EBotActionType[]
        }
    };
    isFinishBot?: boolean;
    hasIDElemt: boolean;
    canExecuteFunctionBefore?: boolean;
    canExecuteFunctionAfter?: boolean;
};


export type TActionDB = { [key in EBotActionType]: TBotActionTypeConfig };
export const eBotActionDB: TActionDB = {

    [EBotActionType.goIslandOnTemplate]: {
        actionEventOfBranch: [],
        hasIDElemt: true,
        nsPickerFilter: {
            showMenuContainer: true,
        },
    },

    [EBotActionType.quickContinue]: initDefaultTemplateActionTypeConfig(),
    [EBotActionType.quickCallPhone]: initDefaultTemplateActionTypeConfig(),
    [EBotActionType.quickVisitWebsite]: initDefaultTemplateActionTypeConfig(),

    [EBotActionType.goActionTree]: {
        actionEventOfBranch: [ENextGenBotElementType.botMenuItem, ENextGenBotElementType.metadata, ENextGenBotElementType.root],
        hasIDElemt: true,
        nsPickerFilter: { showMenuContainer: true }
    },

    [EBotActionType.contentGenerator]: {
        actionEventOfBranch: [ENextGenBotElementType.botMenuItem, ENextGenBotElementType.root],
        hasIDElemt: true,
        nsPickerFilter: { showTransactions: true },
        allowedPosAction: {
            [ENextGenBotElementType.formTransaction]: {
                parentTypes: [ENextGenBotElementType.root],
                allowedActions: [EBotActionType.goBot, EBotActionType.goActionTree]
            }
        }
    },

    [EBotActionType.goBack]: {
        actionEventOfBranch: [ENextGenBotElementType.botMenuItem],
        hasIDElemt: false,
    },


    [EBotActionType.continueFlow]: {
        actionEventOfBranch: [],
        hasIDElemt: false,
    },

    [EBotActionType.goBot]: {
        actionEventOfBranch: [ENextGenBotElementType.botMenuItem, ENonSerializableObjectType.socialMediaConnection],
        isPreCondition: true,
        hasIDElemt: true,
        nsPickerFilter: {
            showBotRoot: true
        }
    },

    [EBotActionType.goFirstTree]: {
        actionEventOfBranch: [ENextGenBotElementType.botMenuItem, ENextGenBotElementType.metadata],
        hasIDElemt: false,
    },

    [EBotActionType.goHuman]: {
        actionEventOfBranch: [ENextGenBotElementType.botMenuItem],
        nsPickerFilter: { showAttIsland: true },
        isPreCondition: true,
        isFinishBot: true,
        hasIDElemt: true,
    },

    [EBotActionType.isEnd]: {
        actionEventOfBranch: [ENextGenBotElementType.botMenuItem],
        isFinishBot: true,
        hasIDElemt: false,
    },

    [EBotActionType.goIsland]: {
        nsPickerFilter: { showAttIsland: true },
        actionEventOfBranch: [],
        hasIDElemt: false,
    },

    [EBotActionType.yesAnswer]: {
        actionEventOfBranch: [],
        hasIDElemt: false,
    },

    [EBotActionType.noAnswer]: {
        actionEventOfBranch: [],
        hasIDElemt: false,
    },

    [EBotActionType.exitCommand]: {
        actionEventOfBranch: [],
        hasIDElemt: false,
    },


    [EBotActionType.yesOrNoAction]: {
        actionEventOfBranch: [],
        hasIDElemt: false,
    },
    /*@TODO conferir isto*/
    [EBotActionType.redoAnswer]: {
        hasIDElemt: false,
        actionEventOfBranch: []
    },
    [EBotActionType.reuseForm]: {
        hasIDElemt: false,
        actionEventOfBranch: []
    },
    [EBotActionType.fillForm]: {
        hasIDElemt: false,
        actionEventOfBranch: []
    },

    [EBotActionType.LGPD]: {
        actionEventOfBranch: [ENextGenBotElementType.botMenuItem, ENonSerializableObjectType.socialMediaConnection],
        hasIDElemt: false,
    },

    [EBotActionType.gotoProperty]: {
        actionEventOfBranch: [],
        hasIDElemt: true,
    },

    [EBotActionType.upsertTicket]: {
        actionEventOfBranch: [],
        hasIDElemt: true,
    },

    [EBotActionType.otpCopyCode]: {
        actionEventOfBranch: [],
        hasIDElemt: false,
    },

    [EBotActionType.copyCode]: {
        actionEventOfBranch: [],
        hasIDElemt: false,
    },

    [EBotActionType.quickReply]: {
        actionEventOfBranch: [],
        hasIDElemt: false,
    },
};

export function getBotAction(botActionType: EBotActionType): TBotActionTypeConfig {
    return eBotActionDB[botActionType];
}

export function geActionDB(): TActionDB {
    return eBotActionDB;
}

function initDefaultTemplateActionTypeConfig(): TBotActionTypeConfig {
    const defaultTemplateActionTypeConfig: TBotActionTypeConfig = {
        actionEventOfBranch: [ENonSerializableObjectType.socialMediaConnection],
        hasIDElemt: false,
    }
    return defaultTemplateActionTypeConfig
}


interface ITemplateActionTypeConfig {
    buttonType: WhatsApp.Template.ButtonType;
    templateOptionType: EAdvancedTemplateOption
}

export const templateActionTypeConfig = createHash<{ [key in TMarketingAction]: ITemplateActionTypeConfig }>()({
    [EBotActionType.quickCallPhone]: {
        buttonType: WhatsApp.Template.ButtonType.PhoneNumber,
        templateOptionType: EAdvancedTemplateOption.callToAction,
    },
    [EBotActionType.quickVisitWebsite]: {
        buttonType: WhatsApp.Template.ButtonType.URL,
        templateOptionType: EAdvancedTemplateOption.callToAction,
    },
    [EBotActionType.quickContinue]: {
        buttonType: WhatsApp.Template.ButtonType.QuickReply,
        templateOptionType: EAdvancedTemplateOption.quickReply,
    },
    [EBotActionType.goBot]: {
        buttonType: WhatsApp.Template.ButtonType.QuickReply,
        templateOptionType: EAdvancedTemplateOption.quickReply,
    },
    [EBotActionType.LGPD]: {
        buttonType: WhatsApp.Template.ButtonType.QuickReply,
        templateOptionType: EAdvancedTemplateOption.quickReply,
    },
    [EBotActionType.goIslandOnTemplate]: {
        buttonType: WhatsApp.Template.ButtonType.QuickReply,
        templateOptionType: EAdvancedTemplateOption.quickReply,
    },
    [EBotActionType.otpCopyCode]: {
        buttonType: WhatsApp.Template.ButtonType.OTP,
        templateOptionType: EAdvancedTemplateOption.callToAction
    },
    [EBotActionType.copyCode]: {
        buttonType: WhatsApp.Template.ButtonType.CopyCode,
        templateOptionType: EAdvancedTemplateOption.callToAction
    }
})


interface IRCSTemplateActionTypeConfig {
    buttonType: RCS.Template.ButtonType;
    templateOptionType: EAdvancedTemplateOption
}

export const templateActionRCS = createHash<{ [key in TRCSMarketingAction]: IRCSTemplateActionTypeConfig }>()({
    [EBotActionType.quickCallPhone]: {
        buttonType: RCS.Template.ButtonType.PhoneNumber,
        templateOptionType: EAdvancedTemplateOption.callToAction,
    },
    [EBotActionType.quickVisitWebsite]: { 
        buttonType: RCS.Template.ButtonType.URL,
        templateOptionType: EAdvancedTemplateOption.callToAction,
    },
    [EBotActionType.quickReply]: {
        buttonType: RCS.Template.ButtonType.Message,
        templateOptionType: EAdvancedTemplateOption.callToAction,
    },
})

export function getRCSTemplateActions(): TRCSMarketingAction[] {
    return keys(templateActionRCS);
}

export function getTemplateActions(): TMarketingAction[] {
    return keys(templateActionTypeConfig);
}

type TTemplateActionByType = {
    [type in EAdvancedTemplateOption]: TMarketingAction[]
}

function whatsAppTemplateButtonTypeToOption(type: WhatsApp.Template.ButtonType): EAdvancedTemplateOption {
    if (type === WhatsApp.Template.ButtonType.QuickReply) return EAdvancedTemplateOption.quickReply;
    return EAdvancedTemplateOption.callToAction;
}


const templateActionsByTemplateType = (groupBy(keys(templateActionTypeConfig), option => whatsAppTemplateButtonTypeToOption(templateActionTypeConfig[option].buttonType))) as unknown as TTemplateActionByType;


export function getTemplateActionByTemplateType(type: EAdvancedTemplateOption) {
    return templateActionsByTemplateType[type];
}
