import { IFileField } from "@colmeia/core/src/request-interfaces/files-interfaces";
import { IExtraInformation } from "@colmeia/core/src/shared-business-rules/connections/connections-requests";
import { Miliseconds } from "@colmeia/core/src/time/time-utl";
import { EDelivery360Action } from "../../comm-interfaces/interaction-interfaces";
import { EConfirmationType, TGlobalUID } from "../../core-constants/types";
import { TGeneralFieldArray } from "../../general-form/general-form-answer";
import { TSchemaPropertyArray } from "../../general-form/general-form-interface";
import { suggestions } from "../../tools/type-utils";
import { keys, values } from "../../tools/utility";
import { ReMapObject } from "../../tools/utility-types";
import { ESendActiveMethod } from "../active-1x1-call/active-1x1-model";
import { TContentAssetArray } from "../bot/bot-content-model";
import { IExecuteCallbackInfo } from "../colmeia-apis/colmeia-callback";
import { ITicketAttachedInfo } from "../crm/crm-entities";
import { TMetadataNameArray } from "../metadata/metadata-db";
import { ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";
import { TIISocialMediaConnectionArray } from "../social-media/social-media.model";
import { ICampaingActionHeader } from "./campaign-type-model";
import { ICampaignExecutionServer } from "./capaing-exec-interfaces";

export interface IActiveCallInfo {
    sendMethod: ESendActiveMethod;
    customMessage: TContentAssetArray;
    idAgentAvatar: TGlobalUID;
    respondToMe: boolean;
}


export interface IRTF1x1Form {
    form: TSchemaPropertyArray;
    answer: TGeneralFieldArray;
    idMetadata: string;
}
export interface IExecuteMassCommRow {
    idCampaing: string;
    action: ICampaingActionHeader;
    row: TGeneralFieldArray;
    idGlobalCanonicals: TMetadataNameArray;
    connections: TIISocialMediaConnectionArray;
    whatsIfChannelOpen: boolean;
    idSocialMedia: string;
    form: TSchemaPropertyArray;
    scheduleBeginning: number;
    activeCallInfo: IActiveCallInfo;
    variables: TICampaingVariableConfigArray;
    fired: ICampaignExecutionServer;
    is2FA: boolean;
    isQuickMkt?: boolean; // is2FA é iniciado pelo cliente portanto não tem problema com optOut. Já o isQuickMKT tem que seguir a regra
    rtfForm?: IRTF1x1Form;
    isScheduledAlready?: boolean;
    idWaitingEvent?: string;
    idBPM?: string;
    ticketInfo?: ITicketAttachedInfo;
}


export type TCommElementArray = Array<ICommElement>;

export interface ICommExecutionBackgroundController {
    idSocialContext: TGlobalUID;
    idRequest: string;
    clockTick: number;
    nextElement: number;
    idMassEntry: string;
    idCampaignAction: string;
    idRunCampaign: string;
    row: TGeneralFieldArray;
    activeCallInfo: IActiveCallInfo;
    tries: number;
    isQuickSend?: boolean
}

export interface TCommExecution {
    elements: TCommElementArray
}


export interface ICommElement {
    target: string;
    channel: EDelivery360Action;
    idChannel?: IdDep<ENonSerializableObjectType.socialMediaConnection>;
    clockTick: number;
}

export type TIReceiveRegisterArray = Array<IReceiveRegister>;

export interface IReceiveRegister {
    confirmationType: EConfirmationType;
    clockTick: number;
    address: string;
}

export type IMassOpenChannel = {
    [key in EDelivery360Action]?: boolean
}


export type TMassCommAnswerArray = Array<IMassCommEntry>;

export interface ICampaingVariableConfig {
    idLocalCanonical: IdDep<ENonSerializableObjectType.canonical>
    isGreetings?: boolean;
    value?: string;
}

export type TICampaingVariableConfigArray = Array<ICampaingVariableConfig>;


export type IEntryCancelReason = {
    cancelFilter: boolean;
    cancelOptOut: boolean;
    cancelRecency: boolean;
    cancelActiveChat: boolean;
    cancelEngagement: boolean;
    cancelSchedule: boolean;
    cancelByUser?: boolean;
    cancelReschedule?: boolean;
    cancelInvalidTemplate?: boolean;
    cancelSendOnlyIfChannelOpen?: boolean;
}

export enum EEntryCancelReason {
    Filter = 'cancelFilter',
    OptOut = 'cancelOptOut',
    Recency = 'cancelRecency',
    ActiveChat = 'cancelActiveChat',
    Engagement = 'cancelEngagement',
    Schedule = 'cancelSchedule',
    ByUser = 'cancelByUser',
    Reschedule = 'cancelReschedule',
    InvalidTemplate = 'cancelInvalidTemplate',
    SendOnlyIfChannelOpen = 'cancelSendOnlyIfChannelOpen',
}

type TEnum = ReMapObject<{ [key in keyof IEntryCancelReason]: { [$key in (key extends `cancel${infer Value}` ? Value : never)]: key } }>
suggestions<TEnum>()(EEntryCancelReason);

const cancelReasonKeys: (keyof IEntryCancelReason)[] = values(EEntryCancelReason);

export function getCancelReasons(entry: IEntryCancelReason): `${EEntryCancelReason}`[] {
    return keys(entry).filter(value => cancelReasonKeys.includes(value) && entry[value]);
}

export interface IMassCommCallbackRowSent {
    clockTick: Miliseconds,
    success: boolean,
    status: EConfirmationType,
    rowSent: IExtraInformation
}

export type TIMassCommEntryArray = Array<IMassCommEntry>;

export interface IMassCommCallbackRowSent {
    clockTick: Miliseconds,
    success: boolean,
    status: EConfirmationType,
    rowSent: IExtraInformation
}


export interface ICallbackMKTMessageConfirmation {
    idMassEntry: string;
    clockTick: Miliseconds;
    target: string;
    status: EConfirmationType;
    channel: EDelivery360Action;
}

export type TICallbackMKTMessageConfirmationArray = Array<ICallbackMKTMessageConfirmation>;

export interface IMassCommEntry {
    idFiredCampaing: string;  // idRunCampaign
    idMassEntry: string;
    idSocialContext: TGlobalUID;
    idCampaing: string;
    idCampaigAction: string;
    idBot: string;
    clockTick: number;
    target: string;
    scheduledTo: number;
    strategy: TCommExecution;
    sendToAllChannels: boolean;
    lastChannel: EDelivery360Action;
    isDelivered: boolean;
    idAvatar: TGlobalUID;
    responded: number;
    dontSendMessageIfTemplateSent?: boolean;
    isError: boolean;
    error: Error[];
    form: TSchemaPropertyArray;
    templateSent: IMassOpenChannel;
    messageSent: IMassOpenChannel;
    billedTemplate: IMassOpenChannel;
    openChannel: IMassOpenChannel;
    activeCallAvatar: TGlobalUID;
    idConversation: string;
    engagementSuccess?: EConfirmationType;
    variables: TICampaingVariableConfigArray;
    idAgentAvatar?: TGlobalUID;

    idSocialKey?: string;

    rtfForm?: IRTF1x1Form;
    // campos setados caso haja callback
    callbackInfo?: IExecuteCallbackInfo;
    idBPMMKT?: string;
    idCBMessage?: string; // Será o ID da mensagem que efetivamente irá ser processado quando houver callback (lido)

    cancel?: IEntryCancelReason; // Tirar do index pf

    idWaitingCampaignAsMessageEvent? : string

    template?: {
        idTemplate: IdDep<ENonSerializableObjectType.channelTemplate>;
        hashVariables: IFileField;
    }

    ticketInfo?: ITicketAttachedInfo

}

