import { Injectable } from '@angular/core';
import { EFacebookChannelIntegrationReqRes } from '@colmeia/core/src/request-interfaces/message-types';
import { IFacebookGetColmeiaLoginConfigResponse, IFacebookGetColmeiaAppLoginConfigRequest, IMetaWhatsappChannelCheckIfPhoneIsRegisteredRequest, IMetaWhatsappChannelCheckIfPhoneIsRegisteredResponse, IMetaExchangeCodeForTokenAndGetWhatsappInfoRequest, IMetaExchangeCodeForTokenAndGetWhatsappInfoResponse, IMetaRegisterWebhookAndPhoneNumber, IMetaRegisterWebhookAndPhoneNumberRequest, IMetaRegisterWebhookAndPhoneNumberResponse } from '@colmeia/core/src/shared-business-rules/channels/facebook-integration-req-resp.dto';
import { isValidString } from '@colmeia/core/src/tools/barrel-tools';
import { ServerCommunicationService } from 'app/services/server-communication.service';

@Injectable({
    providedIn: 'root'
})
export class WhatsappAuthService {

    constructor(
        private api: ServerCommunicationService
    ) { }

    async registerPhoneNumber(metaAPIData: IMetaRegisterWebhookAndPhoneNumber): Promise<IMetaRegisterWebhookAndPhoneNumberResponse> {
        const result = await this.api.sendRequest<IMetaRegisterWebhookAndPhoneNumberRequest, IMetaRegisterWebhookAndPhoneNumberResponse>(
            EFacebookChannelIntegrationReqRes.registerWebhookAndPhoneNumber
        )(metaAPIData);
        return result
    }

    async exchangeCodeByToken(codeToBeExchangedToToken: string, isGetWABAName?: boolean) {
        const result = await this.api.sendRequest<IMetaExchangeCodeForTokenAndGetWhatsappInfoRequest, IMetaExchangeCodeForTokenAndGetWhatsappInfoResponse>(
            EFacebookChannelIntegrationReqRes.exchangeCodeForTokenAndGetWhatsappInfo
        )({
            codeToBeExchangedToToken,
            isGetWABAName,
        });
        return result
    }

    async checkIfPhoneIsRegistered(phoneID: string, isMustThrowOnError: boolean = true) {
        const response = await this.api.sendRequest<IMetaWhatsappChannelCheckIfPhoneIsRegisteredRequest, IMetaWhatsappChannelCheckIfPhoneIsRegisteredResponse>(
            EFacebookChannelIntegrationReqRes.checkIfPhoneIsRegistered
        )({
            data: { phoneID, isMustThrowOnError }
        });

        return {
            isPhoneRegistered: response?.isPhoneRegistered,
            errorDescription: response?.errorDescription
        };
    }
}
