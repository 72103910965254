import { IRequest } from "../../request-interfaces/request-interfaces";
import { IResponse } from "../../request-interfaces/response-interfaces";
import { IAccessToken, ICustomerAdEvent, IAdObjects, TIAccessTokens, TIAdAccountItems, TIAdProviders, TIBusinessAccountItems, TIColmeiaAdEventItems, TITrackingTagItems, ICreateCustomerEventParams, ICreateTrackingTagParams, TIAdCampaignItems, TIAdSetItems, TIAdItems, IUpdateTrackingTagParams } from "./marketing-performance-ads-shared-types";


export enum EMarketingPerformanceRequests {
    getAccessTokens = 'mp.getAccessTokens',
    saveAccessToken = 'mp.saveAccessToken',

    getBusinessAccounts = 'mp.getBusinessAccounts',
    getTrackingTags = 'mp.getTrackingTags',
    createTrackingTag = 'mp.createTrackingTag',
    updateTrackingTag = 'mp.updateTrackingTag',

    getAdProviders = 'mp.getAdProviders',
    getAdAccounts = 'mp.getAdAccounts',
    getAdCampaigns = 'mp.getAdCampaigns',
    getAdSets = 'mp.getAdSets',
    getAds = 'mp.getAds',

    synchronizeAds = 'mp.synchronizeAds',
    setAdTrackingTag = 'mp.setAdTrackingTag',

    getColmeiaEvents = 'mp.getColmeiaEvents',
    getAdEvents = 'mp.getAdEvents',
    createAdEvent = 'mp.createAdEvent',
}

export enum EMarketingPerformanceMessages {
    businessAccountNotFound,
    businessAccountDuplicated,
}

//Access tokens
export interface IMarketingPerformanceGetAccessTokensRequest extends IRequest {}

export interface IMarketingPerformanceGetAccessTokensResponse extends IResponse {
    accessTokens: TIAccessTokens;
}

export interface IMarketingPerformanceSaveAccessTokenRequest extends IRequest {
    token: string;
}

export interface IMarketingPerformanceSaveAccessTokenResponse extends IResponse {
    success: boolean;
    message?: EMarketingPerformanceMessages;
}

// Business accounts
export interface IMarketingPerformanceGetBusinessAccountsRequest extends IRequest {}

export interface IMarketingPerformanceGetBusinessAccountsResponse extends IResponse {
    businessAccounts: TIBusinessAccountItems;
}

// Tracking Tags

export interface IMarketingPerformanceGetTrackingTagsRequest extends IRequest {}

export interface IMarketingPerformanceGetTrackingTagsResponse extends IResponse {
    trackingTags: TITrackingTagItems;
}

export interface IMarketingPerformanceTrackingTagRequest extends IRequest {
    params: ICreateTrackingTagParams;
}

export interface IMarketingPerformanceTrackingTagResponse extends IResponse {
    success: boolean;
}

export interface IMarketingPerformanceUpdateTrackingTagRequest extends IRequest {
    params: IUpdateTrackingTagParams;
}

export interface IMarketingPerformanceUpdateTrackingTagResponse extends IResponse {
    success: boolean;
}

// ADS

// Ad Providers
export interface IMarketingPerformanceGetAdProvidersRequest extends IRequest {}

export interface IMarketingPerformanceGetAdProvidersResponse extends IResponse {
    data: {
        adProviders: TIAdProviders;
    }
}

// Ad Accounts
export interface IMarketingPerformanceGetAdAccountsRequest extends IRequest {}

export interface IMarketingPerformanceGetAdAccountsResponse extends IResponse {
    adAccounts: TIAdAccountItems;
}

// Ad Campaigns

export interface IMarketingPerformanceGetAdCampaignsRequest extends IRequest {
    adAccountId: string;
}
export interface IMarketingPerformanceGetAdCampaignsResponse extends IResponse {
    adCampaigns: TIAdCampaignItems;
}

// Ad Sets
export interface IMarketingPerformanceGetAdSetsRequest extends IRequest {
    adAccountId: string;
}
export interface IMarketingPerformanceGetAdSetsResponse extends IResponse {
    adSets: TIAdSetItems;
}

// Ads
export interface IMarketingPerformanceGetAdsRequest extends IRequest {
    adAccountId: string;
}
export interface IMarketingPerformanceGetAdsResponse extends IResponse {
    ads: TIAdItems;
}

export interface IMarketingPerformanceAdAccountDataRequest extends IRequest {
    adAccountId: string;
}

export interface IMarketingPerformanceAdAccountDataResponse extends IResponse {
    data: IAdObjects;
}

export interface IMarketingPerformanceSynchronizeAdsRequest extends IRequest {
    token: string;
}

export interface IMarketingPerformanceSynchronizeAdsResponse extends IResponse {}

export interface IMarketingPerformanceSetAdTrackingTagRequest extends IRequest {
    idAd: string;
    idTrackingTag: string;
}

export interface IMarketingPerformanceSetAdTrackingTagResponse extends IResponse {
    success: boolean;
}

// Ad token
export interface IMarketingPerformanceInsertTokenRequest extends IRequest {
    tokenData: IAccessToken;
}
export interface IMarketingPerformanceInsertTokenResponse extends IResponse {}

export interface IMarketingPerformanceUpdateTokenRequest extends IRequest {
    tokenData: IAccessToken;
}
export interface IMarketingPerformanceUpdateTokenResponse extends IResponse {}

export interface IMarketingPerformanceGetTokensRequest extends IRequest {
    idAdToken: string;
}
export interface IMarketingPerformanceGetTokensResponse extends IResponse {
    tokenData: IAccessToken[];
}

//Colmeia events
export interface IMarketingPerformanceGetColmeiaEventsRequest extends IRequest {}
export interface IMarketingPerformanceGetColmeiaEventsResponse extends IResponse {
    colmeiaEvents: TIColmeiaAdEventItems;
}


//Ad events
export interface IMarketingPerformanceGetEventsRequest extends IRequest {}
export interface IMarketingPerformanceGetEventsResponse extends IResponse {
    customerEvents: ICustomerAdEvent[];
}

export interface IMarketingPerformanceCreateEventsRequest extends IRequest {
    eventParams: ICreateCustomerEventParams,
}
export interface IMarketingPerformanceCreateEventsResponse extends IResponse {
    success: boolean;
}
