import { suggestions } from "../../../../tools/type-utils";
import { typedScreenOptions } from "../../../../tools/utility";
import { ESubscribedTo } from "../../../BPM/common/common-bpm";
import { EBPMTicketAgentAction } from "../../../crm/crm-services/crm-bpm-action.model";
import { ECloseState, ECRMDBType, EServiceManagementPhases, ESeverityLevel, ESupportLevel, ETicketState, EUrgencyLevel } from "../../../crm/crm-services/crm-config-canonical-model";
import { TCustomEnumPickerFieldMaps } from "../../../enum-db";
import { EAllSerializable, getTranslationsFields } from "../../all-serializables";

const crmConfigServerErrors = typedScreenOptions({
    idSerializable: EAllSerializable.CRMConfigServerErrors,
    fields: ({
        divergentRequest: 1, // Request divergente das propriedades. Cheque nsType, crmType, crmTypeLevel e requestType
        noCRMConfigMetadata: 2, // Request sem metadados
    })
});

const crmConfigEntityErrors = typedScreenOptions({
    idSerializable: EAllSerializable.CRMConfigEntityErrors,
    fields: ({
        missingIdSeverityConfig: 1, // Selecione um pacote de severidade
        missingIdSRPhasesConfig: 2, // Selecione um pacote de fases
        missingIdTicketStateConfig: 3, // Selecione um pacote de status de ticket
        missingIdCloseStateConfig: 4, // Selecione um pacote de status de fechamento
        missingIdUrgencyLevelConfig: 5, // Selecione um pacote de níveis de urgência
    })
});

const crmConfigItemEntityErrors = typedScreenOptions({
    idSerializable: EAllSerializable.CRMConfigItemEntityErrors,
    fields: ({
        closeState: 1, // Campo estado de fechamento inválido
        phase: 2, // Campo fase inválido
        state: 3, // Campo estado inválido
        severity: 4, // Campo severidade inválido
    })
});

const srPackageEntityFields = typedScreenOptions({
    idSerializable: EAllSerializable.CRMSRPackageEntityFields,
    fields: ({
        idSeverityConfig: 1, // Severidade
        idSRPhasesConfig: 2, // Fase
        idTicketStateConfig: 3, // Ticket
        idCloseStateConfig: 4, // Estado de fechamento
        supportingDB: 5, // Banco de dados suportado
        idSupportLevelConfig: 6, // Nível de suporte
        idUrgencyLevelConfig: 7, // Nível de urgência
    })
});

const crmESubscribedTo = typedScreenOptions({
    idSerializable: EAllSerializable.CRMESubscribedTo,
    fields: suggestions<TCustomEnumPickerFieldMaps<ESubscribedTo>>()({
        [ESubscribedTo.botElement]: 1, // Item de menu(Bot)
        [ESubscribedTo.campaignAction]: 2, // Ação de campanha
        [ESubscribedTo.contentGenerator]: 3, // Gerador de conteúdo
        // [ESubscribedTo.crmPhase]: 4, // Fase de CRM
        // [ESubscribedTo.formulary]: 5, // Formulário
        [ESubscribedTo.attRegistry]: 6, // Formulário
    })
});

const crmCRMDBType = typedScreenOptions({
    idSerializable: EAllSerializable.CRMDBType,
    fields: suggestions<TCustomEnumPickerFieldMaps<ECRMDBType>>()({
        [ECRMDBType.customer]: 1, // Cliente
        [ECRMDBType.product]: 2, // Produto
        [ECRMDBType.configurableItem]: 3, // Itens de Configuração (Serial)
        [ECRMDBType.issueClassification]: 4, // Classificação de Incidente
        [ECRMDBType.division]: 5, // Divisão
    })
});

const crmSeverityLevel = typedScreenOptions({
    idSerializable: EAllSerializable.CRMSeverityLevel,
    fields: suggestions<TCustomEnumPickerFieldMaps<ESeverityLevel>>()({
        [ESeverityLevel.severity1]: 1, // Severidade 1
        [ESeverityLevel.severity2]: 2, // Severidade 2
        [ESeverityLevel.severity3]: 3, // Severidade 3
        [ESeverityLevel.severity4]: 4, // Severidade 4
    })
});

const crmUrgencyLevel = typedScreenOptions({
    idSerializable: EAllSerializable.CRMUrgencyLevel,
    fields: suggestions<TCustomEnumPickerFieldMaps<EUrgencyLevel>>()({
        [EUrgencyLevel.urgent]: 1, // Urgente
        [EUrgencyLevel.normal]: 2, // Normal
    })
});

const crmSupportLevel = typedScreenOptions({
    idSerializable: EAllSerializable.CRMSupportLevel,
    fields: suggestions<TCustomEnumPickerFieldMaps<ESupportLevel>>()({
        [ESupportLevel.tier0]: 1, // Tier 0
        [ESupportLevel.tier1]: 2, // Tier 1
        [ESupportLevel.tier2]: 3, // Tier 2
        [ESupportLevel.tier3]: 4, // Tier 3
        [ESupportLevel.tier4]: 5, // Tier 4
    })
});

const crmServiceManagementPhases = typedScreenOptions({
    idSerializable: EAllSerializable.CRMServiceManagementPhases,
    fields: suggestions<TCustomEnumPickerFieldMaps<EServiceManagementPhases>>()({
        [EServiceManagementPhases.detect]: 1,       // Detecção
        [EServiceManagementPhases.record]: 2,       // Registro
        [EServiceManagementPhases.classify]: 3,     // Classificação
        [EServiceManagementPhases.prioritize]: 4,   // Priorização
        [EServiceManagementPhases.investigate]: 5,  // Investigação
        [EServiceManagementPhases.diagnose]: 6,     // Diagnóstico
        [EServiceManagementPhases.resolution]: 7,   // Resolução
        [EServiceManagementPhases.restore]: 8,      // Restauração
        [EServiceManagementPhases.closure]: 9,      // Encerramento
    })
});

const crmTicketState = typedScreenOptions({
    idSerializable: EAllSerializable.CRMTicketState,
    fields: suggestions<TCustomEnumPickerFieldMaps<ETicketState>>()({
        [ETicketState.new]: 1,                  // Novo
        [ETicketState.inProgress]: 2,           // Em progresso
        [ETicketState.OnHold]: 3,               // Em espera
        [ETicketState.Closed]: 4,               // Fechado
        [ETicketState.Canceled]: 5,             // Cancelado
        [ETicketState.reopened]: 6,             // Reaberto
        [ETicketState.waitingEscalation]: 7,    // Esperando escalação
    })
});

const crmCloseState = typedScreenOptions({
    idSerializable: EAllSerializable.CRMCloseState,
    fields: suggestions<TCustomEnumPickerFieldMaps<ECloseState>>()({
        [ECloseState.workaround]: 1,                // Solução de contorno
        [ECloseState.solved]: 2,                    // Resolvido
        [ECloseState.failed]: 3,                    // Falha
        [ECloseState.closedByCaller]: 4,            // Fechado pelo atendente
        [ECloseState.closedByCustomerTimeout]: 5,   // Fechado por inatividade do cliente
    })
});

const crmBPMAction = typedScreenOptions({
    idSerializable: EAllSerializable.CRMBPMActions,
    fields: suggestions<TCustomEnumPickerFieldMaps<EBPMTicketAgentAction>>()({
        [EBPMTicketAgentAction.Assign]: 1,                    // Atribuição
        [EBPMTicketAgentAction.CloseTicket]: 2,               // Fechar Ticket
        [EBPMTicketAgentAction.SendCampaign]: 3,              // Campanha
        [EBPMTicketAgentAction.Notification]: 4,              // Campanhas
        [EBPMTicketAgentAction.executeFunction]: 5,           // Executar função
    } as const)
});

export const allCRMConfigTranslations = ({
    ...getTranslationsFields(crmConfigServerErrors),
    ...getTranslationsFields(crmConfigEntityErrors),
    ...getTranslationsFields(crmConfigItemEntityErrors),
    ...getTranslationsFields(srPackageEntityFields),
    ...getTranslationsFields(crmESubscribedTo),
    ...getTranslationsFields(crmCRMDBType),
    ...getTranslationsFields(crmSeverityLevel),
    ...getTranslationsFields(crmUrgencyLevel),
    ...getTranslationsFields(crmSupportLevel),
    ...getTranslationsFields(crmServiceManagementPhases),
    ...getTranslationsFields(crmTicketState),
    ...getTranslationsFields(crmCloseState),
    ...getTranslationsFields(crmBPMAction),
});

