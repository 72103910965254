import { Injectable, OnDestroy } from '@angular/core';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { ISecurityScreenGroup } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { allSeverityRegistry } from '@colmeia/core/src/core-constants/tracker-qualifiers';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { IProcessedContractItem, TIdMenuContractAccess } from '@colmeia/core/src/shared-business-rules/social-network/social-network-config';
import { EDefineDashboard, EMenuDashboardAnalytics, EMenuDashboardCRM, EScreenGroups, menuDashboardConfigurations } from '@colmeia/core/src/shared-business-rules/visual-constants';
import { genericTypedSuggestions } from '@colmeia/core/src/tools/type-utils';
import { getTypedProperty, isValidRef, mapBy } from '@colmeia/core/src/tools/utility';
import { Nullish, ValueOf } from '@colmeia/core/src/tools/utility-types';
import { mapCurrentDashboardClient } from 'app/model/dashboard/db/dashboard-db-mapper';
import { IMapCurrentDashboardClient } from 'app/model/dashboard/db/dashboard-db-types';
import { routeList, TExtractChildren } from 'app/model/routes/route-constants';
import { DashBoardService, TCurrentSelectedDashboard } from 'app/services/dashboard/dashboard.service';
import { InteractionPersistorServices } from 'app/services/interaction-persistor.service';
import { SessionService } from 'app/services/session.service';
import { SignalPublisherService } from 'app/services/signal/signal-publisher';
import { chain, get } from 'lodash';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SNConfigService } from '../dashboard/social-network-config/sn-config.service';

export interface IMenuDashboardOption {
    title: string;
    description: string;
    icon: string;
    primaryId?: string;
    absolutePath?: string[];
    belongsToPath?: string;
    modulePath?: string;
    path?: string;
    hasVisibility?: boolean;
    config?: IMapCurrentDashboardClient
    contractItem?: IProcessedContractItem;
    order?: number;
}

@Injectable({
    providedIn: 'root'
})
export class MenuDashboardService implements OnDestroy {
    private checkedIfBugSentSerializable: Record<string, boolean> = {};

    public isMinimized: boolean = false;
    public emptyPath: string = '';
    public allOptions: IMenuDashboardOption[];
    private _allOptions$: ReplaySubject<IMenuDashboardOption[]> = new ReplaySubject(1);
    public get allOptions$(): Observable<IMenuDashboardOption[]> {
        return this._allOptions$.asObservable();
    }

    private _selectedDashboard: string | Nullish;
    public get selectedDashboard() {
        return this._selectedDashboard;
    }
    public set selectedDashboard(dashboardRoute: string | Nullish) {
        this._selectedDashboard = dashboardRoute;
        this._selectedDashboard$.next(dashboardRoute);
    }

    public _selectedDashboard$ = new Subject<string | Nullish>();
    public get selectedDashboard$() {
        return this._selectedDashboard$.asObservable();
    }

    private currentDashSubs: Subscription;

    constructor(
        private interactionPersistorSvc: InteractionPersistorServices,
        private session: SessionService,
        private snConfigSvc: SNConfigService,
        private dashboardSvc: DashBoardService,
        private signalPublisher: SignalPublisherService
    ) {
        this.init();

        this.signalPublisher._getSubscriptionStream().pipe(filter(sub => !!sub)).subscribe(() => {
            this.init();
        });
    }

    public async init(): Promise<void> {
        this.loadAllMenuDashboardOptions();
        this.defineItemsRoutes();

        this.initCurrentSelectedDashboard();

        await this.setAllOptionsAccess();

        this._allOptions$.next(this.allOptions);
    }

    ngOnDestroy() {
        this.currentDashSubs.unsubscribe();
    }

    private initCurrentSelectedDashboard() {
        this.selectedDashboard = this.getMostParentModule(this.dashboardSvc.currentDashboard);

        this.currentDashSubs = this.dashboardSvc.currentDashboard$.subscribe(dashboardRoute => {
            this.selectedDashboard = this.getMostParentModule(dashboardRoute);
            this.checkModuleChange(dashboardRoute);
        });
    }

    private getMostParentModule(moduleRoute: TCurrentSelectedDashboard): string | Nullish {
        const module = this.allOptions.find(option => option.modulePath === moduleRoute);

        if (module) {
            let parentModule = this.allOptions.find(option => option.path === module.belongsToPath);

            if (parentModule?.path) {
                return parentModule.path;
            }
        }

        return moduleRoute;
    }

    private loadAllMenuDashboardOptions() {
        const pathMap: Record<string, string> = {
            [menuDashboardConfigurations.serializableId]: 'configurations',
            [EMenuDashboardCRM.serializableId]: 'crm',
            [EMenuDashboardAnalytics.serializableId]: 'analytics',
        };

        this.allOptions = Serializable
            .getVisualElementFromScreenGroup(EScreenGroups.MenuDashboard, false)
            .map((item: Serializable): IMenuDashboardOption => {
                const screenGroup: string = item
                    .getMyScreenGroups()
                    .map((securityScreenGroup: ISecurityScreenGroup) => securityScreenGroup.screenGroup)
                    .find(screenGroup => {
                        return screenGroup.startsWith(EScreenGroups.MenuDashboard);
                    });

                const [, belongsToPath = this.emptyPath] = screenGroup.split('/');
                const icon: string = item.getSerializableText(36);
                const path: string = pathMap[item.getPrimaryID()]

                return {
                    primaryId: item.getPrimaryID(),
                    title: item.getSerializableText(35),
                    description: item.getDescription(),
                    icon,
                    belongsToPath,
                    path,
                    order: item.getOrder()
                }
            }).sort((a, b) => {
                if (isValidRef(a.order) && isValidRef(b.order)) {
                    return b.order - a.order;
                }

                return a.path ? (a.path === b.belongsToPath ? - 1 : 1) : a.belongsToPath.localeCompare(b.belongsToPath)
            });
    }

    private defineItemsRoutes() {
        const mappedOptions = chain(this.allOptions).mapKeys((option: IMenuDashboardOption) => option.primaryId).value();
        const createRoute = this.createRoute(routeList);

        const routes = genericTypedSuggestions<{ [key in ValueOf<typeof mapCurrentDashboardClient>['primaryID']]: string[] }>()({
            [mapCurrentDashboardClient[EDefineDashboard.socialNetwork].primaryID]: createRoute($ => $.dashboard.menuSN.groupContainer),
            [mapCurrentDashboardClient[EDefineDashboard.attendance].primaryID]: createRoute($ => $.dashboard.serviceStatus.current),
            [mapCurrentDashboardClient[EDefineDashboard.omnisense].primaryID]: createRoute($ => $.dashboard.ai.knowledgeBase),
            [mapCurrentDashboardClient[EDefineDashboard.communication].primaryID]: createRoute($ => $.dashboard.communication.socialMedias),
            [mapCurrentDashboardClient[EDefineDashboard.services].primaryID]: createRoute($ => $.dashboard.service.deployedServices),
            [mapCurrentDashboardClient[EDefineDashboard.marketing].primaryID]: createRoute($ => $.dashboard.marketing.campaigns.list),
            [mapCurrentDashboardClient[EDefineDashboard.jobs].primaryID]: createRoute($ => $.dashboard.jobs.environmentDataMigration),
            [mapCurrentDashboardClient[EDefineDashboard.CRMServices].primaryID]: createRoute($ => $.dashboard.crmServices.phases),
            [mapCurrentDashboardClient[EDefineDashboard.environments].primaryID]: createRoute($ => $.dashboard.environments.patches.list),
            [mapCurrentDashboardClient[EDefineDashboard.analytics].primaryID]: createRoute($ => $.dashboard.analytics.attendance),
            [mapCurrentDashboardClient[EDefineDashboard.analyticsHistory].primaryID]: createRoute($ => $.dashboard.analyticsHistory.attendance),
            [mapCurrentDashboardClient[EDefineDashboard.smartFlow].primaryID]: createRoute($ => $.dashboard.smartFlow.rtrParameterizable),
            [mapCurrentDashboardClient[EDefineDashboard.colmeia].primaryID]: createRoute($ => $.dashboard.colmeia),
            [mapCurrentDashboardClient[EDefineDashboard.conversationalCommerce].primaryID]: createRoute($ => $.dashboard.conversationalCommerce.catalog),
            [mapCurrentDashboardClient[EDefineDashboard.security].primaryID]: createRoute($ => $.dashboard.security.tasks),
            [mapCurrentDashboardClient[EDefineDashboard.tools].primaryID]: createRoute($ => $.dashboard.tools.dataExtractor),
            [mapCurrentDashboardClient[EDefineDashboard.marketingPerformance].primaryID]: createRoute($ => $.dashboard.marketingPerformance.ads),
        } as const);

        Object.keys(routes).filter((primaryID: keyof typeof routes) => {
            const hasMappedConfig = isValidRef(mappedOptions[primaryID]);
            const hasChecked = this.checkedIfBugSentSerializable[primaryID];

            if (!hasChecked && !UberCache.testCache(primaryID)) {
                this.interactionPersistorSvc.sendTrace(allSeverityRegistry.critical, "setAllOptionsRoutes", {
                    primaryID,
                    message: "Serializable not exists"
                });
                this.checkedIfBugSentSerializable[primaryID] = true;
            }

            return hasMappedConfig;
        }).forEach((primaryID: keyof typeof routes) => {
            mappedOptions[primaryID].absolutePath = routes[primaryID];
            mappedOptions[primaryID].modulePath = routes[primaryID][1];
        });

        this.allOptions = Object.values(mappedOptions);
    }

    private createRoute(from) {
        return (getPath: ($proxy: TExtractChildren<typeof routeList>) => string | object): string[] => {
            const $ = getTypedProperty<TExtractChildren<typeof routeList>>();
            const path: string = String(getPath($));

            return path
                .split('.')
                .map((key, index, keys) => keys.slice(0, index + 1))
                .map((fathers, index, keys) => {
                    const beforeLast = fathers.slice(0, -1);
                    const last = fathers.slice(-1)[0];

                    let path = beforeLast
                        .map(item => `${item}.children`)
                        .concat(last)
                        .join('.');

                    let got = get(from, path);

                    if (typeof got !== 'string') path = path.concat('.path');

                    got = get(from, path);

                    return got;
                });
        }
    }

    async setAllOptionsAccess() {
        await this.session.getAvatarAsync();
        if (this.snConfigSvc.shouldLoadAccess) {
            await this.snConfigSvc.loadAccess();
        }

        this.allOptions.forEach(item => {
            const config = mapCurrentDashboardClient[item.primaryId] as IMapCurrentDashboardClient;
            if (!config) {
                item.hasVisibility = true;
                return item;
            }
            const contractItem: IProcessedContractItem | undefined = this.snConfigSvc.getProcessedInfo(config.screenGroup.name as TIdMenuContractAccess)

            item.config = config;

            if (!contractItem) return item;

            item.contractItem = contractItem;
            if (item.primaryId === 'marketing-performance') {
                console.log({
                    primaryId: item.primaryId,
                    hasAccess: contractItem.hasAccess,
                    isAdmin: this.session.isAdminOnSN(),
                    hasAccessOnConfig: contractItem.hasAccessOnConfig,
                    hasSpecialRule: contractItem.hasSpecialRule,
                });
            }
            item.hasVisibility = contractItem.hasAccess || (this.session.isAdminOnSN() && !contractItem.hasAccessOnConfig && !contractItem.hasSpecialRule);

            return item;
        })

        const mapOptions: Map<string, IMenuDashboardOption[]> = mapBy(this.allOptions, item => item.belongsToPath, {
            isToMany: true,
        });

        mapOptions
            .get(this.emptyPath)
            .filter(item => !item.config)
            .forEach(item => {
                const elements = mapOptions.get(item.path) ?? [];
                item.hasVisibility = elements.some(item => item.hasVisibility);
            });
    }

    public getSubOptionsFrom(option: IMenuDashboardOption): IMenuDashboardOption[] {
        return this.allOptions.filter(opt => opt.belongsToPath === option.path);
    }

    private checkModuleChange(module: string | Nullish): void {
        if (!isValidRef(module)) {
            this.selectedDashboard = null;
        }
    }
}
