import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { IServiceIslandServer } from '@colmeia/core/src/shared-business-rules/attendance-island/attendance-island';
import { ECRMCanonicalItemsTypes } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config-canonical-model';
import { isValidNumber, isValidRef } from '@colmeia/core/src/tools/barrel-tools';
import { MessageInstance } from 'app/components/chat/message-container/message-instance/message-instance.component';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';
import { TTicketInteractionClient } from 'app/crm-service-tickets-view/tickets-view-card-dialog/ticket-interaction/ticket-interaction.component';
import { SubscriptionGroup } from 'app/model/client-utility';
import { AttendanceService } from 'app/services/attendance.service';
import { filter } from 'rxjs/operators';
import { ETicketInteractionType, ICRMTicketDataWithCustomerName } from '../../../../../core/src/shared-business-rules/crm/crm-entities';
import { CRMTicketPersistenceService } from '../crm-ticket-persistence.service';
import { CRMTicketRouterService } from '../crm-ticket-router.service';
import { TICKET_DATA } from '../crm-tickets-dialog.service';
import { CRMTicketsService } from '../crm-tickets.service';
import { UserSettingsService } from 'app/services/user-settings.service';
import { DatetimeConversions } from 'app/services/datetime-conversions';


@Component({
    selector: 'tickets-view-card-dialog',
    templateUrl: './tickets-view-card-dialog.component.html',
    styleUrls: ['./tickets-view-card-dialog.component.scss'],
    providers: [
        CRMTicketPersistenceService
    ]
})
export class TicketsViewCardDialogComponent implements OnInit, OnDestroy {
    public ticketInteractions: TTicketInteractionClient[];
    public ticketInteractionsIterator: TTicketInteractionClient[];
    public island: IServiceIslandServer;
    public loading: boolean = true;
    public isWindow: boolean = false;
    public protocol: string = '';
    private subs = new SubscriptionGroup();
    public ticketFullRoute: string;
    ECRMCanonicalItemsTypes = ECRMCanonicalItemsTypes;

    public selectedInteractionType?: ETicketInteractionType[] = [];

    public interactionsTypes: Record<ETicketInteractionType, string> = {
        [ETicketInteractionType.content]: 'Conteúdo',
        [ETicketInteractionType.status]: 'Status',
        [ETicketInteractionType.allocation]: 'Alocação',
        [ETicketInteractionType.formAnswer]: 'Formulário',
        [ETicketInteractionType.queue]: 'Fila',
    }
    private dateTimeConversor: DatetimeConversions;

    constructor(
        private ticketRouterSvc: CRMTicketRouterService,
        private attendanceSvc: AttendanceService,
        private crmTicketSvc: CRMTicketsService,
        @Inject(TICKET_DATA)
        public ticketData: ICRMTicketDataWithCustomerName,
        @Optional()
        private windowRef: ColmeiaWindowRef<TicketsViewCardDialogComponent>,
        private userSettingsSvc: UserSettingsService,
    ) {
        this.dateTimeConversor = this.userSettingsSvc.getDatetimeConversor();
        if (isValidRef(this.windowRef)) {
            this.isWindow = true;
        }
    }

    async ngOnInit() {
        const _url = new URL(document.URL);
        _url.pathname = this.ticketRouterSvc.getTicketFullPath(this.ticketData);

        this.ticketFullRoute = _url.toString();

        const pkg = await this.crmTicketSvc.getProject(this.ticketData.idPackage);

        this.island = this.attendanceSvc.getIsland(this.ticketData.idIsland);
        this.protocol = `${pkg?.projectKey}-${this.ticketData?.protocol}`;
        this.loadInfos();

        this.subs.from(this.crmTicketSvc.$ticketUpdate)
            .pipe(filter(t => t.ticketData.idTicket === this.ticketData.idTicket))
            .subscribe(({ ticketData }) => {
                this.loadInfos();
            });
    }

    ngOnDestroy(): void {
        this.subs.destroy();
    }
    
    getDate(value: number): string {
        return isValidNumber(value) ? this.dateTimeConversor.timeToDateTimeString(value) : "--";
    }

    getUpdatedAt() {
        return this.getDate(this.ticketData.lastClockTick);
    }

    getCreatedAt() {
        return this.getDate(this.ticketData.clockTick);
    }
    
    setInteractionFilterType(interactionType: ETicketInteractionType = undefined, event?: MouseEvent) {
        if(!interactionType) {
            this.selectedInteractionType = [];
            return;    
        }
    
        const idx = this.selectedInteractionType.indexOf(interactionType);

        if(event?.ctrlKey) {
            if(idx === -1) {
                this.selectedInteractionType.push(interactionType); 
            } else {
                this.selectedInteractionType.splice(idx, 1);
            }
        } else {
            this.selectedInteractionType = [interactionType];
        }
    }

    private async loadInfos() {
        this.loading = true;
        await this.loadAllTicketInteractions();
        this.loading = false;
    }

    private async loadAllTicketInteractions(): Promise<void> {
        const interactions = await this.crmTicketSvc.getAllTicketInteractions(this.ticketData.idTicket);

        this.ticketInteractions = this.crmTicketSvc.parseTicketInteractionsForClient(interactions);
    }

    public getDisplayForInteraction(interaction: TTicketInteractionClient): boolean {
        return this.selectedInteractionType.length ? this.getDisplayForInteractionType(interaction.interactionType) : true;
    }

    public getDisplayForInteractionType(interactionType: ETicketInteractionType): boolean {
        return this.selectedInteractionType.includes(interactionType);
    }

    getIconUrl() {
        const deliveryIcon = MessageInstance.getDeliveryIcon(this.ticketData.channel);

        if (deliveryIcon) {
            return deliveryIcon;
        }
    }

    onAddComment() {
        this.loadInfos();
        this.updateLastUpdate();
    }

    // atualiza somente no client
    private updateLastUpdate() {
        const clockTick = new Date().getTime();

        // this.card.setLastUpdate(clockTick);
    }

}
