import { TGlobalUID } from '../../business/constant';
import { EDelivery360Action } from "../../comm-interfaces/interaction-interfaces";
import { EConfirmationType } from "../../core-constants/types";
import { Days, daysToHours } from '../../time/time-utl';
import { typedScreenOptions } from '../../tools/utility';
import { IBPMMarketingRootServer } from '../BPM/marketing/bpm-marketing.model';
import { IContentBasicAsset, IContentEmailHTMLTemplate, TContentAssetArray } from '../bot/bot-content-model';
import { IRouteCallback } from '../colmeia-apis/colmeia-callback';
import { EAllSerializable, getTranslationsFields } from '../const-text/all-serializables';
import { IConditionalValuatorContainer } from '../metadata/meta-engagement';
import {
    ENonSerializableObjectType,
    INonSerializable,
    INonSerializableHeader,
    INonSerializableMedia,
    IdSchema
} from '../non-serializable-id/non-serializable-id-interfaces';
import { IdDep, IdNS } from '../non-serializable-id/non-serializable-types';
import { ECampaignActionType, TICampaignActionCommStrategy } from './campaing-comm-strategy';
import { TICampaingVariableConfigArray } from './campaing-interfaces';
import { ICampaignExecutionHeader } from './capaing-exec-interfaces';

export type TCampaignArray = Array<ICampaign>;

export interface ICampaign extends INonSerializableHeader {
    campaingActions: TCampaigActions;
};
export interface ICampaignWithIdNS extends ICampaign {
    idNS?: string;
};


/// ACTION CAMPAING

export type TCampaigActions = Array<ICampaingActionHeader>;
export enum EEngagementMethodControl {
    hourAbsence = 'absence', tries = 'tries'
}

export interface IMarketingEngagmentControl {
    engagedIf: EConfirmationType.read | EConfirmationType.respond; // Considerar como sucesso:
    controlType: EEngagementMethodControl;
    tries: number;
    hourAbsent: number;
    ignoreFailuresAfterDays: number;  // Ignorar campanhas anteriores há:
}

export interface ICampaingActionHeader extends INonSerializableMedia, IRouteCallback {
    idCampaigAction: string;
    actionType: ECampaignActionType;
    actionName: string;
    actionDescription?: string;
    idSchemma: IdSchema;
    allowActionToActiveCall?: boolean; // Habilitar Ação para contato do Agente
    allowActiveCallRTFSelect?: boolean; // Permitir seleção de RTF Parametrizável
    allowActionTo2FA?: boolean; // Habilitar ação para two phase authentication
    sendOnlyIfChannelIsOpen?: boolean; // Enviar mensagem apenas se o Canal estiver aberto
    allowActionToChannelReopen?: boolean; // Habilitar ação para reabertura de canal
    dontSendMessageIfTemplateSent?: boolean;
    sendTemplateAnyway?: boolean;
    emailHTMLTemplate?: IContentEmailHTMLTemplate;
    messages: IContentBasicAsset[];
    expireActiveHours?: number; // Tempo de expiração para tratamento de respota
    idBot?: string;
    useEngagementControl?: boolean;
    engagementControl?: IMarketingEngagmentControl;
    bpmMKTDefaultRuler?: IdNS<IBPMMarketingRootServer>
    isHidden?: boolean;
}

export type TCMassCommActionArray = Array<ICMassCommunicationAction>;

export const allMinimumConfirmation: EConfirmationType[] = [
    EConfirmationType.receive,
    EConfirmationType.read,
    EConfirmationType.respond
];

export const confirmationTypeTransSerID: EAllSerializable.ConfirmationTypeTranslations = EAllSerializable.ConfirmationTypeTranslations;
export const confirmationTypeTransFieldMap: { [key in EConfirmationType]: number } = {
    [EConfirmationType.channelClosed]: 1, // Canal fechado
    [EConfirmationType.colmeiaSent]: 2, // Enviado pela colmeia
    [EConfirmationType.viewMedia]: 3, // Visualizou midia
    [EConfirmationType.span]: 4, // Span
    [EConfirmationType.read]: 5, // Lido
    [EConfirmationType.respond]: 6, // Respondido
    [EConfirmationType.failed]: 7, // Falhou
    [EConfirmationType.sent]: 8, // Enviado
    [EConfirmationType.receive]: 9, // Recebido,
    [EConfirmationType.invalidNumber]: 10, // Número inválido,
    [EConfirmationType.bounce]: 11,  // Nova tentativa
    [EConfirmationType.clicked]: 12,  // Link clicado,
    [EConfirmationType.ignoreByFilter]: 13,  // Ignorado por um filtro
    [EConfirmationType.ignoredByActiveConversation]: 14,  // Uma conversacao esta em andamento
    [EConfirmationType.notRead]: 15,  // Não lido
    [EConfirmationType.notAnswered]: 16, // Não respondido
    [EConfirmationType.queued]: 17, // Acrescentado à fila de envio
    [EConfirmationType.noProcessFromColmeia]: 18, // Ignorado pela colmeia
    [EConfirmationType.brokerOptOut]: 19, // Opt out
    [EConfirmationType.invalidSession]: 20, // Sessão inválida
    [EConfirmationType.ignored]: 21, // Sessão inválida
    [EConfirmationType.error]: 22, // Sessão inválida
};
export const confirmationTypeTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: confirmationTypeTransSerID,
    fields: confirmationTypeTransFieldMap
}));

export const channelPossibleConfirmationsDB: { [key in EDelivery360Action]: EConfirmationType[] } = {

    [EDelivery360Action.DeliveryTelegram]: [
        EConfirmationType.receive,
        EConfirmationType.read,
        EConfirmationType.respond,
    ],

    [EDelivery360Action.Delivery360Email]: [
        EConfirmationType.receive,
        EConfirmationType.read,
        EConfirmationType.respond
    ],

    [EDelivery360Action.DeliveryInstagram]: [
        EConfirmationType.receive,
        EConfirmationType.read,
        EConfirmationType.respond
    ],
    [EDelivery360Action.Delivery360FBMessenger]: [
        EConfirmationType.receive,
        EConfirmationType.read,
        EConfirmationType.respond
    ],
    [EDelivery360Action.Delivery360SMS]: [
        EConfirmationType.receive,
        EConfirmationType.respond
    ],
    [EDelivery360Action.Delivery360RCS]: [
        EConfirmationType.receive,
        EConfirmationType.read,
        EConfirmationType.respond
    ],
    [EDelivery360Action.Delivery360Voice]: [
        EConfirmationType.receive,
    ],
    [EDelivery360Action.Delivery360WhatsApp]: [
        EConfirmationType.receive,
        EConfirmationType.read,
        EConfirmationType.respond
    ],
    [EDelivery360Action.DeliveryColmeia]: [
        EConfirmationType.receive,
        EConfirmationType.read,
        EConfirmationType.respond
    ]
};

export function channelMatchesWithMinimumConfirmation(channel: EDelivery360Action, confirmation: EConfirmationType): boolean {
    return channelPossibleConfirmationsDB[channel].includes(confirmation);
}

export interface ICMassCommunicationAction extends ICampaingActionHeader {
    commStrategy: TICampaignActionCommStrategy;
    flood?: true; //ignore read/receive/respond confirmation
    minimumConfirmation?: EConfirmationType; // ignored if flood is enabled
    considerClosedConversationAfterDays: number; // Considere a conversação encerrada após:
    retries?: number; // Numero de tentativas
    constants?: TICampaingVariableConfigArray;
    idScheduleDefault?: IdDep<ENonSerializableObjectType.attendanceCalendar>;
    /**
     * A data de disparo(quando a ação tem um calendário configurado)
     * é definida se o horário da tentativa de disparo está entre os intervalos do calendário.
     * Caso essa configuração exista, ela é adicionada ao horário da tentativa de disparo
     * Exemplo: 
     * Temos um calendário com a seguinte configuração: Segunda 11h - 17h,
     * o horário da tentativa de disparo é Segunda 10h, e after = 2,
     * logo o horário de disparo vira Quarta 10h(horário da tentiva mais dias adicionais),
     * não ficaria no intervalo do calendário e o disparo seria agendado para a próxima semana Seg 11h
     */
    after?: Days; // Dias adicionados ao horário de disparo
    onOngoingConversation: ICampaignActionOngoingConversation,
}

export interface ICampaignActionOngoingConversation {
    reschedule: number;
    /**
     * @deprecated
     */
    cancel: boolean;
    action?: EOnOngoingConversation;
    actionCond?: IConditionalValuatorContainer;
    customerAssets?: TContentAssetArray;
    agentAssets?: TContentAssetArray;
}

export enum EOnOngoingConversation {
    reschedule = 'reschdl',
    cancel = 'cncl',
    finishBot = 'fnshBot',
    finishAny = 'fnshAny',
    sendMessage = 'sendMssge',
}

export interface ICMassInviteAction extends ICMassCommunicationAction {
    idInviteGroup: TGlobalUID;
};

export interface IPersonalizedBotCAction extends ICampaingActionHeader {
    // idBot: TGlobalUID;
};


export interface ICActiveCallCenterAction extends ICampaingActionHeader {
    idServicePack: string;
}

export type TICampaignServerArray = Array<ICampaignServer>;

export interface ICampaignServer extends ICampaign, INonSerializable {

};


export interface IBasicCampaingAction {
    campaign: ICampaignServer;
    action: ICampaingActionHeader;
}

export interface ICampaingAction extends IBasicCampaingAction {
    header: ICampaignExecutionHeader;
}

export const actionDefaultExpireActiveHours = daysToHours(30);
