<div class="container" (click)="openTicket($event)">

    <div class="l-row l-header">
        <div class="title-section">
            <mat-icon class="mat-18" style="flex-shrink:0;">confirmation_number</mat-icon>
            <h4 class="l-title">{{ ticketData.title }}</h4>
            <ticket-more-options (click)="$event.stopPropagation()" [ticketData]="ticketData"></ticket-more-options>
        </div>
    </div>
    <div class="l-row" style="margin-bottom: 8px;">
        <app-crm-ticket-protocol-view (click)="$event.stopPropagation()" size="small" [ticketData]="ticketData"></app-crm-ticket-protocol-view>
        <p class="l-column" style="margin:0;"><mat-icon class="mat-18">person</mat-icon>{{ ticketData.customerName }}</p>
    </div>
</div>