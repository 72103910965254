import { Injectable, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { CustomErrorField } from '@colmeia/core/src/error-control/barrel-error';
import { FriendlyMessage } from '@colmeia/core/src/error-control/friendly-message';
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { TColmeiaPallete } from "@colmeia/core/src/shared-business-rules/brand-theme/theme-types";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { INotificationPromptReadConfirmation } from '@colmeia/core/src/shared-business-rules/new-notifications/new-notification-model';
import type { GenericSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service';
import { ITranslationConfig } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { AcceptPromise, TFunction } from '@colmeia/core/src/tools/utility-types';
import { throwErrorMessage } from '@colmeia/core/src/tools/utility/functions/error.functions';
import { InteractivePromptComponent } from 'app/components/dialogs/interactive-prompt/interactive-prompt.component';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ColmeiaDialogComponent } from "../components/dialogs/dialog/dialog.component";
import { GlobalWarningComponent } from "../components/dialogs/global-warning/global-warning.component";
import { IMessageDialogDescriptorClient } from "../handlers/descriptor-handler";
import { EMsgDialogType } from "../model/misc.model";
import { IMsgDialogDescriptor } from "../model/waring-message-interfaces";
import { ColmeiaDialogService } from "./dialog/dialog.service";



export enum EInteractiveButton {
    Yes = 'ibtn.yes',
    No = 'ibtn.no',
    Cancel = 'ibtn.cancel',
    SeeDependencies = 'ibtn.checkDep',
    Remove = 'ibtn.remove',
    Ok = 'ibtn.ok',
}

type allInteractiveButtonTranslationsConfig = { [key in EInteractiveButton]: ITranslationConfig };

export interface IInteractiveButtonMiddlewareClick {
    stop: boolean;
}

export interface IInteractiveButton {
    option: EInteractiveButton | string;
    text?: string,
    color?: TColmeiaPallete;
    style?: "stroked" | "flat",
    matIcon?: string,
    tooltip?: string,
    enableQuestion?: INotificationPromptReadConfirmation,
    /**
     * 
     * @returns flag to stop the flow
     */
    middlewareClick?: () => AcceptPromise<IInteractiveButtonMiddlewareClick>
}

export type TInteractiveMatIconConfig = {
    icon: string,
    color: string
}

export interface IInteractivePromptHandler {
    title: string,
    message: string,
    options: IInteractiveButton[],
    disableClose: boolean
    matIcon?: TInteractiveMatIconConfig,
    readConfirmation?: INotificationPromptReadConfirmation
}

export interface IGlobalWarningAskMessgeParams {
    title: string | ITranslationConfig;
    message: string;
    yes?: TFunction;
    no?: TFunction;
    matIcon?: TInteractiveMatIconConfig;
    interactiveButtons?: IInteractiveButton[];
    disableClose?: boolean;
}

export interface IGlobalWarningShowPromptMessageParams {
    title: string | ITranslationConfig;
    message: string | ITranslationConfig;
    options: IInteractiveButton[];
    disableClose?: boolean;
    matIcon?: TInteractiveMatIconConfig;
}

export const allInteractiveButtonTranslations: allInteractiveButtonTranslationsConfig = {
    [EInteractiveButton.Yes]: gTranslations.common.yes,
    [EInteractiveButton.No]: gTranslations.common.no,
    [EInteractiveButton.Cancel]: gTranslations.common.cancel,
    [EInteractiveButton.SeeDependencies]: gTranslations.fragments.seeDependencies,
    [EInteractiveButton.Remove]: gTranslations.common.remove,
    [EInteractiveButton.Ok]: gTranslations.common.ok
};


export const INTERACTIVE_YES: IInteractiveButton = {
    option: EInteractiveButton.Yes,
    color: 'accent'
};

export const INTERACTIVE_YES_WARN: IInteractiveButton = {
    option: EInteractiveButton.Yes,
    color: 'warn'
};

export const INTERACTIVE_NO: IInteractiveButton = {
    option: EInteractiveButton.No,
    style: "stroked"
};


export const INTERACTIVE_CANCEL: IInteractiveButton = {
    option: EInteractiveButton.Cancel,
};

export const INTERACTIVE_SEE_DEP: IInteractiveButton = {
    option: EInteractiveButton.SeeDependencies,
    color: 'accent'
};

export const INTERACTIVE_REMOVE: IInteractiveButton = {
    option: EInteractiveButton.Remove,
    color: 'warn',
    matIcon: 'delete'
};

export const INTERACTIVE_OK: IInteractiveButton = {
    option: EInteractiveButton.Ok,
    color: 'accent',
};

@Injectable({
    providedIn: 'root'
})
export class GlobalWarningService implements IMessageDialogDescriptorClient {
    private defaultWarningData: IMsgDialogDescriptor = {
        type: EMsgDialogType.Warning,
        messages: null,
        icon: 'warning',
        title: null,
        clientCallback: this,
    };
    private msgDesc: Subject<IMsgDialogDescriptor> = new Subject<IMsgDialogDescriptor>();
    private currentResolver: (value?: Serializable | PromiseLike<Serializable>) => void;
    private dialogRef?: MatDialogRef<ColmeiaDialogComponent<{}>, any>;

    constructor(
        private ngZone: NgZone,
        private colmeiaDialogSvc: ColmeiaDialogService
    ) { }

    private async getClickResult(): Promise<Serializable> {
        return new Promise<Serializable>((res) => this.currentResolver = res);
    }

    /**
     * Called when client clicks on some option inside warning window
     * @param  {Serializable} itemClicked
     * @returns void
     */
    onOptionClickedCallback(itemClicked: Serializable): void {
        this.dialogRef.close();
        this.currentResolver(itemClicked);
    }

    public throw(input: GenericSharedService.ThrowInput) {
        try { throwErrorMessage(input) } catch (error) {
            if (!(error instanceof CustomErrorField)) throw error;
            const idError = error.getSerializableID();
            const friendly = new FriendlyMessage(error.getFunctionName(), true);
            friendly.isWarning = error.isWarning;
            friendly.addCustomMessages(idError, error.getAdditionalMessages(), error.title);
            friendly.setBusinessError(!error.isError());
            this.showError(friendly);
            throw friendly;
        }
    }

    /**
     * Modal component calls this function to listen messages
     * @returns Observable
     */
    public getMessageListener(): Observable<IMsgDialogDescriptor> {
        return this.msgDesc.asObservable();
    }

    public async showError(message: FriendlyMessage | string, serverResponse: IResponse = null): Promise<void> {
        try {
            const messages = typeof message === 'string'
                ? [message]
                : message.getFriendlyArray().length
                    ? message.getFriendlyArray().map(msg => ({ idField: 1, serializableId: msg.errorID }))
                    : message.getReturnMessages().map(msg => ({ idField: msg.idField, serializableId: msg.idSerializable }));

            return this.show({
                type: ((typeof message === 'object') && message.isWarning) ? EMsgDialogType.Warning : EMsgDialogType.Error,
                messages: typeof message === 'string'
                    ? [message]
                    : messages,
                clientCallback: null,
                friendlyMessage: message instanceof FriendlyMessage ? message : undefined,
                serverResponse
            });

        } catch (err) {
            const x = 1;
        }
    }

    public showWarning(message: FriendlyMessage | string, serverResponse: IResponse = null): Promise<void> {
        const title = gTranslations.warning.opsTitle;
        const messages = typeof message === 'string'
            ? [message]
            : message.getFriendlyArray().length
                ? message.getFriendlyArray().map(msg => ({ idField: 1, serializableId: msg.errorID }))
                : message.getReturnMessages().map(msg => ({ idField: msg.idField, serializableId: msg.idSerializable }));

        return this.show({
            type: EMsgDialogType.Warning,
            messages,
            icon: 'warning',
            title: (Serializable.staticFactory(title.serializableId)).getSerializableText(title.idField),
            clientCallback: null,
            friendlyMessage: message instanceof FriendlyMessage ? message : undefined,
            serverResponse
        });
    }

    async promptYesOrNo(title: string, message: string, matIcon: TInteractiveMatIconConfig = undefined): Promise<boolean> {
        const answer = await this.showInteractivePrompt({
            title, message, options: [
                INTERACTIVE_NO,
                INTERACTIVE_YES
            ], matIcon
        });
        return EInteractiveButton.Yes === answer;
    }


    async showInteractivePrompt<T extends string = EInteractiveButton>({
        title,
        message,
        options,
        disableClose,
        matIcon
    }: IGlobalWarningShowPromptMessageParams): Promise<T> {

        if (typeof title === 'object') {
            title = Serializable.getTranslation(title);
        }

        if (typeof message === 'object') {
            message = Serializable.getTranslation(message);
        }

        return new Promise<T>((resolve, reject) => {
            const newDialogRef = this.colmeiaDialogSvc.open<InteractivePromptComponent, IInteractivePromptHandler>({
                componentRef: InteractivePromptComponent,
                hideHeader: true,
                hideClose: disableClose,
                panelClass: "global-warning-dialog",
                dataToComponent: {
                    backdropClass: "global-warning-dialog-backdrop",
                    data: <IInteractivePromptHandler>{
                        title: title,
                        message: message,
                        options: options,
                        disableClose: disableClose,
                        matIcon
                    }
                }
            });

            newDialogRef.afterClosed().subscribe((val: T) => {
                resolve(val as T);
            })
        })
    }


    async confirmLeaveWithoutSave(): Promise<boolean> {
        const interactiveButtons: IInteractiveButton[] = [
            INTERACTIVE_YES,
            INTERACTIVE_NO,
        ];
        let message: string = Serializable.getTranslation(gTranslations.fragments.confirmLeaveWithoutSave)
        const confirmPromisse = await this.showInteractivePrompt({
            title: gTranslations.fragments.askExitWithoutSave, message, options: interactiveButtons, disableClose: false, matIcon: {
                icon: "warning_amber",
                color: "warn"
            }
        });

        return confirmPromisse === EInteractiveButton.Yes;
    }

    async askSureNSerRemoval<T>(
        nserName: string,
        fn?: () => Promise<T>,
        options: {
            customTitle?: string;
            customMessage?: string;
            removeConfirmationConfig?: INotificationPromptReadConfirmation
        } = {}): Promise<boolean> {
        const removeBtn: IInteractiveButton = { ...INTERACTIVE_REMOVE }

        if (isValidRef(options.removeConfirmationConfig)) {
            removeBtn.enableQuestion = options.removeConfirmationConfig;
        }

        const response: EInteractiveButton = await this.showInteractivePrompt({
            title: options.customTitle ?? `${Serializable.getTranslation(gTranslations.common.remove)} "${nserName}"?`,
            message: options?.customMessage ?? Serializable.getTranslation(gTranslations.fragments.sureOfDeleteObject),
            options: [INTERACTIVE_NO, removeBtn],
            disableClose: undefined,
            matIcon: {
                icon: "report",
                color: "warn"
            }
        });

        const shouldRemove: boolean = response === EInteractiveButton.Remove;

        if (shouldRemove) await fn?.();

        return shouldRemove;
    }

    public async askMessage({
        title,
        message,
        yes,
        no,
        matIcon,
        interactiveButtons = [
            INTERACTIVE_NO,
            INTERACTIVE_YES,
        ],
        disableClose = false
    }: IGlobalWarningAskMessgeParams): Promise<boolean> {

        const confirmPromisse = await this.showInteractivePrompt({ title, message, options: interactiveButtons, disableClose, matIcon })
        if (confirmPromisse === EInteractiveButton.Yes) {
            yes?.();
            return true;
        } else {
            no?.();
        }

        return false;
    }

    /**
     * Show dialog with custom configs
     * @param {IMsgDialogDescriptor} msgDesc [description]
     */
    public show(msgDesc: IMsgDialogDescriptor): Promise<void> {
        return this.openDialog(msgDesc);
    }

    openDialog(msgDesc: IMsgDialogDescriptor): Promise<void> {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => {
                if (this.dialogRef) {
                    return
                }
                const newDialogRef = this.colmeiaDialogSvc.open<GlobalWarningComponent, IMsgDialogDescriptor>({
                    componentRef: GlobalWarningComponent,
                    hideHeader: true,
                    panelClass: "global-warning-dialog",
                    dataToComponent: {
                        backdropClass: "global-warning-dialog-backdrop",
                        data: msgDesc,
                    }
                });
                this.dialogRef = newDialogRef;
                this.dialogRef.afterClosed().pipe(first()).subscribe(() => {
                    this.dialogRef = undefined;
                    resolve();
                });
                this.dialogRef.afterOpened().pipe(first()).subscribe(() => this.dialogRef = newDialogRef);
            })
        });


    }

    async showDeleteDependenciesConfirmation(nserName: string): Promise<EInteractiveButton> {
        const translation = Serializable.getTranslation(gTranslations.errors.hasReferencesContinueOrSee);
        const response = await this.showInteractivePrompt({
            title: nserName, message: translation, options: [
                INTERACTIVE_NO,
                INTERACTIVE_SEE_DEP,
                INTERACTIVE_YES,
            ], disableClose: true
        });
        return response;
    }

    /**
     * Hides modal window
     */
    public hide(): void {
        this.show({
            ...this.defaultWarningData
        })
    }

    closeAll() {
        this.colmeiaDialogSvc.closeAll();
    }
}
