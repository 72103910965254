import { isInEnumFactory } from "@colmeia/core/src/tools/utility/functions/is-in-enum-factory";
import { screenGroupsMaxUsersConfig } from "@colmeia/core/src/shared-business-rules/social-network/social-network-config/screen-groups-max-users-config-db";

export type TEIdMenusArray = Array<EIdMenus>


export enum EIdMenus {
    AttendanceFile = 'provider-integration',
    AttendanceStatus = 'provider-status',
    AttendanceAgent = 'provider-file',
    AttendanceIsland = 'provider-attendance',
    AttendanceCopilot = 'provider-copilot',
    AttendanceCalendar = 'calendar',
    AttendanceAgentStatus = 'provider-agentstatus',
    AttendanceFollowUp = 'attendence-follow-up',
    AttendenceUserFunctions = 'ai-functions-atendimento',
    AttendanceForm = 'provider-form',
    agentsOnIsland = 'agents-on-island',

    ConfigurationSet = 'configuration-set-sn',

    MarketingForm = 'marketing-form',
    MarketingSendCampaign = 'marketing=send',
    MarketingCampaigns = 'marketing-campaigns',
    MarketingChannelsTemplates = "marketing-channelstemplates",
    MarketingRescheduleCalendar = "marketing-reschedule-calendar",
    MarketingTemplates = "marketing-channelstemplates",
    MarketingBPM = 'marketing-bpm',
    MarketingUserFunctions = "ai-functions-marketing",
    MarketingWabaQuality = 'marketing-waba-quality',

    OmnisenseForm = 'ai-form',
    CommunicationForm = 'communication-form',
    CommunicationConnectionRoute = 'communication-connroute',
    CommunicationApi = 'communication-api',

    OmnisenseChainedBase = 'ai-chainedbases',
    OmnisenseKnowledgeBase = 'ai-kb',
    OmnisenseTags = 'ai-tags',
    OmnisenseCanonicals = 'ai-canonical',
    OmnisenseTransactions = 'ai-transaction',
    OmnisenseBPMGraph = 'ai-bpmgraph',
    OmnisenseKBCorporateEntity = 'ai-kbcorporateentity',
    OmnisenseUserFunctions = 'ai-functions-omnisense',
    OmnisenseNestedAI = 'nested-ai-bpm-omnisense',
    OmnisenseVectors = "ai-vectors-omnisense",
    OmnisensePrompts = "ai-prompts-omnisense",

    SocialNetworkAccessByGroups = 'menusn-groups-list',
    SocialNetworkAccess = 'menusn-groups',
    SocialNetworkUsers = 'menusn-users',
    SocialNetworkForm = 'menusn-form',
    SocialNetworkFacecompany = 'menusn-facecompany',
    SocialNetworkShareServices = 'menusn-shareservices',
    SocialNetworkConfigurations = 'menusn-configurations',
    SocialNetworkUserSettings = 'definicoes-usuario',
    SocialNetworkUserProfiles = 'menusn-user-profiles',

    ServicesServiceDeployed = 'service-deployed',
    ServicesCRMServicesConfig = "crm-service-config",
    ServiceIntegration = 'service-integration',
    ServiceUserFunctions = 'ai-functions-service',

    JobsEnvironmentDataMigration = 'jobs-environmentdatamigration',
    JobsMachineLearning = 'jobs-jobsmachinelearning',
    JobsDeleteAvatarFromSocialNetwork = 'jobs-deleteavatarfromsocialnetwork',
    JobsCampaignExecute = 'jobs-campaign-execute',
    JobsCampaignCancelSchedule = 'jobs-campaign-cancel-schedule',
    JobsFileUpload = 'jobs-file-upload',
    JobsCorporateSearchProcess = 'jobs-corporate-search-process',
    JobsDeleteNS = 'jobs-delete-ns',
    JobsDeleteFile = 'jobs-delete-file',
    JobsDeleteFileContents = 'jobs-delete-file-contents',
    JobsMigrationItemsProcessing = 'jobs-migrationitemsprocessing',
    JobsRequestPayload = 'jobs-request-payload',
    JobsUserGroupProfileUpdate = 'jobs-userGroupProfileUpdate',
    JobsSocialNetworkBatchApproval = 'jobs-socialNetworkBatchApproval',
    JobsFetchContentFromUrlsInVector = 'jobs-fetch-content-from-urls-in-vector',


    CommunicationChannels = 'communication-sm',
    CommunicationWaba = 'communication-waba',
    CommunicationIntegration = 'communication-integration',
    CommunicationConnections = 'communication-connections',
    CommunicationPayments = 'communication-payments',
    CommunicationPaymentsGateways = 'communication-payments-gateways',
    ContactList = 'contact-list',
    ProviderActivecall = 'provider-activecall',
    ProviderAttendanceevents = 'provider-attendanceevents',
    ProviderAgents = 'provider-agents',
    ProviderAttsuport = 'provider-attsuport',
    ProviderSendactive = 'provider-sendactive',
    ConversationHistory = 'conversation-history',

    AnalyticsAgent = 'analytics-agent',
    AnalyticsAi = 'analytics-ai',
    AnalyticsAttendance = 'analytics-attendance',
    AnalyticsBot = 'analytics-bot',
    AnalyticsForms = 'analytics-forms',
    AnalyticsLgpd = 'analytics-lgpd',
    AnalyticsMacroView = 'analytics-macroView',
    AnalyticsMarketing = 'analytics-marketing',
    AnalyticsCustomReports = 'analytics-custom-reports',
    AnalyticsCorporateAttendance = 'analytics-corporate-attendance',
    AnalyticsCorporateMarketing = 'analytics-corporate-marketing',
    AnalyticsCRM = 'analytics-crm',

    AnalyticsAgentHistory = 'analytics-agent-history',
    AnalyticsAiHistory = 'analytics-ai-history',
    AnalyticsAttendanceHistory = 'analytics-attendance-history',
    AnalyticsBotHistory = 'analytics-bot-history',
    AnalyticsCorporateAttendanceHistory = 'analytics-corporate-attendance-history',
    AnalyticsCorporateMarketingHistory = 'analytics-corporate-marketing-history',
    AnalyticsCustomReportsHistory = 'analytics-custom-reports-history',
    AnalyticsFormsHistory = 'analytics-forms-history',
    AnalyticsLGPDHistory = 'analytics-lgpd-history',
    AnalyticsMacroViewHistory = 'analytics-macroView-history',
    AnalyticsMarketingHistory = 'analytics-marketing-history',
    AnalyticsCRMHistory = 'analytics-crm-history',

    Nlpinputs = 'nlpinputs',
    SmartflowContentGenerator = 'smartflow-content-generator',
    AiBots = 'ai-bots',
    AiIntegration = 'ai-integration',
    SmartflowRtfParams = 'smartflow-rtf-params',
    AiFunctions = 'ai-functions',
    IndexedSearchAi = 'indexed-search-ai',
    ProviderNamedaccount = 'provider-namedaccount',
    WhatsappFlows = 'whatsapp-flows',
    CRMservicesClosedState = 'crmservices-closed-state',
    CRMservicesPackages = 'crmservices-packages',
    CRMservicesPhases = 'crmservices-phases',
    CRMservicesSeverity = 'crmservices-severity',
    CRMservicesTicketState = 'crmservices-ticket-state',
    CRMServicesSupportLevel = 'crmservices-support-level',
    CRMServicesUrgencyLevel = 'crmservices-urgency-level',
    CRMservicesGraphList = 'crmservices-graphs-list',
    CRMServicesHelpDesk = 'crmservices-help-desk',
    CRMTicketGenerator = 'crmservices-ticket-generator',
    CRMTicketsView = 'crmservices-tickets-view',

    ColmeiaWidget = 'colmeia-widget',
    ColmeiaContracts = 'colmeia-contracts',
    ColmeiaVisualElements = 'colmeia-visual-elements',
    ColmeiaTools = 'colmeia-tools',
    ColmeiaBroker = 'colmeia-broker',
    ColmeiaScripts = 'colmeia-scripts',
    ColmeiaDashboardsConfig = 'colmeia-dashboards-config',
    ColmeiaHelpTips = 'colmeia-help-tips',
    ColmeiaDashboardsHomologation = 'colmeia-dashboards-homologation',
    ColmeiaProducts = 'colmeia-products',
    ColmeiaBusinessContracts = 'colmeia-business-contracts',
    ColmeiaBillingClose = 'colmeia-billing-close',
    ColmeiaHouseKeeping = 'colmeia-house-keeping',

    MarketingDataextractor = 'marketing-dataextractor',
    MarketingIntegration = 'marketing-integration',
    RescheduleCalendar = 'reschedule-calendar',
    JobsFileDownload = 'jobs-file-download',
    EnvChange = 'env-change',
    EnvTransformations = 'env-transformations',
    EnvPatches = 'env-patches',
    EnvPromote = 'env-promote',
    EnvTagsdeployedmenuitem = 'env-tagsdeployedmenuitem',
    EnvBackup = "env-backup",
    EnvVersioning = 'env-versioning',

    DataExtractor = 'data-extractor',
    JobsDataExtraction = 'jobs-data-extraction',

    CRMTransformer = 'crm-visualizer',


    SmartFlowTransformer = 'sf-transformer',

    // MarketingPerformancePixels = 'marketing-performance-pixels',
    MarketingPerformanceAds = 'marketing-performance-ads',
    MarketingPerformanceBusinessAccounts = 'marketing-performance-business-accounts',
}

export enum EIdMenus {
    ConversationalCommerceCatalog = 'conversational-commerce-catalog',
    ConversationalIntegration = 'conversationalcommerce-integration',
    ConversationalForm = 'conversationalcommerce-form',
    ColmeiaMenuCreator = 'colmeia-menu-creator',
    ColmeiaCoordinatorDeletion = 'colmeia-coordinatordeletion',
}

export enum EIdMenus {
    SecurityTasks = 'security-tasks',
}

export enum EIdMenus {
    WAFSchemaUpsert = 'waf-schema-upsert',
    WAFSchemaPublish = 'waf-schema-publish',
    WAFSchemaDelete = 'waf-schema-delete',
    WAFSchemaMessageSend = 'waf-schema-message-send',
    WAFSchemaDeprecate = 'waf-schema-deprecate',
    WAFSchemaGet = 'waf-schema-get',
    WAFSchemaList = 'waf-schema-list',
    WAFSchemaSubmitToMeta = 'waf-schema-submit-to-meta',
}

export enum EIdMenus {
    WAFUpsert = 'waf-upsert',
    WAFGet = 'waf-get',
    WAFListProviders = 'waf-list-providers',
    businessEncryptionKey = 'waf-business-encryption-key'
}


export const isIdMenu = isInEnumFactory(EIdMenus)

