import { IServiceDeployedServices } from "@colmeia/core/src/shared-business-rules/deployed-services/deployed-services";
import { IParticipantJSON } from "../../comm-interfaces/business-interfaces";
import { EDelivery360Action } from "../../comm-interfaces/interaction-interfaces";
import { DeliveryProvider, TGlobalUID } from "../../core-constants/types";
import { IGeneralFormAnswer } from "../../general-form/general-form-answer";
import { Miliseconds } from "../../time/time-utl";
import { createMapFromEntries } from "../../tools/utility/functions/createMapFromEntries";
import { $ValueOf, $declare, DeepRequiredByValue, EAssert } from "../../tools/utility-types";
import { defineDeepMap } from "../../tools/utility/functions/defineDeepMap";
import { Explicit, IntersectPartialExplicit, ValueOf } from "../../tools/utility/types";
import { Custom } from "../../tools/utility/types/generic";
import { IBotActionAsset } from "../bot/bot-action-model";
import { IContentBasicAsset } from "../bot/bot-content-model";
import { TMarketingAction } from "../bot/new-bot-action";
import { ICompiledTemplateWithVariables, TPropertyKeyArray } from "../metadata/metadata-util-interfaces";
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";
import { ITelegramUser } from "./telegram.interfaces";
import { ESMSMessageType, INSWhatsAppTemplateServer, IWhatsAppTemplateParameter } from "./template-model";
import { WhatsApp } from "./whatsapp-interface";
import { EMetaWhatsappMessagingLimitTier, EMetaWhatsappPhoneStatus } from "../channels/meta-whatsapp-channel.model";

export type TIISocialMediaConnectionArray = Array<ISocialMediaConnectionServer>;

export enum EInterfaceCommType {
    fixed = 1
}

export interface ISocialMediaConnection extends INonSerializableHeader {
    socialMediaType: EDelivery360Action;
    provider: DeliveryProvider;
    address: string;
    detail: IProviderConnectionDetail;
    isActiveDefault: boolean;
}


export enum EConnectionCredentialStatus {
    UnChecked = 'UnChecked',
    Valid = 'Valid',
    Invalid = 'Invalid',
}

export interface IProviderConnectionDetail {
    notToBeUsed: EInterfaceCommType.fixed;
    credentialStatus?: EConnectionCredentialStatus;
    shouldAllowChannelForMarketing?: boolean;
    shouldSubmitAllApprovedTemplates?: boolean;
}

export enum EHandshakeFormType {
    javascriptIntegration = 'ft.jsInte',
    customerFullfill = 'ft.client',
    fileShakeHands = 'ft.file'
}


export interface IHandShakeForm {
    type: EHandshakeFormType;
    idMetadata: string;
    answer: IGeneralFormAnswer;
};

export type THandshakeFormArray = Array<IHandShakeForm>;

export interface IThirdPartShakingHands {
    idShakingHands: string;
    idUserToken: string;
    idSocialContext: string;
    idService: string;
    handshakeForms: THandshakeFormArray;
    idCustomerChatGroup: string;
    addressName?: string;
    address: string;
    idAvatar: TGlobalUID;
    idAttendentGroup: TGlobalUID;
    participantCustomer: IParticipantJSON;
    participantBot: IParticipantJSON;
    participantAgent: IParticipantJSON;
    isAttending: boolean;
    isOpenConversation: boolean;
    clockTick: Miliseconds;
    timezone?: number; // para embeded services
    isVirtualService?: boolean;
    // idBot?: string
    virtualService: IServiceDeployedServices;
    idConversation?: string;
};




export interface ColmeiaEmbeddedConnectionDetails extends IProviderConnectionDetail {
    socialMediaType: EDelivery360Action.DeliveryColmeia;
    handshake?: IThirdPartShakingHands; // Não utilizar.. apenas o server preenche na execução
}

export interface TwilioBaseConnectionDetails extends IProviderConnectionDetail {
    key: string;
    authToken: string;
    service: string;
    hasContentAPI?: boolean;
}

export interface TwilioWhatsappConnectionDetails extends TwilioBaseConnectionDetails { }

export interface TwilioSmsConnectionDetails extends TwilioBaseConnectionDetails { }

export interface TwilioVoiceConnectionDetails extends TwilioBaseConnectionDetails {
    callSid?: string; // NÃO PREENCHER, APENAS O SERVER INSERE ISSO DURANTE EXECUÇÃO.
    gatherURL?: string; // NÃO PREENCHER, APENAS O SERVER INSERE ISSO DURANTE EXECUÇÃO.
}



export interface PontaltechBaseConnectionDetails extends IProviderConnectionDetail {

}

export interface PontaltechRCSConnectionDetails extends PontaltechBaseConnectionDetails {

}

export interface IPontalTechConnectionDetails extends PontaltechBaseConnectionDetails {
    username: string,
    password: string,
    domainApi: string,
    accountId: string,
    smsMessageType: ESMSMessageType,
}


export interface InfobipBaseConnectionDetails extends IProviderConnectionDetail {
    domainApi: string,
    username: string,
    password: string
}

export interface InfobipWhatsappConnectionDetails extends InfobipBaseConnectionDetails, IWhatsAppDetail {
    scenarioKey: string
}

export interface InfobipSmsConnectionDetails extends InfobipBaseConnectionDetails { }

export interface InfobipRcsConnectionDetails extends InfobipBaseConnectionDetails { }

export interface InfobipVoiceConnectionDetails extends InfobipBaseConnectionDetails { }



export enum EEmailTemplateType {
    variables = 'variables',
    content = 'content',
}

export interface ITemplateTextWithVariables {
    title: ICompiledTemplateWithVariables;
    localCanonicals: TPropertyKeyArray;
}

export interface IEmailTemplateVariables extends IEmailTemplate, ITemplateTextWithVariables {

}
export interface IEmailTemplateContent extends IEmailTemplate { }
export interface IEmailTemplate extends IGenericTemplateDetails {
    type: EEmailTemplateType;
    idEmailTemplate: string;
}



export type TEmailTemplateArray = IEmailTemplate[];

export interface IProviderConnectionDetailWithTemplates extends IProviderConnectionDetail {
    // templates?: IGenericTemplateDetails[];
}
export interface SendgridEmailConnectionDetails extends IProviderConnectionDetailWithTemplates {
    key: string;
    // templates?: TEmailTemplateArray;
}


export interface ColmeiaFacebookMessengerDetails extends IProviderConnectionDetail {
    /**
     * facebook page id
     */
    pageId: string;
    /**
     * facebook page name
     */
    pageName: string;
    instagramPageName: string;
    tokenPage?: string;
    subscribedAppId?: string
}

export type SocialMediaProviderMap = Map<EDelivery360Action, Array<IProviderPresentation>>;
export enum EProviderPresentationID {
    twilio = 'twilio',
    sendgrid = 'sendgrid',
    colmeia = 'colmeia',
    infobip = 'infobip',
    facebook = 'facebook',
    instagram = 'instagram',
    whatsApp = 'whatsApp',
    wavy = 'wavy',
    smarters = 'smarters',
    meta = 'meta',
    pontaltech = 'pontaltech'
}


export interface IProviderPresentation {
    id: EProviderPresentationID;
    enum: DeliveryProvider;
    title: string;
    icon: string;
}

const twilioPresentation: IProviderPresentation = {
    id: EProviderPresentationID.twilio,
    enum: DeliveryProvider.twilio,
    icon: './assets/img/360-provider/twilio.png',
    title: 'Twilio'
};

const sendgridPresentation: IProviderPresentation = {
    id: EProviderPresentationID.sendgrid,
    enum: DeliveryProvider.sendGrid,
    title: 'Sendgrind',
    icon: './assets/img/360-provider/sendgrid.png'
};

const colmeiaPresentation: IProviderPresentation = {
    id: EProviderPresentationID.colmeia,
    enum: DeliveryProvider.colmeia,
    title: 'Colmeia',
    icon: './assets/img/360-provider/colmeia.svg'
};

const infobipPresentation: IProviderPresentation = {
    id: EProviderPresentationID.infobip,
    enum: DeliveryProvider.infobip,
    title: 'Infobip',
    icon: './assets/img/360-provider/infobip.svg'
};

const facebookPresentation: IProviderPresentation = {
    id: EProviderPresentationID.facebook,
    enum: DeliveryProvider.facebook,
    title: 'Facebook',
    icon: './assets/img/360-provider/messenger.svg'
};

const instagramPresentation: IProviderPresentation = {
    id: EProviderPresentationID.instagram,
    enum: DeliveryProvider.instagram,
    title: 'Instagram',
    icon: './assets/img/360-provider/messenger.svg'
};

const whatsAppPresentation: IProviderPresentation = {
    id: EProviderPresentationID.whatsApp,
    enum: DeliveryProvider.whatsApp,
    title: 'Meta',
    icon: './assets/img/360-provider/icons8-meta.svg',
};

const wavyPresentation: IProviderPresentation = {
    id: EProviderPresentationID.wavy,
    enum: DeliveryProvider.wavy,
    title: 'Wavy',
    icon: './assets/img/360-provider/wavy.svg'
};

const smartersPresentation: IProviderPresentation = {
    id: EProviderPresentationID.smarters,
    enum: DeliveryProvider.smarters,
    title: 'Smarters',
    icon: './assets/img/360-provider/smarters.svg'
};

const pontaltechPresentation: IProviderPresentation = {
    id: EProviderPresentationID.pontaltech,
    enum: DeliveryProvider.pontaltech,
    title: 'Pontaltech',
    icon: './assets/img/360-provider/pontaltech.svg'
};

export const providersBySocialMedia: SocialMediaProviderMap = createMapFromEntries<{ [key in EDelivery360Action]: IProviderPresentation[] }, false>()([
    [EDelivery360Action.DeliveryTelegram, []],
    [EDelivery360Action.DeliveryColmeia, []],
    [EDelivery360Action.DeliveryInstagram, [instagramPresentation]],
    [EDelivery360Action.Delivery360FBMessenger, [facebookPresentation]],
    [EDelivery360Action.Delivery360Email, [sendgridPresentation]],
    [EDelivery360Action.Delivery360WhatsApp, [
        twilioPresentation,
        infobipPresentation,
        whatsAppPresentation,
        wavyPresentation,
        smartersPresentation,
    ]],
    [EDelivery360Action.Delivery360SMS, [twilioPresentation, pontaltechPresentation]],
    [EDelivery360Action.Delivery360RCS, [pontaltechPresentation]],
    [EDelivery360Action.Delivery360Voice, [twilioPresentation]]
])

export interface IGenericTemplateDetails {
    idTemplate?: string;
    name: string;
}



export type TWhatsappTemplates = Array<IWhatsAppTemplateParameter>;

export enum EAdvancedTemplateOption {
    quickReply = 'quickReply',
    callToAction = 'callToAction'
}

export const DEFAULT_WHATSAPP_TEMPLATE_LANGUAGE: WhatsApp.Template.Language = "pt_BR" as WhatsApp.Template.Language //WhatsApp.Template.Language.PortugueseBR;
export const DEFAULT_WHATSAPP_SECTION_NAME = 'Opções';

export const MAX_WHATSAPP_BUTTONS_QUICK_REPLY_CALL_TO_ACTION: number = 10;
export const MAX_WHATSAPP_BUTTONS_AUTHENTICATION: number = 1;

export const MAX_WHATSAPP_BUTTONS_PHONE_BUTTON: number = 1;
export const MAX_WHATSAPP_BUTTONS_URL_BUTTON: number = 2;
export const MAX_WHATSAPP_BUTTONS_COPY_CODE_BUTTON: number = 1;

export const MAX_WHATSAPP_BUTTONS_QUICK_REPLY: number = 3;
export const MAX_WHATSAPP_BUTTONS_CALL_TO_ACTION: number = 2;
export const MAX_WHATSAPP_BUTTON_TITLE_SIZE: number = 20;

export const MAX_WHATSAPP_LIST_SECTION_TITLE_SIZE: number = 24;
export const MAX_WHATSAPP_LIST_ROW_TITLE_SIZE: number = 24;
export const MAX_WHATSAPP_LIST_ROW_DESCRIPTION_SIZE: number = 72;

export const MAX_WHATSAPP_LIST_ROWS_PER_SECTION: number = 10;
export const MAX_WHATSAPP_LIST_SECTIONS: number = 10;
export const MAX_WHATSAPP_TEMPLATE_BODY_SIZE: number = 1024;
export const MAX_WHATSAPP_TEMPLATE_NAME: number = 512;
export const MAX_WHATSAPP_TEMPLATE_BUTTON_TEXT_SIZE: number = 25;
export const MAX_WHATSAPP_TEMPLATE_BUTTON_CONTENT_PHONE_NUMBER_SIZE: number = 20;
export const MAX_WHATSAPP_TEMPLATE_BUTTON_CONTENT_URL_SIZE: number = 2000;
export const MAX_WHATSAPP_TEMPLATE_BUTTON_CONTENT_COPY_CODE_SIZE: number = 15;
export const MAX_WHATSAPP_TEMPLATE_BUTTON_CONTENT_OTP_SIZE: number = 15;

export const MAX_WHATSAPP_INTERACTIVE_HEADER_TEXT_SIZE: number = 60;
export const MAX_WHATSAPP_INTERACTIVE_FOOTER_TEXT_SIZE: number = 60;

export const MAX_WHATSAPP_INTERACTIVE_LTO_TEXT_SIZE: number = 16; // LTO means limited time offer

export const MAX_RCS_BUTTONS_QUICK_REPLY_CALL_TO_ACTION: number = 4;
export const MAX_RCS_BUTTONS_PHONE_BUTTON: number = 1;
export const MAX_RCS_BUTTONS_URL_BUTTON: number = 1;
export const MAX_RCS_BASIC_TEMPLATE_BODY_SIZE: number = 160;
export const MAX_RCS_SINGLE_TEMPLATE_BODY_SIZE: number = 5000;
export const MAX_RCS_RICH_CARD_BODY_SIZE: number = 2000;
export const MAX_RCS_TEMPLATE_BUTTON_CONTENT_PHONE_NUMBER_SIZE: number = 20;
export const MAX_RCS_TEMPLATE_BUTTON_CONTENT_URL_SIZE: number = 2000;
export const MAX_RCS_TEMPLATE_BUTTON_TEXT_SIZE: number = 25;

export interface IQuickReplyAsset extends IBotActionAsset {
    type: TMarketingAction;
    idElement?: IdDep<ENonSerializableObjectType.bot | ENonSerializableObjectType.serviceIsland>;
}

// Add later the others types of buttons that can be used on templates, I only included the ones that are present in Limited Time Offers
export enum ButtonParameterNames {

    COUPONCODE = "coupon_code",
    URL = "url",
    PAYLOAD = "payload"
}


export interface IQuickReplyOption {
    buttonText: string;
    buttonData?: string; // phoneNumber, url ...
    buttonDataContent?: IContentBasicAsset;
    action: IQuickReplyAsset;
};

export type TIQuickReplyOptionArray = Array<IQuickReplyOption>;

export interface IAdvancedTemplateAction {
    /** @deprecated */
    type?: EAdvancedTemplateOption;
    quickReplyOptions: TIQuickReplyOptionArray;
}

export enum EColmeiaWATemplateApprovalStatus {
    Notsent = 'notsent',
    ConnectionError =  'CONNECTION_ERROR'
}

export type TColmeiaWhatsAppTemplateApprovalStatus = WhatsApp.Template.Status | EColmeiaWATemplateApprovalStatus;


export interface IWhatsAppTemplate extends IWhatsAppTemplateParameter { };

export function getCompiledTemplateByConnectionDetailsTemplateAndChannel(channel: EDelivery360Action, template: IWhatsAppTemplateParameter): ICompiledTemplateWithVariables {
    switch (channel) {
        case EDelivery360Action.Delivery360WhatsApp: return template.whatsAppVariablesTemplate;
    }
}

export interface IInfobipWhatsppTemplate extends IWhatsAppTemplate {
    nameSpace: string;
}

export interface IWhatsAppDetail extends IProviderConnectionDetail, IProviderConnectionDetailWithTemplates {
    //
    settings?: Partial<WhatsApp.Profile.Settings>;
}

export interface ITwilioWhatsappDetails extends IWhatsAppDetail, TwilioBaseConnectionDetails { }



export type TMessengerProviderType =
    | EDelivery360Action.Delivery360FBMessenger
    | EDelivery360Action.DeliveryInstagram
    ;


export type TMetaProviderType =
    | TMessengerProviderType
    | EDelivery360Action.Delivery360WhatsApp
    ;
export interface IWhatsAppSocialMediaConnectionServer extends ISocialMediaConnectionServer {
    socialMediaType: EDelivery360Action.Delivery360WhatsApp;
    detail: IWhatsAppDetail
}

export interface ISMSSocialMediaConnectionServer extends ISocialMediaConnectionServer {
    socialMediaType: EDelivery360Action.Delivery360SMS;
    detail: InfobipSmsConnectionDetails
}

export interface IRCSSocialMediaConnectionServer extends ISocialMediaConnectionServer {
    socialMediaType: EDelivery360Action.Delivery360RCS;
    detail: InfobipSmsConnectionDetails
}

export interface IVoiceSocialMediaConnectionServer extends ISocialMediaConnectionServer {
    socialMediaType: EDelivery360Action.Delivery360Voice;
    detail: InfobipVoiceConnectionDetails | TwilioVoiceConnectionDetails
}

export interface IMessengerSocialMediaConnectionServer extends ISocialMediaConnectionServer {
    socialMediaType: TMessengerProviderType;
    detail: ColmeiaFacebookMessengerDetails
}

export interface IFBMessengerSocialMediaConnectionServer extends IMessengerSocialMediaConnectionServer {
    socialMediaType: EDelivery360Action.Delivery360FBMessenger;
}

export interface IInstagramSocialMediaConnectionServer extends IMessengerSocialMediaConnectionServer {
    socialMediaType: EDelivery360Action.DeliveryInstagram;
}

export type TMessengerSocialMediaConnectionServer = IFBMessengerSocialMediaConnectionServer | IInstagramSocialMediaConnectionServer



export interface IEmailSocialMediaSendGridConnectionServer extends ISocialMediaConnectionServer {
    socialMediaType: EDelivery360Action.Delivery360Email;
    detail: SendgridEmailConnectionDetails
}
export interface IColmeiaSocialMediaConnectionServer extends ISocialMediaConnectionServer {
    socialMediaType: EDelivery360Action.DeliveryColmeia;
    detail: ColmeiaEmbeddedConnectionDetails
}

export interface ITelegramSocialMediaConnectionDetails extends IProviderConnectionDetail {
    token: string;
    webHookDomain: string;
    info: ITelegramUser;
}


export interface ITelegramSocialMediaConnectionServer extends ISocialMediaConnectionServer {
    socialMediaType: EDelivery360Action.DeliveryTelegram;
    detail: ITelegramSocialMediaConnectionDetails
}

export type TSocialMediaConnection = $ValueOf<MapTypeToSocialMediaConnection>

export type MapTypeToSocialMediaConnection = Explicit<{ [key in EDelivery360Action]: IntersectPartialExplicit<ISocialMediaConnectionServer, { socialMediaType: key }> }, {
    [EDelivery360Action.Delivery360WhatsApp]: IWhatsAppSocialMediaConnectionServer;
    [EDelivery360Action.Delivery360SMS]: ISMSSocialMediaConnectionServer;
    [EDelivery360Action.Delivery360RCS]: IRCSSocialMediaConnectionServer;
    [EDelivery360Action.Delivery360FBMessenger]: IFBMessengerSocialMediaConnectionServer;
    [EDelivery360Action.DeliveryInstagram]: IInstagramSocialMediaConnectionServer;
    [EDelivery360Action.Delivery360Email]: IEmailSocialMediaSendGridConnectionServer;
    [EDelivery360Action.Delivery360Voice]: IVoiceSocialMediaConnectionServer;
    [EDelivery360Action.DeliveryColmeia]: IColmeiaSocialMediaConnectionServer;
    [EDelivery360Action.DeliveryTelegram]: ITelegramSocialMediaConnectionServer;
}>

type MapTypeToDetails = { [key in EDelivery360Action]: MapTypeToSocialMediaConnection[key]['detail'] }
export interface ISocialMediaConnectionServer extends INonSerializable, ISocialMediaConnection { }

export type NulledSocialMediaConnectionInterfacesMap = Map<string, IProviderConnectionDetail>;

// export const allNulledSocialMediaConnectionInterfaces: NulledSocialMediaConnectionInterfacesMap = new Map<string, IProviderConnectionDetail>([
//     [`${EDelivery360Action.Delivery360FBMessenger}_${DeliveryProvider.colmeia}`, <ColmeiaFacebookMessengerDetails>{idPage: null, tokenPage: null, notToBeUsed: EInterfaceCommType.fixed}],
//     [`${EDelivery360Action.DeliveryInstagram}_${DeliveryProvider.colmeia}`, <ColmeiaFacebookMessengerDetails>{idPage: null, tokenPage: null, notToBeUsed: EInterfaceCommType.fixed}],
//     [`${EDelivery360Action.Delivery360WhatsApp}_${DeliveryProvider.twilio}`, <ITwilioWhatsappDetails>{authToken: null, key: null, phoneNumber: null, notToBeUsed: EInterfaceCommType.fixed, service: undefined }],
//     [`${EDelivery360Action.Delivery360SMS}_${DeliveryProvider.twilio}`, <TwilioSmsConnectionDetails>{authToken: null, key: null, phoneNumber: null, notToBeUsed: EInterfaceCommType.fixed, service: undefined }],
//     [`${EDelivery360Action.Delivery360Email}_${DeliveryProvider.sendGrid}`, <SendgridEmailConnectionDetails> {key: null, notToBeUsed: EInterfaceCommType.fixed}],
//     [`${EDelivery360Action.Delivery360WhatsApp}_${DeliveryProvider.infobip}`, <InfobipWhatsappConnectionDetails>{password: null, username: null}]
// ]);


const whatsAppDetails = defineDetails<IWhatsAppDetail>({
    notToBeUsed: EInterfaceCommType.fixed,
    settings: {
        websites: [],
    },
})

const smartersDetails = defineDetails<ISmartersWhatsappDetails>({
    notToBeUsed: EInterfaceCommType.fixed,
    settings: {
        websites: [],
    },
    whatsAppBusinessAccountIdNs: '',
    whatsAppBusinessAccountId: ''
})

const wavyDetails = defineDetails<IWavyWhatsappDetails>({
    notToBeUsed: EInterfaceCommType.fixed,
    externalAccount: {},
    settings: {
        websites: [],
    },
})

function defineDetails<T>(details: DeepRequiredByValue<Partial<T>, object>): T {
    return details as T;
}


export const allNulledSocialMediaConnectionInterfaces = defineDeepMap<[EDelivery360Action, DeliveryProvider, $ValueOf<MapTypeToDetails>]>()([
    [EDelivery360Action.Delivery360WhatsApp, DeliveryProvider.smarters, smartersDetails],
    [EDelivery360Action.Delivery360WhatsApp, DeliveryProvider.wavy, wavyDetails],
    [EDelivery360Action.Delivery360FBMessenger, DeliveryProvider.facebook, {
        notToBeUsed: EInterfaceCommType.fixed,
        tokenPage: undefined,
    }],
    [EDelivery360Action.DeliveryInstagram, DeliveryProvider.instagram, {
        notToBeUsed: EInterfaceCommType.fixed,
        tokenPage: undefined,
    }],
    [EDelivery360Action.Delivery360WhatsApp, DeliveryProvider.twilio, {
        notToBeUsed: EInterfaceCommType.fixed,
    }],
    [EDelivery360Action.Delivery360WhatsApp, DeliveryProvider.sinch, {
        notToBeUsed: EInterfaceCommType.fixed,
    }],
    [EDelivery360Action.Delivery360SMS, DeliveryProvider.twilio, {
        notToBeUsed: EInterfaceCommType.fixed,

        domainApi: undefined,
        username: undefined,
        password: undefined,
    }],
    [EDelivery360Action.Delivery360RCS, DeliveryProvider.pontaltech, {
        notToBeUsed: EInterfaceCommType.fixed,
        domainApi: undefined,
        username: undefined,
        password: undefined,
    }],
    [EDelivery360Action.Delivery360Email, DeliveryProvider.sendGrid, {
        notToBeUsed: EInterfaceCommType.fixed,
        key: undefined,
    }],
    [EDelivery360Action.Delivery360WhatsApp, DeliveryProvider.infobip, {
        notToBeUsed: EInterfaceCommType.fixed,
    }],
    [EDelivery360Action.Delivery360WhatsApp, DeliveryProvider.whatsApp, whatsAppDetails],
    [EDelivery360Action.DeliveryColmeia, DeliveryProvider.colmeia, {
        notToBeUsed: EInterfaceCommType.fixed,
        socialMediaType: undefined,
    }],
    [EDelivery360Action.DeliveryTelegram, DeliveryProvider.telegram, {
        notToBeUsed: EInterfaceCommType.fixed,
        token: undefined,
        webHookDomain: undefined,
        info: undefined,
    }],

    [EDelivery360Action.Delivery360Voice, undefined, undefined],


], $declare<IGetItem>());

interface IGetItem extends Custom.Generic {
    compute(): never extends EAssert<infer Item, this['item']> ?
        Item extends [EDelivery360Action, DeliveryProvider, $ValueOf<MapTypeToDetails>] ?
        MapTypeToDetails[Item[0]]
        : {}
        : never
}

export function socialMediaToAddress(social: ISocialMediaConnection): string {
    if (!social) {
        return "";
    }
    return social.address;
}


export type MapWhatsAppSocialMediaConnectionServer = Explicit<{ [Provider in DeliveryProvider]?: IntersectPartialExplicit<IWhatsAppSocialMediaConnectionServer, { provider: Provider }> }, {
    [DeliveryProvider.infobip]: IInfobipIWhatsAppSocialMediaConnectionServer;
    [DeliveryProvider.twilio]: ITwillioIWhatsAppSocialMediaConnectionServer;
    [DeliveryProvider.whatsApp]: IMetaIWhatsAppSocialMediaConnectionServer;
    [DeliveryProvider.sinch]: ISinchWhatsAppSocialMediaConnectionServer;
    [DeliveryProvider.wavy]: IWavyWhatsAppSocialMediaConnectionServer;
    [DeliveryProvider.smarters]: ISmartersWhatsAppSocialMediaConnectionServer;
}>;


export interface IInfobipIWhatsAppSocialMediaConnectionServer extends IWhatsAppSocialMediaConnectionServer {
    provider: DeliveryProvider.infobip;
    detail: InfobipWhatsappConnectionDetails;
}
export interface ITwillioIWhatsAppSocialMediaConnectionServer extends IWhatsAppSocialMediaConnectionServer {
    provider: DeliveryProvider.twilio;
    detail: ITwilioWhatsappDetails;
}
export interface IMetaIWhatsAppSocialMediaConnectionServer extends IWhatsAppSocialMediaConnectionServer {
    provider: DeliveryProvider.whatsApp;
    detail: IMetaWhatsappDetails;
    templates?: INSWhatsAppTemplateServer[]
}

export interface IMetaWhatsappDetails extends IWhatsAppDetail, IMetaProviderDetails {
    token: string;
    phoneId?: string;
    webhookURL?: string;
    solutionPartnerID?: string
    whatsAppBusinessAccountId: string;
    whatsAppBusinessAccountIdNs: IdDep<ENonSerializableObjectType.waba>;
    businessEncryptKeyUrl?: string;
    status?: string;
    qualityRating?: string;
    messagingLimitTier?: string;
    messageError?: string;
}

//
export interface IWavyWhatsAppSocialMediaConnectionServer extends IWhatsAppSocialMediaConnectionServer {
    provider: DeliveryProvider.wavy;
    detail: IWavyWhatsappDetails;
}




export interface IWavyWhatsappDetailsAccount {
    email: string;
    password: string;
}
export interface IWavyWhatsappDetails extends IWhatsAppDetail, IWavyWhatsappDetailsAccount {
    externalAccount: IWavyWhatsappDetailsAccount;

    username: string;
    authenticationToken: string;
}

// // // // // //


export interface ISmartersWhatsAppSocialMediaConnectionServer extends IWhatsAppSocialMediaConnectionServer {
    provider: DeliveryProvider.smarters;
    detail: ISmartersWhatsappDetails;
}

export interface ISmartersWhatsappDetails extends IWhatsAppDetail {
    authenticationToken: string;
    webHookDomain: string;
    token: string;
    whatsAppBusinessAccountId: string;
    whatsAppBusinessAccountIdNs: IdDep<ENonSerializableObjectType.waba>;
    solutionPartnerID?: string
}

// // // // // //

export interface ISinchWhatsAppSocialMediaConnectionServer extends IWhatsAppSocialMediaConnectionServer {
    provider: DeliveryProvider.sinch;
    detail: ISinchWhatsappDetails;
}

export interface ISinchWhatsappDetails extends IWhatsAppDetail {
    projectId: string;
    botId: string;
    bearerToken: string;
}

export interface IMetaProviderDetails {
    businessId?: string;
}

export type TWhatsAppSocialMediaConnectionServer =
    ValueOf<MapWhatsAppSocialMediaConnectionServer>
    ;

export type TDeliveryProvidersWithWhatsApp = keyof MapWhatsAppSocialMediaConnectionServer;


export interface ICurrentAddressSN {
    address: string; // key
    idSocialContext: TGlobalUID;
    clockTick: number;
}

