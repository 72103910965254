import { $declare, CheckExpression, IsEqual, IsMatch, KebabCaseV2, Negate } from "../tools/utility-types";
import { $NarrowExtends } from "../tools/utility/types/validate";

export enum EAuthURIFragments {
    Auth = 'auth',
}

export enum ELandingURIFragments {
    None = 'none',
    SignUp = 'signUp',
    SignIn = 'signIn',
    ForgotPass = 'forgotPass'
}

export const sharedUriFragments = {
    api: {
        id: 'api',
        movie: 'movie'
    },
    landing: 'landing',
    auth: EAuthURIFragments.Auth,
    signIn: {
        forgotPass: {
            id: ELandingURIFragments.ForgotPass,
            idConfirmation: 'forgotPassConfirmationID'
        } as const
    },
    signUp: {
        id: ELandingURIFragments.SignUp,
        idConfirmation: 'signUpConfirmationID',
    },
} as const

export const URL_PATH = {
    file: '/api/file',
    multimedia: '/api/multimedia'
}

export const ROUTES = defineRoutes({
    v1: {
        healthCheck: '/v1/health-check',
        healthCheckLB: '/v1/lbhealth-check',

        tracking: '/v1/tracking',

        files: '/v1/files',
        namedFiles: '/v1/named-files',
        facebookMedia: '/:hash/:name?',
        streaming: '/v1/streaming',

        oauth: '/v1/oauth',

        controlled: '/v1/controlled',
        auth: '/v1/auth',
        business: '/v1/business',
        lookup: '/v1/lookup',
        colmeia_services: '/v1/colmeia-services',
        colmeia_apis: '/v1/rest',
        conversationalCommerce: {
            path: '/v1/conversational-commerce',
            catalog: '/:mode?/:idCatalog',
        },
    },
    webhook: {
        billing: '/webhook/billing',
        sendgrid: '/webhook/sendgrid',
        infobip: '/webhook/infobip',
        twilio: '/webhook/twilio',
        sinch: '/webhook/sinch',
        wavy: '/webhook/wavy',
        smarters: '/webhook/smarters',
        whatsapp: '/webhook/whatsapp',
        facebook: '/webhook/facebook',
        instagram: '/webhook/instagram',
        telegram: '/webhook/telegram',
        gpubsub: '/webhook/gpubsub',
        webChat: getWebChatRoutes(),
        pontaltech: '/webhook/pontaltech',
    },
    payment:{
        callback: '/webhook/whatsapp/payments'
    },
}, $declare<'/v1/lbhealth-check' | '/v1/rest'>())


function getWebChatRoutes() {
    const prefix = '/webhook/web-chat';

    return {
        path: prefix,
        business: `${prefix}/business`,
        socket: `${prefix}/socket.io`,
    } as const
}


type DefineRoutes = { [key in string]: `/${string}` | DefineRoutes };
type ValidateRoutes<T, Exceptions extends string = never, Previous extends string = ''> = {
    [key in keyof T]: key extends string ? CheckExpression<Negate<IsEqual<string, Exceptions>> & IsMatch<T[key], Exceptions>> extends true ? T[key] : T[key] extends object ? ValidateRoutes<T[key], Exceptions, `${Previous}/${key}`> : `${Previous}/${KebabCaseV2<key>}` : never
}

function defineRoutes<T extends $NarrowExtends<T, DefineRoutes>, Exceptions extends string>(
    routes:
        & T
        // & AfterNarrow<
        //     T extends unknown
        //     ? (
        //         & $$.NonNullableDeep<T>
        //         & $$.ExtendsObject<T, ValidateRoutes<T, Exceptions>>
        //     )
        //     : never
        // >
    ,
    exceptions?: Exceptions
): T;
function defineRoutes(routes: unknown, exceptions?: unknown): unknown {
    return routes;
}
