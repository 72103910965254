import {
    Component,
    Input,
    OnInit
} from "@angular/core";
import { ITicketAttrThemeConfig } from "@colmeia/core/src/shared-business-rules/crm/crm-ticket-theme.model";
import { CRMTicketDesignService } from "app/crm-service-tickets-view/crm-ticket-design.service";
import { Reactify } from "app/reactify/reactify";
import { CRMTicketsService } from "../crm-tickets.service";

@Reactify.This()
@Component({
    selector: "app-crm-ticket-attr-icon-view",
    styles: [`:host { display: inline-flex; align-items: center; }`],
    template: `<app-icon [config]="themeConfig" [size]="size"></app-icon>`,
})
export class CrmTicketAttrIconViewComponent implements OnInit {
    @Input()
    ticket_id: string;

    @Input()
    attr_id: string;

    @Input()
    size: number;

    name: string;

    themeConfig: ITicketAttrThemeConfig;

    constructor(
        private ticketSvc: CRMTicketsService,
        private ticketDesignSvc: CRMTicketDesignService,
    ) { }

    async ngOnInit() {
        this.themeConfig = this.ticketDesignSvc.getTicketAttrItemTheme(this.attr_id);
    }

}
