import { Injectable } from '@angular/core';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { UberCache, TSerializableArray } from '@colmeia/core/src/persistency/uber-cache';
import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';
import { TSerializableHeaderResponse } from '@colmeia/core/src/serializable/header';
import { IAvatarJSON } from '@colmeia/core/src/comm-interfaces/business-interfaces';
import { ObjectType } from '@colmeia/core/src/business/object-type';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { SecuritySettings } from '@colmeia/core/src/security/security-settings';
import { SecurityItem } from '@colmeia/core/src/security/security-item';
import { ICreateEditAvatarResponse, IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { IEditAvatarRequest, IEditAvatarNameRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { MultimediaInstance } from "@colmeia/core/src/multi-media/multi-media-instance";
import { IHashSections } from "../handlers/profile-avatar-handler";
import { SessionService } from "./session.service";
import { RequestBuilderServices } from "./request-builder.services";
import { MultimediaService } from "./multimedia.service";
import { ServerCommunicationService } from "./server-communication.service";
import { SubscriptionInteractionInfoBuilder } from "./subscription-info.service";
import { MultimediaObject } from "@colmeia/core/src/multi-media/multi-media-object";
import { ClientInfraResponse, DefaulActionOnError, IInfraParameters } from "../model/client-infra-comm";
import { SpinType } from "./screen-spinner.service";
import { EChatBtnsVisualElements } from '@colmeia/core/src/core-constants/constant-visual-elements.enums';
import { BigIconService } from './big-icon.service';
import { AnnotationsModalHandler, EAnnotationsType, IAnnotationsModalParameters } from 'app/handlers/annotations-modal-handler';
import { EScreenGroups } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { AnnotationsModalComponent } from 'app/components/annotation/annotations-modal/annotations-modal.component';
import { ColmeiaDialogService } from './dialog/dialog.service';
import { isValidRef } from '@colmeia/core/src/tools/barrel-tools';
import { ReplaySubject, Subject } from 'rxjs';
import { PlayerInfoService } from 'app/services/player-info.service';
import { take } from 'rxjs/operators';

export interface ISlideToAvatar {
    avatarSelected: Avatar;
}

export interface IProfileAvatarImageDB {
    [idAvatar: string]: MultimediaInstance
}

export interface IProfileavatarSectionDb {
    [idAvatar: string]: IHashSections
}


@Injectable({
    providedIn: 'root'
})
export class ProfileAvatarStoreService {
    private avatarChangedSubject = new Subject<Avatar>();
    avatarChanged$ = this.avatarChangedSubject.asObservable();

    private avatarCache: Map<TGlobalUID, ReplaySubject<Avatar>> = new Map();

    constructor(
        private sessionSvc: SessionService,
        private rbs: RequestBuilderServices,
        private multimediaSvc: MultimediaService,
        private server: ServerCommunicationService,
        private requestBuilderSvc: RequestBuilderServices,
        private subscriptionsSVC: SubscriptionInteractionInfoBuilder,
        private bigIconSvc: BigIconService,
        private dialogSvc: ColmeiaDialogService,
        private playerSvc: PlayerInfoService,
    ) { }

    public async getAvatarFromServerIfNotOurs(idAvatar: TGlobalUID): Promise<Avatar> {
        if (UberCache.testCache(idAvatar))
            return Avatar.staticFactory(idAvatar)

        const query = [{
            serializableID: idAvatar,
            objectTypeID: constant.objectType.avatar
        }]


        const response: TSerializableHeaderResponse = await this.subscriptionsSVC.serializablesHeader(
            query
        )
        if (response && response[idAvatar]) {
            const avatarJson: IAvatarJSON = <IAvatarJSON>response[idAvatar];
            return Avatar.factoryMessage(avatarJson);
        }
    }

    public async getBestAvatarCached(idAvatar: TGlobalUID): Promise<Avatar> {
        let current = this.avatarCache.get(idAvatar);

        if (!current) {
            current = new ReplaySubject(1);
            this.avatarCache.set(idAvatar, current);

            this.getBestAvatar(idAvatar).then(avatar => {
                current.next(avatar);
            });
        }


        return current.asObservable().pipe(take(1)).toPromise();
    }

    public async getBestAvatar(idAvatar: TGlobalUID): Promise<Avatar> {
        const isMyAvatar = this.playerSvc.avatarBelongsToPlayer(idAvatar);

        if (!isMyAvatar) {
            return this.getAvatarFromServerIfNotOurs(idAvatar);
        } else {
            return this.sessionSvc.getPlayerAvatars().sort((x, y) => {
                return x.getClockTick() - y.getClockTick();
            })[0];
        }
    }

    async persistMultimedia(imageDB: IProfileAvatarImageDB, avatar: Avatar, isNewAvatarBeingEdit: boolean) {
        const mmObject = MultimediaObject.getNewMultimediaObject(
            ObjectType.staticFactory(constant.objectType.avatar),
            avatar.getPrimaryID()
        );
        mmObject.addMultimediaInstance(imageDB[avatar.getPrimaryID()]);
        const infraParameters: IInfraParameters = this.rbs.getNoCallBackSpinnningParameters(
            avatar.getParentEntityID(),
            avatar.getPrimaryID()
        );
        const isMediaUploadedSuccess: boolean = await this.multimediaSvc.uploadSerializableMedia(
            infraParameters,
            avatar,
            mmObject,
            this.sessionSvc.getPersonalGroup().getGroupID(),
            isNewAvatarBeingEdit
        );
        return { isMediaUploadedSuccess, mmObject }
    }

    broadcastAvatarChanged(avatar: Avatar) {
        this.avatarChangedSubject.next(avatar);
    }

    subscribeToAvatarChanges(callback: (avatar: Avatar) => void) {
        this.avatarChanged$.subscribe(callback);
    }

    public async saveMyAvatarProfileCallback(
        avatar: Avatar,
        imageDB: IProfileAvatarImageDB,
        isNewAvatarBeingEdit: boolean
    ): Promise<ICreateEditAvatarResponse> {
        let mmObject: MultimediaObject
        const isAddingUserImage = isValidRef(imageDB[avatar.getPrimaryID()]);
        const isRemovingUserImage = imageDB[avatar.getPrimaryID()] === null;

        if (isAddingUserImage) {
            mmObject = (await this.persistMultimedia(imageDB, avatar, isNewAvatarBeingEdit)).mmObject;
            avatar.setMultimediaObject(mmObject);
        } else if (isRemovingUserImage) {
            mmObject = MultimediaObject.getNewMultimediaObject(
                ObjectType.staticFactory(constant.objectType.avatar),
                avatar.getPrimaryID()
            );
            avatar.setMultimediaObject(mmObject);
        }

        const clientResponse: ClientInfraResponse = await this.sendAvatarToServer(avatar, isNewAvatarBeingEdit);
        return clientResponse
            ? (<ICreateEditAvatarResponse>clientResponse.response)
            : null
    }

    private async sendAvatarToServer(
        avatar: Avatar,
        isNewAvatarBeingEdit: boolean
    ): Promise<ClientInfraResponse> {
        const json: IAvatarJSON = avatar.toJSON();
        let requestType: string;

        if (isNewAvatarBeingEdit) {
            json.primaryID = null; // cria primaryID sequencial
            requestType = apiRequestType.avatar.createAvatar;
        } else {
            requestType = apiRequestType.avatar.editAvatar;
        }

        const parameter: IInfraParameters = {
            ...this.requestBuilderSvc.getSecureDefaultInfraParameters(),
            defaultActionOnError: DefaulActionOnError.standardErrorMessage,
            spinType: SpinType.fullScreen
        };

        const editingRequest: IEditAvatarRequest = {
            ...this.requestBuilderSvc.secureBasicRequest(requestType),
            avatar: json
        };

        const infraResponse = await this.server.managedRequest(parameter, editingRequest);
        console.log('sendAvatarToServer: ', infraResponse);
        return infraResponse;
    }

    public async editAvatarName(
        name: string,
    ): Promise<IResponse> {
        return await this.server.doRequest<IEditAvatarNameRequest>(apiRequestType.avatar.editAvatarName, {
            name
        });
    }

    private getSecuritySections(): TSerializableArray {
        return Serializable.getVisualElementFromScreenGroup(EScreenGroups.MainSectionGroup);
    }

    public getAvatarSecurityOptions(
        selectedAvatar: Avatar,
        sessionsDB: IProfileavatarSectionDb,
    ): IHashSections {
        const idAvatar: TGlobalUID = selectedAvatar.getAvatarID();
        let avtSections: IHashSections = sessionsDB[idAvatar];

        if (!avtSections) {
            const settings: SecuritySettings = selectedAvatar.getSecuritySettings();
            avtSections = {};

            for (const section of this.getSecuritySections()) {
                avtSections[section.getPrimaryID()] = {
                    groupScreenName: section,
                    securitySettings: []
                };
                for (const option of Serializable.getVisualElementFromScreenGroup(section.getPrimaryID())) {
                    const securityItem: SecurityItem = SecurityItem.getNewSecurityItem(option.getPrimaryID());
                    avtSections[section.getPrimaryID()].securitySettings.push(securityItem);
                    if (settings)
                        securityItem.setSelected(settings.isSecurityRequested(option.getPrimaryID()));
                };
            };
            sessionsDB[idAvatar] = avtSections;
        };
        return avtSections;
    };

    public handlerMoreOptionsSelect(option: Serializable, avatar: Avatar, markForCheckCallback: Function): void {
        const optionSelected = option.getPrimaryID();
        switch (optionSelected) {
            case EChatBtnsVisualElements.BigIconOptions:
                this.bigIconSvc.openFromSerializables(
                    [
                        Serializable.staticFactory(EChatBtnsVisualElements.Annotate)
                    ],
                    this.sessionSvc.getSelectedGroupID(),
                    (serializable: Serializable) => this.onBigIconOptionClick(avatar, serializable, markForCheckCallback),
                )
                break;
        }
    }

    private onBigIconOptionClick(avatar: Avatar, serializable: Serializable, markForCheckCallback: Function) {
        switch (serializable.getPrimaryID()) {
            case EChatBtnsVisualElements.Annotate:
                const annotationData: IAnnotationsModalParameters = {
                    type: EAnnotationsType.allSchemas,
                    persist: true,
                    serializableId: avatar.getPrimaryID(),
                    onSaveAnnotationCallback: () => {
                        avatar.setAnnotation(true);
                        markForCheckCallback();
                    },
                    clientCallback: undefined
                };
                this.openAnnotation(annotationData);
                break;
        }
    }

    public openAnnotation(annotationData: IAnnotationsModalParameters): void {
        this.dialogSvc.open<AnnotationsModalComponent, AnnotationsModalHandler>({
            componentRef: AnnotationsModalComponent,
            dataToComponent: { data: new AnnotationsModalHandler(annotationData) },
            hideHeader: true
        });
    }
}
