import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { BrowserModule, ɵDomSanitizerImpl } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TableauModule } from "ngx-tableau";
import { SwiperModule } from "swiper/angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppInitialize } from "./bootstrap/app-initialize";
import { UberCacheIntializer } from "./bootstrap/uber-initialiazer";
import { UpdatedDialogComponent } from "./components/dialogs/updated-dialog/updated-dialog.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { NotificationRedirectComponent } from "./components/notification-redirect/notification-redirect.component";
import { getPTBRPaginatorIntl } from "./intl/paginator.pt-br";
import { CustomIconsModule } from "./modules/custom-icons/custom-icons.module";
import { AuthGuard } from "./services/auth/auth-guard.service";
import { AuthInterceptorService } from "./services/auth/auth-interceptor.service";
import { AuthService } from "./services/auth/auth.service";
import { BackgroundSender } from "./services/background-sender";
import { BpmSaveHostedService } from "./services/bpm/bpm-save-hosted.service";
import { BpmService } from "./services/bpm/bpm.service";
import { ClientTransactService } from "./services/client.transact.service";
import { NavigatorServices } from "./services/controllers-services/navigator/navigator.service";
import { ToolBarServices } from "./services/controllers-services/tool-bar-controller/tool-bar.service";
import { FeatureStateEmissorService } from "./services/features-emissor.service";
import { GlobalErrorHandlerService } from "./services/global-error-handler.service";
import { GlobalWarningService } from "./services/global-warning.service";
import { GroupChatServices } from "./services/group-chat.services";
import { GroupPersistorServices } from "./services/group-persistor.services";
import { InitSystemService } from "./services/init-system.service";
import { InteractionPersistorServices } from "./services/interaction-persistor.service";
import { InteractionService } from "./services/interaction.service";
import { MultimediaService } from "./services/multimedia.service";
import { PlayerInfoService } from "./services/player-info.service";
import { PosProcessingInteractionService } from "./services/pos-processing-interaction.service";
import { QueuService } from "./services/queue.service";
import { RateLimitClientService } from "./services/rate-limit-client.service";
import { RecoverySystemService } from "./services/recovery-system.service";
import { RequestBuilderServices } from "./services/request-builder.services";
import { RequestClientCacheService } from "./services/request-cache.service";
import { RoutingService } from "./services/routing.service";
import { ScreenSpinnerService } from "./services/screen-spinner.service";
import { SearchResultService } from "./services/search-result.service";
import { SempaphoreService } from "./services/semaphore-service";
import { ServerCommunicationService } from "./services/server-communication.service";
import { SessionService } from "./services/session.service";
import { SignalListenerService } from "./services/signal/signal-listener";
import { SignalPublisherService } from "./services/signal/signal-publisher";
import { SocketFunctionsService } from "./services/socket-functions.service";
import { SocketService } from "./services/socket.service";
import { SusbcriptionContainerService } from "./services/subscriptions.service";
import { UniversalRehydrator } from "./services/universal-rehydrator";
import { UserActionService } from "./services/user-action.service";
import { VisualElementService } from "./services/visual-element.service";
import { WindowNavigationService } from "./services/window-navigation/window-navigation.service";
import { MaterialModule } from "./modules/material/material.module";
import { ColmeiaDialogService } from "./services/dialog/dialog.service";
import { HardwareLayerService } from "./services/hardware";
import { DeleteAccountComponent } from './components/delete-account/delete-account.component';
import { AttendanceChatSearchCharacters } from "./services/attendance-chat-search-characters.service";
import { HighlightMatchingStringCharacters } from "./services/highlight-matching-string-characters.service";
import { SharedEssentialsModule } from "./modules/shared-essentials/shared-essentials.module";
import { provideEnvironmentNgxMask } from "ngx-mask";
import { ColorThemeBuilderService } from "./services/color-theme-builder.service";
import { ThemeIntializer } from "app/bootstrap/theme-initializer";

registerLocaleData(localePt, "pt");

function prefersReducedMotion(): boolean {
    const mediaQueryList = window.matchMedia("(prefers-reduced-motion)");

    return mediaQueryList.matches;
}
/*
Este módulo deve conter apenas o que entra em contato nos primeiros movimentos da aplicação...
Autenticação (login/registro/esqueci a senha)
Group Home
Face Company
*/

@NgModule({
    declarations: [
        /* Views: */
        // MainHeaderViewComponent,
        AppComponent,
        /* Auth: */
        // UpdatedDialogComponent,
        NotificationRedirectComponent,

        // LoadingComponent,
        DeleteAccountComponent,
        // PrivacyPolicyComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule.withConfig({
            disableAnimations: prefersReducedMotion(),
        }),
        HttpClientModule,
        // SharedEssentialsModule,
        MaterialModule,

        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        CustomIconsModule,
        TableauModule,
        SwiperModule,
        SharedEssentialsModule,
    ],
    providers: [
        InitSystemService,
        AuthService,
        UserActionService,
        VisualElementService,
        ServerCommunicationService,
        RoutingService,
        SessionService,
        SocketFunctionsService,
        SocketService,
        PlayerInfoService,
        NavigatorServices,
        QueuService,
        SusbcriptionContainerService,
        ToolBarServices,
        SearchResultService,
        GlobalWarningService,
        PosProcessingInteractionService,
        ScreenSpinnerService,
        ClientTransactService,
        InteractionPersistorServices,
        MultimediaService,
        InteractionService,
        SignalListenerService,
        SignalPublisherService,
        FeatureStateEmissorService,
        RequestBuilderServices,
        UniversalRehydrator,
        GroupPersistorServices,
        RecoverySystemService,
        GroupChatServices,
        SempaphoreService,
        AttendanceChatSearchCharacters,
        HighlightMatchingStringCharacters,
        ColorThemeBuilderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        ɵDomSanitizerImpl,
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        AuthGuard,
        /* Uber and bootstrap: */
        UberCacheIntializer,
        ThemeIntializer,
        {
            provide: APP_INITIALIZER,
            useFactory: AppInitialize,
            deps: [UberCacheIntializer, ThemeIntializer],
            multi: true,
        },
        BpmService,
        BpmSaveHostedService,
        BackgroundSender,
        { provide: MatPaginatorIntl, useValue: getPTBRPaginatorIntl() },
        { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
        WindowNavigationService,
        RateLimitClientService,
        RequestClientCacheService,
        HardwareLayerService,
        ColmeiaDialogService,
        provideEnvironmentNgxMask(),
    ],
    bootstrap: [AppComponent],
    exports: [
        SwiperModule,
    ],
})
export class AppModule { }
