import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ICRMTicketDataWithCustomerName } from "@colmeia/core/src/shared-business-rules/crm/crm-entities";
import { CRMTicketsService } from "../crm-tickets.service";
import { Reactify } from "app/reactify/reactify";

@Reactify.This()
@Component({
    selector: 'app-crm-ticket-summary',
    styleUrl: './crm-ticket-summary.component.scss',
    templateUrl: './crm-ticket-summary.component.html',
})
export class CRMTicketSummaryComponent implements OnInit, OnChanges {

    @Input()
    ticketData: ICRMTicketDataWithCustomerName;


    @Input()
    id_ticket: string;

    constructor(
        private ticketSvc: CRMTicketsService,
    ) {

    }

    ngOnInit() {
        this.ticketData ||= this.ticketSvc.getTicket(this.id_ticket);

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ticketData ||= this.ticketSvc.getTicket(this.id_ticket);
    }

    public openTicket(event: Event) {
        event.stopPropagation(); event.preventDefault();
        this.ticketSvc.openTicket(this.ticketData.idTicket);
    }
}