import { EMessageReplicateType, EThreadType, ETimedInteractionTypeID, EWrapperStatus } from '../business/constant.enums';
import { TDeliveryTargets, TGlobalUID } from '../core-constants/types';
import { EBotEventType } from '../shared-business-rules/bot/bot-event-model';
import { TOmniChannelArray } from '../shared-business-rules/campaigns/campaing-comm-strategy';
import { IFieldMapper } from '../shared-business-rules/metadata/meta-engagement';
import { ENonSerializableObjectType } from '../shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '../shared-business-rules/non-serializable-id/non-serializable-types';
import { ICoordinates, ILocationCoordinates } from '../tools/geo-util';
import { ValueOfListWithPreservedOrder } from '../tools/utility/types/validate';
import { IPublicMediumPresentable } from "./billing-interfaces";
import { IAvatarJSON, IGroupJSON, IInteractionFeedbackDriver, IParticipantJSON, IUniversalJSON, TArrayIDJSON, TDriverFeedbackArray, TGroupGenealogy } from './business-interfaces';
import { IFeedbackFigures } from './feedback-interfaces';
import { IBillOptions } from './finance-interfaces';
import { EConversationStatusCC } from './service-group-interfaces';

export type IInteractionJSONArray = Array<IInteractionJSON>;
export type IChainedInteractionJSONArray = Array<IChainedInteractionJSON>;
export type IComparedObjectJSONArray = Array<IComparedObjectJSON>;
export type TIConfigurationItemJSONArray = Array<IConfigurationItemJSON>;
export type TIFeedbackInteractionJSONArray = Array<IFeedbackInteractionJSON>;
export type TIDriverInteractionJSONArray = Array<IDriverInteractionJSON>;
export type TIReplicatedInteractionJSONArray = Array<IShareInteractionJSON>;
export type TArrayDeleteInteractionJSON = Array<IDeleteInteractionJSON>;
export type TArrayGranteeInteractionJSON = Array<IGranteeInteractionJSON>;
export type TITreeInteractionJSONArray = Array<ITreeInteractionJSON>;



export type TReferencedSerializableJSON = IInteractionJSON | IGroupJSON | IAvatarJSON;
export type TPersonalResponseArray = Array<IPersonalResponse>;
export type TPublishGroupArray = Array<IPublishGroup>;
export type TThreadInfoArray = Array<IThreadInfo>;
export type TITurnGpsOnJSONArray = Array<ITurnGpsOnJSON>;

export type TIEnableRealtimeMonitoringJSONArray = Array<IEnableRealtimeMonitoring>;



export enum EGoPermissionType {
    role = 1,
    permission,
}

export interface IGoPermissionType {
    permissionType: EGoPermissionType;
    idPermissionRole: TGlobalUID;
};

export type TGoPermissionArray = Array<IGoPermissionType>;


export interface IThreadInfo {
    idThread: TGlobalUID;
    threadName: string;
    threadType: EThreadType;
    idGeneralResponse: TGlobalUID;
};

export interface IShareInteractionJSON extends IChainedInteractionJSON {
    idGroupDestiny: TGlobalUID;
    interactionArray: IInteractionJSONArray;
};



export interface IShareAsFeature {
    usePermissions: TGoPermissionArray;
    goPermissions: TGoPermissionArray;
    driverFeedbacks: TDriverFeedbackArray;
    timedType: ETimedInteractionTypeID;
    whenOcurrs: number;
    idPayable: TGlobalUID;
};


export interface IPublishGroup {
    idPublishingGroup: TGlobalUID,
    idGroup: TGlobalUID,
    replicate: boolean;
    replicateType: EMessageReplicateType;
    genealogy: TGroupGenealogy;
    idDemandingInteraction: TGlobalUID;
    idInteraction?: TGlobalUID;
    featureShared?: IShareAsFeature;
};

export interface IPublishGroupForSave {
    idPublishingGroup: TGlobalUID,
    idGroup: TGlobalUID,
    replicate: boolean;
    replicateType: EMessageReplicateType;
    idDemandingInteraction: TGlobalUID;
    idInteraction: TGlobalUID;
    idInteractionType: string;
    idAvatar: string;
    idParticipant: string;
}

export interface IPersonalResponse {
    idAvatarArray: TArrayIDJSON;
    idGroup: TGlobalUID;
    goPermissions: TGoPermissionArray;
    idInteraction?: TGlobalUID;
};

export interface IFeedbackInformation {
    solved: boolean;
    points: number;
}

export interface ITimed {
    timedType: ETimedInteractionTypeID;
    whenOcurrs: number;
    warnMeBefore: number;
    registering: boolean;
    personalParticipant: IParticipantJSON;
};


export type TIDueDateAppointmentArray = Array<IDueDateAppointment>;

export interface IDueDateAppointment {
    idGroup: TGlobalUID;
    idAvatarArray: TArrayIDJSON;
};

export enum EWindowWhatsapp {
    standard = 24,
    mkt = 72
}


export enum EWindowOpenConversationType {
    Authentication = 'AUTHENTICATION',
    Marketing = 'MARKETING',
    Utility = 'UTILITY',
    CustomerInitiated = 'CUSTOMERINITIADED', // Freeform
    // TODO CHEGAR COM O ZE
    Transactional = 'TRANSACTIONAL',
}

export enum EDistributionEngagement {
    engageWarn = 'engageWarn',
    engageFeed = 'engageFeed',
    engageGroupW = 'engageGroupW',
    engageTeamW = 'engageTeamW',
};


/* Please reflect changes on allSelectableDeliveryAction and onlySingleMessageInMassComm */
export enum EDelivery360Action {
    Delivery360WhatsApp = 'WhatsApp',
    Delivery360SMS = 'SMS',
    Delivery360RCS = 'RCS',
    Delivery360FBMessenger = 'FBMessenger',
    DeliveryInstagram = 'Instagram',
    Delivery360Email = 'Email',
    Delivery360Voice = 'Phone',
    DeliveryColmeia = 'colmeia',
    DeliveryTelegram = 'Telegram',
};

// para adicionar um item como selecionavel tem que criar um serializable com primary_id igual ao valor do enum, contento icone (18) e nome (1).

export const allSelectableDeliveryAction: ValueOfListWithPreservedOrder<typeof EDelivery360Action> = [
    EDelivery360Action.Delivery360WhatsApp,
    EDelivery360Action.Delivery360SMS,
    EDelivery360Action.Delivery360RCS,
    EDelivery360Action.Delivery360FBMessenger,
    EDelivery360Action.DeliveryInstagram,
    EDelivery360Action.Delivery360Email,
    EDelivery360Action.Delivery360Voice,
    EDelivery360Action.DeliveryColmeia,
    EDelivery360Action.DeliveryTelegram,
];

export const onlySingleMessageInMassComm: EDelivery360Action[] = [
    EDelivery360Action.Delivery360Email,
    EDelivery360Action.Delivery360Voice,
    EDelivery360Action.Delivery360SMS,
    EDelivery360Action.Delivery360RCS,
];

export type TEDelivery360ActionArray = EDelivery360Action[];


export interface IInteractionDeliveryOptions {
    schedule?: TOmniChannelArray;
    distributionEngagement: EDistributionEngagement;
    delivery360Options: TEDelivery360ActionArray;
};


export interface ICitedAvatar {
    idAvatar: TGlobalUID;
    name: string;
};

export type TCitedAvatarArray = Array<ICitedAvatar>;


export interface IReadReceiveData {
    read: boolean; // todos leram
    rec: boolean; // todos receberam
    yr: boolean; // vc leu
}

export interface IReadSendMSGControl {
    [idGroup: string]: IReadReceiveData;
}

export interface IWebChatConfig {
    descriptor?: IFieldMapper[]
}


export interface IForceBind {
    idGroup: TGlobalUID;
    idPlayer: TGlobalUID;
    idAvatar: TGlobalUID;
}

export interface IInteractionJSON extends IUniversalJSON {
    idGroup: TGlobalUID,
    idParticipant: TGlobalUID,
    idInteractionType: TGlobalUID,
    idInteractionParent?: TGlobalUID,
    participant: IParticipantJSON,
    idInteractionParentArray: TArrayIDJSON,
    generatedByIDPlayer?: TGlobalUID,
    generatedByIDAvatar?: TGlobalUID,
    dateDeleted?: number,
    publishingGroups: TPublishGroupArray,
    personalResponse?: TPersonalResponseArray,
    driverFeedbacks?: TDriverFeedbackArray;
    timedConfig?: ITimed,
    appointment?: TIDueDateAppointmentArray;
    feedbackFigures?: IFeedbackFigures;
    delivery?: IInteractionDeliveryOptions;
    threadInfo?: TThreadInfoArray;
    // Estão aqui por causa da busca do Datastore
    // Tem que estar desnormalizados
    isFeedback: boolean;
    billOptions?: IBillOptions;
    isTypeFeedback: boolean;
    coordinates?: ILocationCoordinates;
    sentCoordinates?: ICoordinates;
    toSocketAvatarID?: TGlobalUID;
    isSendableFeedback: boolean; // principalmente para feedback no DS mas pode ser expandido
    citations?: TCitedAvatarArray;
    forceBind?: IForceBind; // utilizada para forçar um bindKey (idPlayer)

    // gerado através do Hook
    e360Provider?: TDeliveryTargets;
    rrCtrl?: IReadSendMSGControl; //read receive control
    idHandShake?: string;
    interlocutor?: EConversationStatusCC;
    isAttendantPersonal?: boolean;
    webChatConfig?: IWebChatConfig;
    generatedByBotEvent?: EBotEventType; // define se foi uma mensagem automatica do bot enviada no nome do atendente
    sentType?: EInteractionSentType;
    islandEventsId?: IdDep<ENonSerializableObjectType.customEvents>
};

export enum EInteractionSentType {
    onQueue = 'oQ',
}

export interface IFirstFormFeature {
    idSchema: string;
    idAnswer: string;

}

export interface IFeatureCarrierInteractioJSON extends IInteractionJSON {
    data: IFirstFormFeature;
}

export interface IDriverInteractionJSON extends IInteractionJSON, IInteractionFeedbackDriver { };





export interface IConfigurationItemJSON extends IInteractionJSON {
    configurationType: TGlobalUID;
};

export interface IConfigurationCarrierJSON extends IInteractionJSON {
    configurationItems: IInteractionJSONArray
}


export interface ITreeInteractionJSON extends IChainedInteractionJSON {
    isTreeNode: boolean;
};



export interface IInviteHeaderJSON extends IInteractionJSON {
    nameFrom: string,
    expireDate: Date,
};



export interface IQueueStatusJSON extends IUniversalJSON {
    allocated: boolean;
    finalState: boolean;
};


export type IResourceSoliciationRejectedJSON = IChainedInteractionJSON;



export interface IThreadJSON extends IFeedbackInteractionJSON { };

export interface ISetSpokeAvatarJSON extends IInteractionJSON {
    idSpokeAvatar: TGlobalUID;
    avatar?: IAvatarJSON;
};



export interface IGrantSpokePersonStatusJSON extends IInteractionJSON {
    idGranteeParticipant: TGlobalUID;
    spokePersonParticipant?: IParticipantJSON;
};



export interface IFeedbackInteractionJSON extends IChainedInteractionJSON { };



export interface IGroupSecurityItemJSON extends IInteractionJSON {
    idSecuritySettings: TGlobalUID,
    idSecurityDomain: TGlobalUID,
};


export interface IStartPublishGroupJSON extends IInteractionJSON {
    fromGroup: TGlobalUID;
    toGroup: TGlobalUID;
};


export interface IStopPublishGroupJSON extends IDeleteInteractionJSON {
    idStopingMergeToGroup: TGlobalUID;
};

export interface ITurnGpsOffJSON extends IDeleteInteractionJSON {
};

export interface ITurnGpsOnJSON extends IInteractionJSON {
    surrogate?: string;
};

export interface IRealTimeMonitoring {
    nameAvatar: string;
    idTrackedMediaAvatar: string;
    idTrackedAvatar: TGlobalUID;
}

export interface IEnableRealtimeMonitoring extends IInteractionJSON, IRealTimeMonitoring {
    enabledGPS: boolean;
    autoExpireOn: number
};

export interface IDisableRealtimeMonitoring extends IChainedInteractionJSON, IRealTimeMonitoring {
};


export interface IDeleteInteractionJSON extends IChainedInteractionJSON { };
export interface iGroupRevoqueSecurityItemJSON extends IDeleteInteractionJSON { };

export interface IChainedInteractionJSON extends IInteractionJSON {
    idInteractionParent: TGlobalUID;
    idInteractionTypeParent: TGlobalUID;
    idMessageProvider?: string;
    idFeedback: TGlobalUID;
    feedback: IFeedbackInformation;
    treePos: number; // node/leaf
};



export interface IComparedObjectJSON extends IChainedInteractionJSON {
    ulr: string;
};


export interface IGranteeInteractionJSON extends IChainedInteractionJSON {
    idRole: TGlobalUID,
};

export interface IRevokeRoleInteractionJSON extends IDeleteInteractionJSON {
    onBehalfOfAvatar?: TGlobalUID;
};


export interface INewParticipantInteractionJSON extends IInteractionJSON { };

export interface INewGroupInteractionJSON extends IInteractionJSON { };

export interface IGrantorInteractionJSON extends IInteractionJSON { };


export interface IObjectFieldConfiguration {
    obligatory: boolean;
    numberOfCharacters: number;
    optionsAllowed: number
    isMultipleLineEditor: boolean;
    command: TGlobalUID;
    multiValueField: number;
    filesCount: number
};


export interface IBookmarkJSON extends IChainedInteractionJSON {
    serializableBookmarked: TGlobalUID;
};

export interface ISubmissionStatusJSON extends IInteractionJSON {
};


export interface IResourceCharacteristicFeedbackJSON extends IFeedbackInteractionJSON {
    idResource: TGlobalUID,
}



export interface ISolicitationCharacteristicFeedbackJSON extends IFeedbackInteractionJSON {
    idSolicitation: TGlobalUID,
    askedQuantity: number,
}



// Paylaoded Interaction

// export interface IConditionalTree {
//     conditions: TChoiceConditionArray;
// };


export interface IPaymentMediumInteractionJSON extends IInteractionJSON {
    creditCardNumber: string;
    creditCardBrand: string;
    creditCardHolderName: string;
    creditCardExpiry: string;
    creditCardCVC: string;
    creditCardDocument: string;
};

export interface IPaymentCustomerInteractionJSON extends IInteractionJSON {
    idCustomerGatewayID: string;
};

export interface IPaymentSourceInteractionJSON extends IChainedInteractionJSON {
    idPaymentMedium: TGlobalUID;
};

export interface IPurchaseConfirmationInteractionJSON extends IChainedInteractionJSON {
    medium: IPublicMediumPresentable;
}

export interface IBIllableInteractionJSON extends IChainedInteractionJSON { };


export interface IWrapperInteractionJSON extends IInteractionJSON {
    wrapperType: ETimedInteractionTypeID;
    wrapperStatus: EWrapperStatus;
    wrappedText: string;
    idWrappedInteraction: TGlobalUID;
    wrappedGroup: IWrappedSimpleUniversalJSON;
    wrappedAvatar?: IWrappedSimpleUniversalJSON;
    wrappedInteraction?: IInteractionJSON;
}

export interface IWrappedSimpleUniversalJSON {
    idObjectType: TGlobalUID,
    primaryID: TGlobalUID,
    name: string,
    idMedia: string,
}
