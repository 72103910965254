import { getBlankNonSerializableHeader, initNserClient, initNserClientNoCheck } from "../../../nser/blank-ns";
import { apiRequestType } from "../../../request-interfaces/message-types";
import { getReadableUniqueID, getUniqueStringID } from "../../../tools/utility";
import { emptyMetaEngagement } from "../../bot/engagement-function";
import { ENonSerializableObjectType } from "../../non-serializable-id/non-serializable-id-interfaces";
import { ECRMTicketActionTriggerType } from "./crm-bpm-action.model";
import { ECRMCanonicalItemsTypes } from "./crm-config-canonical-model";
import { ICRMDatabaseManifest, ICRMProjectServer, TCRMConfigHeaderNsTypes, TCRMConfigHeaderServer, TCRMConfigItemServer, TCRMConfigItemsNsTypes, TCRMConfigNSTypes } from "./crm-config-ds-model";

import { ECRMAgentAttributeUpdateControl, ICRMAgentAttributeUpdateForm, ICRMAgentAttributeUpdateItem, ICRMAttributePackageBase, ICRMProjectAttributeConfig } from './crm-config.model';

const crmConfigParentNsTypes: ENonSerializableObjectType[] = [
  ENonSerializableObjectType.crmHeaderCloseState,
  ENonSerializableObjectType.crmHeaderPhase,
  ENonSerializableObjectType.crmHeaderSeverity,
  ENonSerializableObjectType.crmHeaderState,
  ENonSerializableObjectType.crmHeaderSupportLevel,
  ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel,
];

export function isCRMConfigParent(nsType: ENonSerializableObjectType): boolean {
  return crmConfigParentNsTypes.includes(nsType);
}


export function getCRMConfigListRequestType<NsType extends TCRMConfigNSTypes>(nsType: NsType): typeof apiRequestType.dashboardExtra.crm.config[NsType]['list'] {
  return apiRequestType.dashboardExtra.crm.config[nsType].list;
}


export class InitCRMConfig {
  public static item(idParent: string, name: string, nsType: TCRMConfigItemsNsTypes): TCRMConfigItemServer {
    const ns: TCRMConfigItemServer = {
          ...getBlankNonSerializableHeader(name, false),
          nsType,
          idParent,
    } as TCRMConfigItemServer;
    return ns as TCRMConfigItemServer;
  }
  public static header(name: string, nsType: TCRMConfigHeaderNsTypes): TCRMConfigHeaderServer {
    const ns: TCRMConfigHeaderServer = {
          ...getBlankNonSerializableHeader(name, false),
          nsType,
    } as TCRMConfigHeaderServer;
    return ns as TCRMConfigHeaderServer;
  }
  public static package(name: string): ICRMProjectServer {
    const ns: ICRMProjectServer = initNserClient(ENonSerializableObjectType.crmConfigPackage, name, {
      supportingDB: {},
      projectKey: "",
      actions: [],
      closeState: getCrmProjectAttributePackageConfig(),
      phase: getCrmProjectAttributePackageConfig(),
      severity: getCrmProjectAttributePackageConfig(),
      ticketState: getCrmProjectAttributePackageConfig(),
      supportLevel: getCrmProjectAttributePackageConfig(),
      urgencyLevel: getCrmProjectAttributePackageConfig()
    });
      return ns;
  }

  public static packageSupportingDB(): ICRMDatabaseManifest {
      return {
          idDatabaseManifest: getUniqueStringID(10),
          idRTF: undefined
      }
  }
}

const crmConfigItemToHeaderMap: Record<TCRMConfigHeaderNsTypes, TCRMConfigItemsNsTypes> = {
  [ENonSerializableObjectType.crmHeaderCloseState]: ENonSerializableObjectType.crmItemCloseState,
  [ENonSerializableObjectType.crmHeaderPhase]: ENonSerializableObjectType.crmItemPhase,
  [ENonSerializableObjectType.crmHeaderSeverity]: ENonSerializableObjectType.crmItemSeverity,
  [ENonSerializableObjectType.crmHeaderState]: ENonSerializableObjectType.crmItemState,
  [ENonSerializableObjectType.crmHeaderSupportLevel]: ENonSerializableObjectType.crmItemSupportLevel,
  [ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel]: ENonSerializableObjectType.crmItemUrgencyLevel,
} 

export function getItemConfigNSTypeByHeader(nsType: TCRMConfigHeaderNsTypes): TCRMConfigItemsNsTypes {
  return crmConfigItemToHeaderMap[nsType];
} 


export function getCrmPackageObject<T extends ICRMAttributePackageBase>(headerPackageId: string, name: string, type: ECRMCanonicalItemsTypes): T {
  return {
    headerPackageId,
    name,
    type,
    items: [],
  } as T
}

export function getCrmProjectAttributePackageConfig(): ICRMProjectAttributeConfig {
  return {
    id: undefined,
    updateConfig: {
      form: getCrmAgentAttributeUpdateForm(),
      items: {},
    }
  }
}

export function getCrmAgentAttributeUpdateForm(): ICRMAgentAttributeUpdateForm {
  return {
    register: {
      idMetadata: undefined,
      engagement: emptyMetaEngagement()
    },
    control: ECRMAgentAttributeUpdateControl.free,
  }
}

export function getCRMProjectAttributeItemConfig(): ICRMAgentAttributeUpdateItem {
  return {
    form: getCrmAgentAttributeUpdateForm(),
    conditions: [],
  };
}
