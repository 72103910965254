import { EColmeiaColorPallete, EColorVariant, EShapeStyle, IIconConfig, ITagConfig } from "../brand-theme/theme-types";

export interface ITicketAttrThemeConfig extends ITagConfig, IIconConfig {
}

export function getDefaultTicketAttrItemTheme(override: Partial<ITicketAttrThemeConfig> = {}): ITicketAttrThemeConfig {
    return {
        tagStyle: EShapeStyle.Flat,
        color: EColmeiaColorPallete.primary,
        colorVariant: EColorVariant.default,
        ...override,
    }
}
