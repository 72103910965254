import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ICRMTicketDataWithCustomerName } from "@colmeia/core/src/shared-business-rules/crm/crm-entities";
import { CRMTicketsService } from "../crm-tickets.service";
import { Reactify } from "app/reactify/reactify";


@Reactify.This()
@Component({
    selector: 'app-crm-ticket-protocol-view',
    styleUrl: './crm-ticket-protocol-view.component.scss',
    templateUrl: './crm-ticket-protocol-view.component.html',
})
export class CRMTicketProtocolComponent implements OnInit, OnChanges {
    
    @Input()
    ticketData: ICRMTicketDataWithCustomerName;
    
    @Input()
    id_ticket: string;

    protocol: string;

    @Input()
    size: 'small' | 'medium' | 'large' = 'medium'

    constructor(
        private ticketSvc: CRMTicketsService,
    ) { }

    ngOnInit(): void {
        this.generateProtocol();

    }

    ngOnChanges(): void {
        this.generateProtocol();
    }

    private generateProtocol() {
        if (this.id_ticket) {
            this.ticketData = this.ticketSvc.getTicket(this.id_ticket)
        }

        this.protocol = `${this.ticketData?.projectKey}-${this.ticketData?.protocol}`;
    }
}