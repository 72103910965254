import { EBPMTicketAgentAction } from "../crm/crm-services/crm-bpm-action.model";
import { EBPMAction, EBPMActionTriggerType, TAllBPMACtion } from "./bpm-action-model";
import { EBPMMarketingActionType } from "./marketing/bpm-marketing.model";


interface IBPMActionConfigItem {
    hideConditions?: boolean;
    hasTriggerType?: boolean;
    canControlTriggerType?: boolean;
    defaultTriggerType?: EBPMActionTriggerType;
}


type TDbControlMeta = { [key in TAllBPMACtion]: IBPMActionConfigItem };


export const dbControlBPM: TDbControlMeta = {
    [EBPMAction.action]: {},
    [EBPMAction.validator]: {},
    [EBPMAction.conditionalDisplay]: {},
    [EBPMAction.mktFilter]: {},
    [EBPMAction.anomization]: {},
    [EBPMAction.getTitularData]: {},
    [EBPMAction.optOut]: {},
    [EBPMAction.optIn]: {},
    [EBPMAction.solicitation]: {},
    [EBPMTicketAgentAction.Notification]: {
        hasTriggerType: true,
        defaultTriggerType: EBPMActionTriggerType.System,
    },
    [EBPMTicketAgentAction.executeFunction]: {
        hideConditions: true,
        hasTriggerType: true,
    },
    [EBPMTicketAgentAction.Assign]: {
        hideConditions: true,
    },
    [EBPMTicketAgentAction.CloseTicket]: {
        hideConditions: true,
    },
    [EBPMTicketAgentAction.SendCampaign]: {
        defaultTriggerType: EBPMActionTriggerType.User,
    },
    [EBPMMarketingActionType.messageWithCampaing]: {},
    [EBPMMarketingActionType.goCampaignWithBot]: {},
}

export function getBPMConfig(bpmActionType: TAllBPMACtion): IBPMActionConfigItem {
    return dbControlBPM[bpmActionType];
}
