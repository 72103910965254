import { $string, SetAsCaseInsensitive, UnionToIntersection } from "../../tools/utility-types";
import { StringNumber } from "../../tools/utility/types/basic";
import { IBotActionAsset } from "../bot/bot-action-model";
import { IContentBasicAsset } from "../bot/bot-content-model";
import { TRCSMarketingAction } from "../bot/new-bot-action";
import { ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";
import { EAdvancedTemplateOption } from "./social-media.model";

export interface IRCSQuickReplyAsset extends IBotActionAsset {
    type: TRCSMarketingAction;
    idElement?: IdDep<ENonSerializableObjectType.bot | ENonSerializableObjectType.serviceIsland>;
}

export interface IRCSQuickReplyOption {
    buttonText: string;
    buttonData?: string; // phoneNumber, url ...
    buttonDataContent?: IContentBasicAsset;
    action: IRCSQuickReplyAsset;
};

export type TRCSQuickReplyOptionArray = Array<IRCSQuickReplyOption>;

export interface IRCSAdvancesTemplateAction {
  type?: EAdvancedTemplateOption;
  quickReplyOptions: TRCSQuickReplyOptionArray;
}

export namespace RCS {
    export namespace Template {
        export enum Category {
            // carousel = 'carousel',
            card = 'card',
            text = 'text',
        }

        export enum MessageType {
            basic = 'basic',
            single = 'single',
        }

        export enum ButtonType {
            PhoneNumber = 'PHONE_NUMBER',
            URL = 'URL',
            Message = 'MESSAGE',
            Calendar = 'CALENDAR',
        }



        export enum Language {
            PortugueseBR = "pt_BR",
            PortuguesePOR = "pt_PT",
            EnglishUS = "en_US",
            Afrikaans = "af",
            Albanian = "sq",
            Arabic = "ar",
            Azerbaijani = "az",
            Bengali = "bn",
            Bulgarian = "bg",
            Catalan = "ca",
            ChineseCHN = "zh_CN",
            ChineseHKG = "zh_HK",
            ChineseTAI = "zh_TW",
            Croatian = "hr",
            Czech = "cs",
            Danish = "da",
            Dutch = "nl",
            English = "en",
            EnglishUK = "en_GB",
            Estonian = "et",
            Filipino = "fil",
            Finnish = "fi",
            French = "fr",
            Georgian = "ka",
            German = "de",
            Greek = "el",
            Gujarati = "gu",
            Hausa = "ha",
            Hebrew = "he",
            Hindi = "hi",
            Hungarian = "hu",
            Indonesian = "id",
            Irish = "ga",
            Italian = "it",
            Japanese = "ja",
            Kannada = "kn",
            Kazakh = "kk",
            Kinyarwanda = "rw_RW",
            Korean = "ko",
            KyrgyzKyrgyzstan = "ky_KG",
            Lao = "lo",
            Latvian = "lv",
            Lithuanian = "lt",
            Macedonian = "mk",
            Malay = "ms",
            Malayalam = "ml",
            Marathi = "mr",
            Norwegian = "nb",
            Persian = "fa",
            Polish = "pl",
            Punjabi = "pa",
            Romanian = "ro",
            Russian = "ru",
            Serbian = "sr",
            Slovak = "sk",
            Slovenian = "sl",
            Spanish = "es",
            SpanishARG = "es_AR",
            SpanishSPA = "es_ES",
            SpanishMEX = "es_MX",
            Swahili = "sw",
            Swedish = "sv",
            Tamil = "ta",
            Telugu = "te",
            Thai = "th",
            Turkish = "tr",
            Ukrainian = "uk",
            Urdu = "ur",
            Uzbek = "uz",
            Vietnamese = "vi",
            Zulu = "zu",
        }
    }

    export namespace Message {
        export type TargetMedia = {
            id?: string;
            link?: string;
        };

        export interface Media extends TargetMedia {
            caption?: string;
            filename?: string;
        }

        export interface Document extends Media {
            link: string;
            caption?: string;
            filename?: string;
        }

        export interface Image extends Media {
            // id: string;
            caption?: string;
        }

        export interface Video extends Media {
            // id: string;
            caption?: string;
        }

        export interface Contact {
            phones?: Phone[];
            urls?: URL[];
        }

        export interface Phone {
            phone: $string | StringNumber;
            type: string;
            wa_id?: string;
        }

        export namespace Interactive {
            export enum Type {
                Button = 'button',
                CallToActionURL = 'cta_url',
            }
        }

        export interface Body {
            text: string;
        }

        export type HeaderMedia = Partial<UnionToIntersection<RCS.Message.TargetMedia>> & RCS.Message.Media;

        export interface Header {
            type: SetAsCaseInsensitive<`${RCS.Message.Template.Structure.Format}`>;
            text: string;
            document?: HeaderMedia;
            image?: HeaderMedia;
            video?: HeaderMedia;
        }

        export interface Interactive {
            type: Interactive.Type;
            header?: Header;
            body: Body;
            action: string;
        }

        export enum Type {
            Contact = "contacts",
            Document = "document",
            Image = "image",
            Template = "template",
            Text = "text",
            Video = "video",
            Interactive = "interactive",
        }

        export interface SendTemplateComponent {
            template?: Template;
            location?: Location;
            contacts?: Contact[];

            text?: Text;

            interactive?: Interactive;

            image?: Image;
            document?: Document;
            video?: Video;
        }

        export namespace Template {
            export namespace Structure {
                export interface Component {
                    type: ComponentType;
                    text?: string;
                    format?: Format;
                    buttons?: Button[];
                    example?: {
                        header_handle?: string[];
                        header_text?: string[];
                        body_text?: string[][];
                        header_url?: string[];
                    };
                    // add_security_recommendation?: boolean; // Authentication Template Only
                    // code_expiration_minutes?: number; // Authentication Template Only
                }

                export interface Button {
                    type: RCS.Template.ButtonType;
                    text?: string;
                    url?: string;
                    example?: string; // [https://www.website.com/dynamic-url-example]
                    otp_type?: "copy_code";
                    phone_number?: string;
                }

                export enum Format {
                    Text = "text",
                    Document = "document",
                    Video = "video",
                    Image = "image",
                }

                export enum ComponentType {
                    Body = "body",
                    Buttons = "buttons",
                    Footer = "footer",
                    Header = "header",
                    Lto = "limited_time_offer"
                }
            }

            export namespace Component {
                export enum Type {
                    Header = 'header',
                    Body = 'body',
                    Footer = 'footer',
                    Button = 'button',
                    Lto = "limited_time_offer"
                }

                export interface Parameter {
                    type: RCS.Message.Type;
                    /**
                     * Required for quick_reply buttons.
                     * Developer-defined payload that is returned when the button is clicked in addition to the display text on the button.
                     */
                    payload?: string;
                    /**
                     * Required for URL buttons.
                     * Developer-provided suffix that is appended to the predefined prefix URL in the template.
                     */
                    text?: string;
                    image?: TargetMedia;
                    document?: Document;
                    video?: TargetMedia;
                }
            }

            export interface Component {
                type: Component.Type;
                parameters?: Component.Parameter[];
            }
        }


        export interface Template {
            /**
             * The name of the template
             */
            name: string;
            /**
             * Contains a language object. Specifies the language the template may be rendered in.
             * Only the deterministic language policy works with media template messages.
             */
            // language: Template.Language;
            components?: Template.Component[];
        }

        export namespace Component {

        }
    }
}
