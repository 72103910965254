import { over } from "lodash";
import { EConfirmationType, TArrayID, TNserUID, TNserUIDArray } from "../../core-constants/types";
import { getReadableUniqueID, isEntities, isInEnum, isInvalid, isThisOneOfThat, isValidArray, isValidRef, isValidString, typedClone } from "../../tools/utility";
import { isValidAssetAction } from "../bot/asset-functions";
import { initialAction } from "../bot/bot-action-model";
import { IBotMenuItem, IBotRoot } from "../bot/bot-model";
import { isValidBasicCondition } from "../bot/conditional-functions";
import { EBotActionType } from "../bot/new-bot-action";
import { BasicElement, TBasicElementArray } from "../graph/essential/basic-element";
import { IBasicElementClient, TIBasicElementClientArray, TIBasicElementServerArray } from "../graph/essential/graph-basic-element-interfaces";
import { IBasicElementJSON, IGraphElementJSON, IPredicateElementJSON, TBasicElementJSONArray } from "../graph/essential/graph-interfaces";
import { EGraphElementType } from "../graph/essential/graph-types";
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";
import { EBPMAction, IBPMActionAsset, IBPMActionModel, IBPMConditionalDisplay, IBPMLGPDActionOnBotAction, IBPMValidatorAction, IBPMValidatorActionOnError, IMultipleNestedFormConfig } from "./bpm-action-model";
import { getBPMConfig } from "./bpm-control-db";
import { EBPMType, IBPConditionalEvaluator, TEBPMTypeArray, TIBPConditionalEvaluatorArray, subscriberToBPMTypeMap } from "./bpm-model";
import { ESubscribedTo, IBPMBaseNodeServer, TBPMBusinessEventTypes } from "./common/common-bpm";
import { hasBPMTimerNodeConfig } from "./common/common-bpm-functions";
import { EBPMMarketingActionType } from "./marketing/bpm-marketing.model";
import { EBPMTicketAgentAction } from "../crm/crm-services/crm-bpm-action.model";

export interface IBPMEvaluatorValidatorOptions {
    /**
     * default: 1
     */
    minEvaluators?: number;
    validatorErrorMessageIsOptional?: boolean;
}

export function isValidBPMEvaluators(evaluators: TIBPConditionalEvaluatorArray, options: IBPMEvaluatorValidatorOptions = {}): boolean {
    return isValidArray(evaluators, options.minEvaluators) && evaluators.every((e) => { return isValidBPM(e, options) });
}

export function isValidBPM(bpm: IBPConditionalEvaluator, options: IBPMEvaluatorValidatorOptions = {}): boolean {
    const isNotValid = isInvalid(bpm);
    const isNotValidCondition = getBPMConfig(bpm.action.bpmAction)?.hideConditions === true ? false : !isValidCondition(bpm);
    const isNotValidBPMAction = !isValidBPMAction(bpm.action, options);

    if (isNotValid || isNotValidCondition || isNotValidBPMAction) {
        return false;
    }

    for (const cond of bpm.matchConditions) {
        if (!isValidBasicCondition(cond, bpm.action.bpmAction)) {
            return false;
        }
    };
    return true;
}
function isValidCondition(bpm: IBPConditionalEvaluator): boolean {
    return isValidArray(bpm.matchConditions) ||
        (bpm.action.bpmAction === EBPMAction.conditionalDisplay && (<IBPMConditionalDisplay>bpm.action).doNotDisplay)
}

export function isValidBPMAction(bpmAction: IBPMActionModel, options: IBPMEvaluatorValidatorOptions = {}): boolean {

    if (isInvalid(bpmAction)) {
        return false;
    }
    switch (bpmAction.bpmAction) {
        case EBPMAction.action: {
            const action = <IBPMActionAsset>bpmAction;
            return isValidAssetAction(action.action);
        };
        case EBPMAction.validator: {
            const action = <IBPMValidatorAction>bpmAction;

            return options?.validatorErrorMessageIsOptional ? true : isValidArray(action.onError);

        };

        case EBPMAction.mktFilter: {
            return true;
        };

        case EBPMAction.conditionalDisplay: {
            const action = <IBPMConditionalDisplay>bpmAction;
            return true;
        };
        case EBPMAction.mktFilter: return true;


        case EBPMAction.anomization:
        case EBPMAction.optIn:
        case EBPMAction.optOut:
        case EBPMAction.getTitularData: {
            const action = <IBPMLGPDActionOnBotAction>bpmAction;
            return isValidRef(action.actionStyle)
        };

        case EBPMMarketingActionType.messageWithCampaing: return true;
        case EBPMMarketingActionType.goCampaignWithBot: return true;
        case EBPMTicketAgentAction.SendCampaign: return true;
    }
}

export function getIDsFromEvaluators(evaluator: TIBPConditionalEvaluatorArray): TArrayID {
    const idNSs: TArrayID = [];
    for (const eva of evaluator) {
        for (const cond of eva.matchConditions) {
            if (isValidString(cond.idInformationSource)) {
                idNSs.push(cond.idInformationSource);
            }
        }
    }
    return idNSs;

}

export function isExternalElement(element: IBasicElementJSON): boolean {
    return (<IGraphElementJSON>element).isExternalElement
}

export function isElementRootFromGraph(element: IBasicElementJSON): boolean {
    return element.elementType === EGraphElementType.root && !isExternalElement(element)
}

export function getGraphExternalElementList(elements: TIBasicElementServerArray): TIBasicElementServerArray {
    return elements.filter(e => isExternalElement(e.element))
}

export function getRootFromElements(elements: TBasicElementJSONArray): IBasicElementJSON {
    return elements.find((e) => { return isElementRootFromGraph(e) })
}

export function getGraphElementByHostedID(idHostedID: string, elements: TBasicElementJSONArray): IBasicElementJSON {
    return elements.find((e) => { return e.idHostedObject === idHostedID })
}

export function basicElementInstanceToBasicElementClient(elements: TBasicElementArray, idGraphRoot?: string) {
    const nodes: TIBasicElementClientArray = elements.map((e: BasicElement) => {
        const node: IBasicElementClient = {
            nsType: ENonSerializableObjectType.graphElement,
            nName: e.getName(),
            element: e.toJSON(),
        }

        if (!e.isRoot()) {
            node.idParent = idGraphRoot;
        }
        return node
    })

    return nodes
}
export function bpmGetTransactionListFromNser(allExternalElementsAndChildrenBots: INonSerializableHeader[]) {
    return allExternalElementsAndChildrenBots
        .filter(nser => (nser as IBotRoot).firstAction?.type == EBotActionType.contentGenerator
            || (nser as IBotMenuItem).action?.type == EBotActionType.contentGenerator)
        .map(action => (action as IBotRoot).firstAction?.idElement || (action as IBotMenuItem).action?.idElement)
}

export function getAllHostedIdFromNodesThatIPoint(
    idGraphNodeTarget: IdDep<ENonSerializableObjectType.graphElement>,
    graphNodeList: TIBasicElementServerArray,
    idNodeGraphToIdHostedMap: Record<TNserUID, TNserUID>
): TNserUIDArray {
    return graphNodeList
        .filter(graphNode => graphNode.element.elementType == EGraphElementType.predicate
            && (<IPredicateElementJSON>graphNode.element).fromElementId == idGraphNodeTarget)
        .map(graphNode => idNodeGraphToIdHostedMap[(<IPredicateElementJSON>graphNode.element).toElementId])
        .filter(result => isValidRef(result))
}

export function isAssetNestedForm(asset: IBPMActionAsset): boolean {
    return asset?.action?.type === EBotActionType.contentGenerator
}


export function hasMultipleFormController(asset: IBPMActionAsset): boolean {
    return isAssetNestedForm(asset) && asset.isMultipleFormConfig && isValidRef(asset.multipleCallsConfig)
};



export const isBPMActionModel = isEntities<IBPMActionAsset | IBPMValidatorAction | IBPMConditionalDisplay, IBPMActionModel>();

export function getNewMultipleCallsConfig(): IMultipleNestedFormConfig {
    return {
        title: [],
        yesQuestion: [],
        noQuestion: []
    }
}

export function isConditionalLessBPM(bpmNodeNS: IBPMBaseNodeServer): boolean {
    return hasBPMTimerNodeConfig(bpmNodeNS);
}

export function getEmptyActionOnErrorsConfig(override?: Partial<IBPMValidatorActionOnError>): IBPMValidatorActionOnError {
    return { errorsCount: 0, action: typedClone(initialAction), ...override };
}

export function getBPMTypeBySubscriber(bpmNSItem: INonSerializable): EBPMType {
    return subscriberToBPMTypeMap[bpmNSItem.nsType];
}

export function isBPMOfType(nsType: string, ...bpmType: TEBPMTypeArray): boolean {
    return (isThisOneOfThat(subscriberToBPMTypeMap[nsType], ...bpmType))
}

export function isMarketingEvent(evt: TBPMBusinessEventTypes): boolean {
    return isInEnum(EConfirmationType, evt);
}

export function isBPMEventLocked(event: TBPMBusinessEventTypes): boolean {
    return  [ESubscribedTo.attRegistry, ESubscribedTo.contentGenerator].includes(event as ESubscribedTo);
}


export function getEmptyBpmAction<T extends IBPMActionModel>(override?: Partial<T>): T {
    return {
        actionTriggerType: getBPMConfig(override?.bpmAction)?.defaultTriggerType,
        bpmAction: override?.bpmAction,
        bpmActionId: getReadableUniqueID(8),
        ...override,
    } as T;
}