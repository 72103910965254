import { EDelivery360Action } from '../../comm-interfaces/barrel-comm-interfaces';
import { I360Media } from '../../core-constants/bot';
import { TGlobalUID } from '../../core-constants/types';
import { ENonSerializableObjectType } from '../non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '../non-serializable-id/non-serializable-types';
import { IBPMCRMConditionalEvaluator } from './crm-services/crm-bpm-action.model';
import { ICRMTicketActionRuntimeInfo } from "./crm-services/crm-bpm-action.model";


export interface ICRMStep {
    idPhase: IdDep<ENonSerializableObjectType.crmItemPhase>;
    idSeverity: IdDep<ENonSerializableObjectType.crmItemSeverity>;
    idTicketState: IdDep<ENonSerializableObjectType.crmItemState>;
    idCloseState: IdDep<ENonSerializableObjectType.crmItemCloseState>;
    idSupportLevel: IdDep<ENonSerializableObjectType.crmItemSupportLevel>;
    idUrgencyLevel: IdDep<ENonSerializableObjectType.crmItemUrgencyLevel>;
}

export interface ICRMStatusNames {
    phaseName?: string;
    severityName?: string;
    ticketStateName?: string;
    closeStateName?: string;
    supportLevelName?: string;
    urgencyLevelName?: string;
}

export interface ICRMCTicketStatusInteractionInsert extends Partial<ICRMStep> { }

export type TIBPMCRMConditionalEvaluatorArray = Array<IBPMCRMConditionalEvaluator>;

export interface ICRMTicketDataBasic {
    idTicket: string;
    title: string;
    idSocialContext: string;
    channel?: EDelivery360Action;
    idIsland: string;
    idSocialKey?: TGlobalUID;
    idAvatarCustomer?: TGlobalUID;
    idAvatarAgent: TGlobalUID;
    clockTick: number;
    lastClockTick?: number;
    currentStatus: ICRMStep;
    currentStatusNames?: ICRMStatusNames;
    protocol: number;
    lastComment?: string;
    lastIdMedia?: string;
    idPackage: string;
    projectKey: string;
}

export type TICRMTicketDataBasicArray = Array<ICRMTicketDataBasic>

export interface ICRMTicketDataWithCustomerName extends ICRMTicketDataBasic {
    customerName: string;
}

export type TICRMTicketItems = Array<ICRMTicketDataWithCustomerName>;


export enum ETicketInteractionType {
    content = 'ctt',
    status = 'sts',
    allocation = 'alloc',
    formAnswer = 'frmAnsr',
    queue = 'q'
}

export interface ITicketInteractionBase {
    idTicket: string;
    idInteraction: string;
    interactionType: ETicketInteractionType;
    clockTick: number;
    idAvatarAuthor: TGlobalUID;
    idSocialKey: TGlobalUID;
    idChat?: string;
    idConversation?: string;
    isAgentInteraction: boolean
}

export interface ITicketInteractionStatusBase {
    idSeverityItem: TGlobalUID;
    idTicketStateItem: TGlobalUID;
    idSupportLevelItem: TGlobalUID;
    idPhaseItem: TGlobalUID;
    idCloseStateItem: TGlobalUID;
    idUrgencyLevelItem: TGlobalUID;
    isCurrent: boolean;
}

export interface ITicketInteractionStatus extends ITicketInteractionBase, ITicketInteractionStatusBase {
    interactionType: ETicketInteractionType.status;
}

export interface ITicketInteractionContentBase {
    comment?: string;
    idMedias?: TGlobalUID[];
    i306Medias?: I360Media[];
}

export interface ITicketInteractionContent extends ITicketInteractionBase, ITicketInteractionContentBase {
    interactionType: ETicketInteractionType.content;
}

export interface ITicketInteractionAllocationBase {
    idIsland: TGlobalUID;
    idAvatarAgentSuggested?: TGlobalUID;
    idAvatarAgent: TGlobalUID;
    isTransfer: boolean;
}

export interface ITicketInteractionAllocation extends ITicketInteractionBase, ITicketInteractionAllocationBase {
    interactionType: ETicketInteractionType.allocation;
}

export interface ITicketInteractionFormAnswerBase {
    idAnswer: TGlobalUID;
}

export interface ITicketInteractionFormAnswer extends ITicketInteractionBase, ITicketInteractionFormAnswerBase {
    interactionType: ETicketInteractionType.formAnswer;
}

export interface ITicketInteractionQueueBase {
    idQueue: TGlobalUID;
    idIsland: TGlobalUID;
    isTransfer: boolean;
    idAvatarAgentSuggested?: TGlobalUID;
}

export interface ITicketInteractionQueue extends ITicketInteractionBase, ITicketInteractionQueueBase {
    interactionType: ETicketInteractionType.queue;
}

export type TTicketInteraction = ITicketInteractionStatus | ITicketInteractionContent | ITicketInteractionAllocation | ITicketInteractionFormAnswer | ITicketInteractionQueue;
export type TTicketInteractionAllData = ITicketInteractionBase & Partial<ITicketInteractionStatusBase & ITicketInteractionContentBase & ITicketInteractionAllocationBase & ITicketInteractionFormAnswerBase & ITicketInteractionQueueBase>;
export type TTicketInteracitonArray = Array<TTicketInteraction>;

export interface ITicketAttachedInfo {
    idTicket: string;
    idProject?: string;
    actionData?: ICRMTicketActionRuntimeInfo;
}


export interface ICRMInteractionTypeToInterface {
    [ETicketInteractionType.content]: ITicketInteractionContent;
    [ETicketInteractionType.status]: ITicketInteractionStatus;
    [ETicketInteractionType.formAnswer]: ITicketInteractionFormAnswer;
    [ETicketInteractionType.allocation]: ITicketInteractionAllocation;
    [ETicketInteractionType.queue]: ITicketInteractionQueue;
}