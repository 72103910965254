import { Injectable } from '@angular/core';
import { EUnitTypeID } from '@colmeia/core/src/business/constant.enums';
import { TSchemaPropertyArray } from '@colmeia/core/src/general-form/general-form-interface';
import { KBAssetType } from '@colmeia/core/src/shared-business-rules/bot/bot-asset-model';
import { EBotContentEvent } from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import { EBPMAction, IBPMValidatorAction } from '@colmeia/core/src/shared-business-rules/BPM/bpm-action-model';
import { INewMetaEngagement, EMetadataEngagementType, EMetaEngagementConditionType } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { GeneralFormEvaluator } from '@colmeia/core/src/general-form/general-form-evaluator';

@Injectable({
    providedIn: 'root'
})
export class NewMainHeaderService {

    constructor() { }

    execTests() {
        const metadata: TSchemaPropertyArray = [
            {
                "propertyName": "nome",
                "prompt": "nome",
                "choices": [
                    {
                        "text": ""
                    }
                ],
                "idUnity": EUnitTypeID.stringType,
                "idLocalCanonical": "CbXbKza8eyA4Km7cmIKx8uxerX70bx2N",
                "idProperty": "Wilq3LAC4o",
            },
            {
                "prompt": "email",
                "propertyName": "email",
                "choices": [
                    {
                        "text": ""
                    }
                ],
                "idLocalCanonical": "FlgErV7axE5so906JtH4ZSKgdWmfUmjY",
                "nestedSchema": [
                    {
                        "propertyName": "",
                        "prompt": "",
                        "idProperty": "C2Uf7i7r2J",
                        "idUnity": EUnitTypeID.stringType,
                    }
                ],
                "idUnity": EUnitTypeID.stringType,
                "idProperty": "hbxKfYvsFf",
            },
            {
                "propertyName": "telefone",
                "idProperty": "JCneObVXdT",
                "prompt": "telefone",
                "idLocalCanonical": "tgrEW43Kd038DUMrH9WpN7Hx2AkINxH3",
                "idUnity": EUnitTypeID.stringType,
            }
        ]
        const action: IBPMValidatorAction = {
            bpmActionId: "",
            "bpmAction": EBPMAction.validator,
            "onError": [
                {
                    "media": {
                        "mymeType": null,
                        "idMedia": null,
                        "idMediaType": null,
                        "size": null
                    },
                    "variablesTemplate": {
                        "variables": [],
                        "compiledTemplate": "erro no maior que 5",
                        "messageIfNoBind": ""
                    },
                    "botContentType": EBotContentEvent.mainContent,
                    "idAsset": "NN1RqM6hp7",
                    "content": "erro no maior que 5",
                    "type": KBAssetType.content
                }
            ],
            "onSuccess": []
        }
        const engagement: INewMetaEngagement = {
            "idMetaEngagement": '',
            "engagementConfig": {},
            "descriptor": [
                {
                    "idFieldMapper": "TG7KRZNVn6zgKM1",
                    "idFieldForm": "Wilq3LAC4o",
                    "engagementType": EMetadataEngagementType.NewCondition,
                    "evaluators": [
                        {
                            "matchConditions": [
                                {
                                    "sourceInfo": undefined,
                                    "reverseCondition": false,
                                    "condition": "5",
                                    "metaConditionType": EMetaEngagementConditionType.greater,
                                    "idConditionalAsset": "SBuEfRI0GsVVRy9c"
                                }
                            ],
                            action,
                            "stopAction": false,
                            "name": "validacao"
                        }
                    ]
                }
            ],
            "elementsOrder": [
                "Wilq3LAC4o",
                "hbxKfYvsFf",
                "JCneObVXdT"
            ]
        }

        // const evaluator = GeneralFormEvaluator.create({ metadata, engagement })
        // const changedIdProperty = metadata[0].idProperty

        // console.log({ result: evaluator.validateFormField(changedIdProperty, "5") });
    }
}
