import { IRequest } from "../../request-interfaces/request-interfaces";
import { IResponse } from "../../request-interfaces/response-interfaces";


export enum EEmbeddedSignupRequests {
    exchangeCodeForToken = 'es.exchangeCodeForToken',
}

export interface IEmbeddedSignupExchangeCodeForTokenRequest extends IRequest {
    code: string;
}

export interface IEmbeddedSignupExchangeCodeForTokenResponse extends IResponse {
    access_token: string;
}

