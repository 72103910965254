import { IdField, typedScreenOptions } from "@colmeia/core/src/tools/barrel-tools";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { suggestions } from "@colmeia/core/src/tools/type-utils";

export const rcsSchemaToValidateRCSTemplate = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.RcsTemplateValidationError,
    fields: suggestions<{ [key in string]: IdField}>()({
        namePattern: 1,  // O nome só pode conter caracteres alfanuméricos minúsculos e sublinhados
        templateMax: 2, // O conteúdo do template é muito grande
    })
} as const));

export const rcsAdvancedTemplateActionErrors = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.RcsAdvancedTemplateOptions,
    fields: suggestions<{ [key in string]: IdField }>()({
        quickReply: 1, // Resposta rápida
        invalidTemplate: 2, // Template inválido
        invalidButtonPhoneNumber: 3, // Botão com telefone inválido
        invalidButtonURL: 4, // Botão com URL inválida
        invalidButtonType: 5, // Tipos de botões inválidos
        allowMaxFourButtonsOnTemplate: 6, // Só é possível acrescentar até 4 botões
        invalidButtonGrouping: 7, // Os tipos de botões devem estar agrupados e não misturados
        allowOnlyDifferentTypesOnCallToAction: 8, // Só é possível acrescentar botões de tipos distintos na chamada para ação
    })
} as const));
