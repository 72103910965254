import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { IBotActionAsset } from '../../bot/bot-action-model';
import { IBPMActionModel } from '../../BPM/bpm-action-model';
import { IBPConditionalEvaluator, TIBPConditionalEvaluatorArray } from '../../BPM/bpm-model';
import { ECampaignExecutionType } from '../../campaigns/capaing-exec-interfaces';

// Colocar esses tipos no EBPMAction
export enum EBPMTicketAgentAction {
    Notification = 'crm.Notification',
    executeFunction = 'crmaExecuteFunction',

    // legacy, to be used, translation already created
    Assign = 'crmaAssign',
    CloseTicket = 'crmaClose',

    SendCampaign = 'crm.agt.sendCampaign', // pega campanha com um bot associado
}

export enum ECRMTicketActionTriggerType {
    byAgent = 'byAgent',
    byProject = 'byProject'
}

export interface ICRMTicketActionActionOnReturn {
    enabled: boolean;
    conditions: TIBPConditionalEvaluatorArray;
    botAction: IBotActionAsset;
}

export interface IBPMCRMActionModel extends IBPMActionModel {
    bpmAction: EBPMTicketAgentAction

    triggerType: ECRMTicketActionTriggerType;
    triggerConditions: TIBPConditionalEvaluatorArray;
}

export interface ICRMBPMActionAgentSendCampaign extends IBPMCRMActionModel {
    bpmAction: EBPMTicketAgentAction.SendCampaign,
    campaigns: TICRMTicketActionFireCampaignConfigArray
    actionOnReturn?: ICRMTicketActionActionOnReturn;
}


export interface ICRMAssignation extends IBPMCRMActionModel {
    bpmAction: EBPMTicketAgentAction.Assign,
    idIsland: string;
}

export interface ICRMBPMActionNotification extends IBPMCRMActionModel {
    bpmAction: EBPMTicketAgentAction.Notification;
    idCampaignAction: string;
};

export interface ICRMExecuteFunction extends IBPMCRMActionModel {
    bpmAction: EBPMTicketAgentAction.executeFunction;
    idFunction: string;
}

export interface ICRMTicketActionRuntimeInfo {
    bpmActionId: string;
}

export interface ICRMTicketActionFireCampaignConfig {
    enabled: boolean;
    label: string;
    type: ECampaignExecutionType;
    idElement: TGlobalUID; // pode ser um id de ação de campanha ou régua de mkt
}

export type TICRMTicketActionFireCampaignConfigArray = Array<ICRMTicketActionFireCampaignConfig>;

export type TBPMCRMAction = ICRMBPMActionAgentSendCampaign | ICRMExecuteFunction;
// COMMON DAQUI PARA BAIXP

export interface IBPMCRMConditionalEvaluator extends IBPConditionalEvaluator {
    action: TBPMCRMAction;
}
