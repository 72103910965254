import { values } from "@colmeia/core/src/tools/utility";
import { isInEnumFactory } from "@colmeia/core/src/tools/utility/functions/is-in-enum-factory";

export const intSupportGroupsFeatureID = 'intSupportGroups';
export const seeAllGroupsConstant = 'seeAllGroups';
export const mainHeaderChat = 'maiHeader/chat';
export const groupChatRightSideBarsId = 'sideBarLeft.constants';
export const chatMessageEdit = 'chatMessage.edit';
export const canAddFormFieds = 'canAddFormFields';
export const feedbackDialog = 'feedbackdlg';
export const deleteInteraction = 'deleteInteractionConfirmation';
export const advSettingSharingToOutside = 'sessionShareByGroup';
export const advSettingSharingFromOutside = 'sessionShareWithGroup';
export const advSettingsParticipationRulesLabel = 'createGroup/participationRules';
export const advSettingsValidatedByRulesLabel = 'createGroup/validatedByRules';
export const advSettingsContentPrivacyLabel = 'createGroup/contentPrivacy';
export const advSettingsPermissionRulesLabel = 'createGroup/permissionRules';
export const advSettingsAccessGroupRulesLabel = 'createGroup/accessGroupRules';
export const datepicker = 'datepicker';
export const realTimeGPSTracking = 'realTimeGPSTracking';
export const ShareAsFeatureUsePermissions = 'usePermissions';

export enum ESNManagement {
    seeGroups = 'SNManagement/seeGroups',
    removeUser = 'SNManagement/removeUser',
    removeGroup = 'SNManagement/removeGroup',
};


export const createGroupScreenGroups = {
    rootScreen: 'createGroup',
    // CUSTOMIZATION - Top level
    customizationTitle: 'createGroup/customization',
    customizationGroupPhotoLabel: 'createGroup/groupPhoto',
    customizationGroupBannerLabel: 'createGroup/groupBanner',
    customizationGroupBackgroundLabel: 'createGroup/groupBackground',
    participantsAddNewParticipantLabel: 'createGroup/addNewParticipant',
    // ADVANCED SETTINGS - Top level
    advSettingsTitle: 'createGroup/advancedSettings',
    advSettingsRulesLabel: 'createGroup/rules',
    // ADVANCED SETTINGS - Children - Participação
    advSettingsParticipationRulesLabel: 'createGroup/participationRules',
    // ADVANCED SETTINGS - Children - Apenas avatares autenticados por
    advSettingsValidatedByRulesLabel: 'createGroup/validatedByRules',
    // ADVANCED SETTINGS - Children - Acesso
    advSettingsAccessRulesLabel: 'createGroup/accessRules',
    advSettingsAccessGroupRulesLabel: 'createGroup/accessGroupRules',
    // ADVANCED SETTINGS - Children - Permissão
    advSettingsPermissionRulesLabel: 'createGroup/permissionRules',
    // ADVANCED SETTINGS - Children - Permite fusão de grupo
    advSettingsAllowDistributionListRulesLabel: 'createGroup/allowDistributionList',
    // ADVANCED SETTINGS - Top level
    advSettingsPrivacyLabel: 'createGroup/privacy',
    // ADVANCED SETTINGS - Children - Conteúdo
    advSettingsContentPrivacyLabel: 'createGroup/contentPrivacy',
    // ADVANCED SETTINGS - Children - Chat
    advSettingsChatPrivacyLabel: 'createGroup/chatPrivacy',
    advSettingsChatConfirmReceivedPrivacyLabel: 'createGroup/chatConfirmReceivedPrivacy',
    // ADVANCED SETTINGS - SHARING
    advSettingsSharing: 'createGroup/sharing',
    advSettingSharingToOutside: 'sessionShareByGroup',
    advSettingSharingFromOutside: 'sessionShareWithGroup',
    // Recertification Politics
    // recertificationPolitics: 'recertificationPolitics'
};

export const afterBefore = {
    after: 'afterBefore.after',
    before: 'afterBefore.before',
    reset: 'afterBefore.reset',
};

export enum EScreenGroups {
    SmartFlow = "dashboard/smart-flow",
    CRMServices = 'dashboard/crm-services',
    BindSocialMedia = 'BindSocialMedia',
    Friendship = 'avatarPrivacyOptions.friendship',
    PrivateMessage = 'avatarPrivacyOptions.privateMessage',
    TeamMembership = 'avatarPrivacyOptions.teamMembership',
    MainSectionGroup = 'allAvatarPrivacyOptions',
    SearchBar = 'searchBar',
    SearchBarScope = 'scopeSearch',
    Environments = 'environmentssc',
    YourColmeia = 'dashboard/updateProviderStatus',
    Ai = 'dashboard/ai',
    MenuSN = 'dashboard/menu-sn',
    Marketing = 'dashboard/marketing',
    Communication = 'dashboard/communication',
    Colmeia = 'dashboard/colmeia',
    Service = 'dashboard/service',
    MenuDashboard = 'menu-dashboard',
    Features = 'features',
    UpdateProviderStatus = 'dashboard/updateProviderStatus',
    AfterBefore = 'afterBefore',
    Navigation = 'navigation',
    Jobs = 'dashboard/jobs',
    Analytics = 'analytics',
    AnalyticsHistory = 'AnalyticsHistory',
    AppbarShortcuts = "appbar/shortcuts",
    ConversationalCommerce = 'ConversationalCommerce',
    Security = 'Security',
    Tools = 'Tools',
    MarketingPerformance = 'dashboard/MarketingPerformance',
}

export const hasScreenGroup = isInEnumFactory(EScreenGroups)

export enum EMainHeaderOptions {
    logout = 'player/logout',
    notifications = 'notifications/view',
    settings = 'player/settings',
    messages = 'player/messages'
}

export const menuDashboardConfigurations = {
    serializableId: 'menu-dashboard/configurations',
    screenGroup: 'menu-dashboard/configurations',
};

export enum EMenuDashboardCRM {
    serializableId = 'menu-dashboard/crm',
    screenGroup = 'menu-dashboard/crm',
}

export enum EMenuDashboardAnalytics {
    serializableId = 'menu-dashboard/analytics',
    screenGroup = 'menu-dashboard/analytics',
}

export enum EDefineDashboard {
    marketing = 'SNManagement/campaigns',
    omnisense = 'artificialIntelligence',
    attendance = 'updateProviderStatus',
    services = 'SNManagement/deployedServices',
    communication = 'SNManagement/socialMedias',
    environments = 'environmentCategory',
    socialNetwork = 'menuSN',
    jobs = 'Jobs',
    CRMServices = 'CRMServices',
    analytics = 'analytics',
    analyticsHistory = 'AnalyticsHistory',
    conversationalCommerce = 'ConversationalCommerce',
    colmeia = 'dashboard-colmeia',
    smartFlow = 'dashboard-smartFlow',
    security = 'Security',
    tools = 'Tools',
    marketingPerformance = 'marketing-performance',
}



export enum EProfileItemClick {
    bindSocialMedia = 'bindSocialMedia',
    logout = 'player/logout',
    notifications = 'notifications/view',
    settings = 'player/settings',
    messages = 'player/messages',
    profile = 'player/myProfile',
    about = 'player/about',
    rootGroup = 'rootGroup',
    createSN = 'tool/createSN'
}

export enum ESNConfigClick {
    tags = 'NonSerializableTags',
    users = 'SNManagement/users',
    kb = 'artificialIntelligence'
}

export enum EYourColmeiaClick {
    updateServiceStatus = 'updateProviderStatus'
}

export enum EChatMesageScreenGroup {
    ChatMessage = 'chatMessage',
}

export enum EInteractiveButtons {
    Yes = 'interactive_yes',
    No = 'interactive_no',
    Continue = 'interactive_continue',
    Cancel = 'interactive_cancel'
}

export type TInteractiveButtons = EInteractiveButtons[];

export enum kbPredictItems {
    data = 'data',
    intents = 'intents',
    synonyms = 'synonyms',
    patterns = 'patterns'
}

export enum kbExtractItems {
    entities = 'entities'
}

export enum ENavigatorFilter {
    where = 'globalNavigator.where',
    attention = 'globalNavigator.attention',
    popularity = 'globalNavigator.popularity',
    followUp = 'globalNavigator.answers',
}

export enum EGroupNavigation {
    screenGroup = 'navigation',
    groups = 'searchBar.groups',
    analytics = 'group.analytics',
    about = 'navigation.about',
    whatYouWantSee = 'naviagation.whatDoYouWantSee',
    attention = 'globalNavigator.attention',
    popularity = 'globalNavigator.popularity',
    followUp = 'globalNavigator.answers',
    where = 'globalNavigator.where',
    follow = 'follow.button',
    createParticipantOnFreeGroup = 'createParticipantOnFreeGroup',
    options = 'groupTagsButton',
    createGroup = 'createSubgroup'
}

export enum EGroupType {
    Corporate = 'features/corportate',
    Tribe = 'features/tribe',
    MassCommunication = 'features/bulkInvite',
    Broadcaster = 'features/broadcaster',
    Channel = 'features/channel',
    Distribution = 'features/distribution',
    NewFeature = 'features/newFeature',
    NewInventory = 'inventory',
    Votation = 'features/votation',
    SpokePerson = 'features/spokePerson',
    SubGroup = 'features/subgroup',
    NewAnonymous = 'features/anonymous',
    SubGroupEdit = 'features/subgroup/edit',
    Options = 'groupTagsButton'
}

export const AppBarShortcuts = {
    JobsStatus: 'features/jobs-status',
    Corporate: EGroupType.Corporate,
    Tribe: EGroupType.Tribe
}

export enum EClientContext {
    Authentication = 'authentication',
    Chat = 'chat',
    Dashboard = 'dashboard',
    GroupHome = 'group-home',
    ChatEmbedded = 'chat-embedded',
    Widget = 'Widget'
}
