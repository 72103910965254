import { Component, Input } from "@angular/core";
import { IIconConfig } from "@colmeia/core/src/shared-business-rules/brand-theme/theme-types";

@Component({
    selector: "app-icon",
    styles: [`:host { display: inline-flex; align-items: center; }`],
    template: `
        <ng-container *ngIf='matIcon'>
            <mat-icon class="mat-{{size}}">{{ matIcon }}</mat-icon>
        </ng-container>

        <ng-container *ngIf='utf8Icon'>
            <span class="cm-utf-8-icon">{{ utf8Icon }}</span>
        </ng-container>

        <ng-container *ngIf='svgIcon'>
            <mat-icon class="mat-{{size}}" [svgIcon]="svgIcon"></mat-icon>
        </ng-container>
    `,
})
export class AppIconComponent {
    @Input() matIcon: string;
    @Input() utf8Icon: string;
    @Input() svgIcon: string;
    @Input() size: number = 24;

    @Input()
    config: IIconConfig;

    ngOnInit() {
        this.defineIconByConfig();
    }

    ngOnChanges() {
        this.defineIconByConfig();
    }

    private defineIconByConfig() {
        if (this.config) {
            this.matIcon = this.config.matIcon;
            this.utf8Icon = this.config.utf8Icon;
            this.svgIcon = this.config.customIcon;
        }
    }
}