
export type TColmeiaExtraPallete = 'info' | 'success' | 'alert';
export type TColmeiaPallete = 'primary' | 'accent' | 'warn' | TColmeiaExtraPallete;

export enum EColorVariant {
    default = 'default',
    v100 = '100',
    v200 = '200',
    v300 = '300',
    v400 = '400',
    v500 = '500',
    v600 = '600',
    v700 = '700',
    v800 = '800',
    v900 = '900',
    A100 = 'A100',
    A200 = 'A200',
    A400 = 'A400',
    A700 = 'A700',
}

export enum EColmeiaColorPallete {
    primary = 'primary',
    accent = 'accent',
    info = 'info',
    success = 'success',
    alert = 'alert',
    warn = 'warn',
}

export enum EShapeStyle {
    Basic = 'basic',
    Raised = 'raised',
    Stroked = 'stroked',
    Flat = 'flat',
    Light = 'light',
}

export enum EButtonStyle {
    Basic = 'basic',
    Raised = 'raised',
    Stroked = 'stroked',
    Flat = 'flat',
    Icon = 'icon',
    FAB = 'fab',
    miniFAB = 'miniFAB',
    ExtendedFAB = 'extendedFAB',
}

export enum EFormFieldStyle {
    Fill = 'fill',
    Outline = 'outline',
}

export interface IIconConfig {
    matIcon?: string;
    /**
     * Svg icons, should be added on src/app/utils/custom-svg-icons.ts
     */
    customIcon?: string;
    utf8Icon?: string;
    size?: number; // default = 24 => 24x24
}

export interface IColorConfig {
    color: EColmeiaColorPallete;
    colorVariant?: EColorVariant;
    invertColor?: boolean;
}

export interface IButtonConfig extends IColorConfig {
    buttonStyle: EButtonStyle;
}

export interface ITagConfig extends IColorConfig {
    tagStyle: EShapeStyle;
}