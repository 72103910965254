import { IRequest } from "../../request-interfaces/request-interfaces";
import { IResponse } from "../../request-interfaces/response-interfaces";
import { EEntityPickerSelector, IEntityPickerItem } from "./entity-picker.types";


export enum EEntityPickerRequests {
    getEntities = 'ep.getEntities',
    getEntity = 'ep.getEntity',
}

export interface IEntityPickerGetEntitiesRequest extends IRequest {
    selector: EEntityPickerSelector,
}

export interface IEntityPickerGetEntitiesResponse extends IResponse {
    entities: IEntityPickerItem[],
}

export interface IEntityPickerGetEntityRequest extends IRequest {
    selector: EEntityPickerSelector,
    id: string,
}

export interface IEntityPickerGetEntityResponse extends IResponse {
    entity: IEntityPickerItem,
}
