import {
    Component,
    Input,
    OnInit
} from "@angular/core";
import { ITicketAttrThemeConfig } from "@colmeia/core/src/shared-business-rules/crm/crm-ticket-theme.model";
import { CRMTicketDesignService } from "app/crm-service-tickets-view/crm-ticket-design.service";
import { Reactify } from "app/reactify/reactify";
import { CRMTicketsService } from "../crm-tickets.service";

@Reactify.This()
@Component({
    selector: "app-crm-ticket-attr-view",
    styles: [`
        :host { display: inline-block; }
        app-crm-ticket-attr-icon-view { margin-right: 4px; }
    `],
    template: `
        <app-tag *ngIf="name && themeConfig" [config]="themeConfig">
            <app-crm-ticket-attr-icon-view [ticket_id]="ticket_id" [attr_id]="attr_id" size="14"></app-crm-ticket-attr-icon-view>
            {{ name }}
        </app-tag>
    `,
})
export class CrmTicketAttrViewComponent implements OnInit {
    @Input()
    ticket_id: string;

    @Input()
    attr_id: string;

    name: string;
    themeConfig: ITicketAttrThemeConfig;

    constructor(
        private ticketSvc: CRMTicketsService,
        private ticketDesignSvc: CRMTicketDesignService
    ) {}

    async ngOnInit() {
        const itemData = this.ticketSvc.getItemLocalData(this.attr_id);

        this.name = itemData?.itemName;
        this.themeConfig = this.attr_id && this.ticketDesignSvc.getTicketAttrItemTheme(this.attr_id);
    }

}
